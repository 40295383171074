import React from 'react';
import { DataLoadStatus } from "app/api/agent";

interface IDataLoadStatusBadgeProps {
    status: DataLoadStatus
}

const DataLoadStatusBadge: React.FC<IDataLoadStatusBadgeProps> = ({ status }) => {

    return (
        DataLoadStatus[status] === "NotAttempted" && (
            <span className="badge badge-warning mr-3 mb-2 text-md">
                Data load not started
            </span>
        ) ||
        DataLoadStatus[status] === "Loading" && (
            <span className="badge badge-info mr-3 mb-2 text-md">
                Loading data...
            </span>
        ) ||
        DataLoadStatus[status] === "Successful" && (
            <span className="badge badge-success mr-3 mb-2 text-md">
                Data load complete
            </span>
        ) ||
        DataLoadStatus[status] === "Failed" && (
            <span className="badge badge-danger mr-3 mb-2 text-md">
                Data load failed
            </span>
        ) 
    )
}

export default DataLoadStatusBadge;