import React, { useEffect, useState } from "react";
import { IdDescriptionPair } from "../../app/models/inpatient";
import { ListGroup, ListGroupItem, Table } from "reactstrap";
import { observer } from "mobx-react-lite";
import agent, { DataLoadStatus } from "app/api/agent";
import { ShortDateTimeFormat } from "./common/DateTimeFunctions";
import PatientRecordSummaryDisplay from "./common/PatientRecordSummaryDisplay";

const PatientRecordSummaryCaseloads: React.FC = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const loadData = () => 
    agent.Patient.Caseloads({
      systemIdentifier: urlParams.get("p"),
      patientIdentifier: urlParams.get("p"),
      visitIdentifier: null
    })

  const displayTemplate = (data: any, status: DataLoadStatus, loader: React.ReactNode) => {

    if (status === DataLoadStatus.Loading)
      return loader;
      
    if (status === DataLoadStatus.Failed)
      return <></>;
      
    return (
    <Table size="sm" className="mb-0">
      <tbody>
      {data && data.length > 0 ?
        data.map((item: IdDescriptionPair, index: number) => (
        <tr key={index} className="p-0 text-sm">
          <td className="table-primary font-weight-bold t-bold text-center text-xs text-uppercase" style={{width:"60px"}}></td>
          <td className="px-2">
            {item.description}
            <div className="text-xs text-secondary">{item.code.description}</div>
          </td>
        </tr>
      ))
    : <tr className="table-warning text-sm text-secondary"><td className="px-2">No caseloads found.</td></tr>}
    </tbody>
    </Table>
  )}
  
  return (
    <PatientRecordSummaryDisplay _agentCall={loadData} _displayTemplate={displayTemplate} _title="Community Caseloads" />
  )
}
export default observer(PatientRecordSummaryCaseloads);
