import React, { ReactNode, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { DataLoadStatus } from "app/api/agent";
import DataLoadStatusBadgeSummary from "../DataLoadStatusBadgeSummary";
import DataLoadErrorAlert from "../DataLoadErrorAlert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

interface IDataItemSummaryProps<T> {
    _title: string,
    _agentCall: () => Promise<T>,
    _displayTemplate: (data: any, status: DataLoadStatus, loader: ReactNode) => React.ReactNode
}

const PatientRecordSummaryDisplay: React.FC<IDataItemSummaryProps<any>> = ({ _title, _agentCall, _displayTemplate }) => {
    const [data, setData] = useState<unknown>(null);
    const [error, setError] = useState("");
    const [status, setStatus] = useState(DataLoadStatus.NotAttempted);

    useEffect(() => {
        loadData();
    }, []);  

    const loadData = () => {
        if (status !== DataLoadStatus.Loading) {
          setStatus(DataLoadStatus.Loading);
          _agentCall()
            .then((response) => {
                setData(response);        
                setStatus(DataLoadStatus.Successful);
            })
            .catch((ex) => {
                setStatus(DataLoadStatus.Failed);
                setError(ex);
            });
        }
    }

    const loader = () : ReactNode => {
        return (
            <div className="text-center py-2 px-3 text-secondary"><FontAwesomeIcon icon={faCircleNotch} spin size="2x" /></div>
        )
    }

    return (        
        <div className="mx-2">
            <div className="text-xl border border-bottom-0 t-bold text-dark py-1 pl-3 ps-3" style={{borderRadius:'10px 10px 0 0', backgroundColor:'rgba(50, 150, 200, 0.15)'}}>
                {_title}
                <div className="float-right float-end">
                    <DataLoadStatusBadgeSummary status={status} />
                </div>                
            </div>
            <div className="border border-top-0 bg-light mb-3 shadow-sm" style={{borderRadius:'0 0 10px 10px'}}>
                <div className="px-4 py-3">
                    <DataLoadErrorAlert error={error} />  
                    {_displayTemplate(data, status, loader())}
                </div>
            </div>
        </div>
    )
}

export default observer(PatientRecordSummaryDisplay);