import { msalConfig } from "./AuthConfig";

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken 
 */
export async function GraphUserCheck(accessToken, emailAddress) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    const isOnPremAccount = emailAddress.toLowerCase().indexOf('xrothgen.nhs.uk') > 0;
    const endpoint = isOnPremAccount ?
                        `https://graph.microsoft.com/v1.0/users/${emailAddress}?$select=id,mail,displayName,userType` :
                        `https://graph.microsoft.com/v1.0/users/?$filter=userType eq 'guest' AND mail eq '${emailAddress}'&$select=id,mail,displayName,userType&$top=1`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(endpoint, options)
        .then(response => response.json())
        .then(json => { 
            console.log(json); 
            if (json.value && json.value.length) 
                return json.value[0];
            else if (json.id)
                return json;
            else
                return null;
        })
        .catch(error => console.log(endpoint, error));
}