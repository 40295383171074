import React, { Fragment } from "react";
import { Card, CardBody, CardHeader } from 'reactstrap';
import { observer } from "mobx-react-lite";
import agent from "app/api/agent";
import { IMedication } from "app/models/inpatient";
import PatientRecordListDisplay, { IFilter, ISpecialFilter, ISort } from "./common/PatientRecordListDisplay";
import { ShortDateOnlyFormat, ShortTimeOnlyFormat } from "./common/DateTimeFunctions";


const PatientRecordMedicationsList: React.FC = () => {
    let urlParams = new URLSearchParams(window.location.search);    

    const loadData = () => {
        return agent.Patient.Medications({
            Identifier: urlParams.get("v")
          })
      };

      const displayTemplate = (medication: IMedication, index: number) => {
        return (                                  
              <li key={index} className="list-group-item list-group-item-action py-0">
                  <div className="row">
                              <div className="col-12 col-lg-5 py-1">
  
                                  <div className="text-center d-none d-sm-block d-lg-none float-right">
                                      {medication.status === "Active" ? (
  
                                          <span className="text-md badge badge-primary my-3">Active</span>
  
                                      ) :
                                          medication.status === "Discontinue" ? (
                                              <span className="text-md badge badge-danger my-3">Discontinued</span>
                                          ):
                                              medication.status === "Unverified" && (
                                                  <span className="text-md badge badge-warning my-3">Unverified</span>
                                              )}
                          </div>                               
                                      <div className="mb-0 pt-2 t-bold text-lg ">
                                          {medication.medicineDetails.name?.description}
                                      </div>
                                      <div className="text-sm text-secondary t-bold mt-1 mb-1 mb-md-0">
                                          <div className="d-inline-block py-1 px-2 rounded mr-2 shadow-sm bg-PRmedicinedetails">
                                              {medication.medicineDetails?.dose}
                                              {medication.medicineDetails?.units}
                                          </div>
                                          <div className="d-inline-block py-1 px-2 rounded mr-2 shadow-sm bg-PRmedicinedetails">
                                              {medication.medicineDetails?.administrationRoute}
                                          </div>
                                          <div className="d-inline-block py-1 px-2 rounded mr-2 shadow-sm bg-PRmedicinedetails">
                                              {medication.medicineDetails.direction?.id}
                                          </div>
                                          <div className="d-inline-block py-1 px-2 rounded mr-2 shadow-sm bg-PRmedicinedetails">
                                              {medication.medicineDetails?.schedule}
                                          </div>
                                          </div>
                              
                                  
                              </div>
                      
                                      <div className="col-12 col-lg-5 py-2 bg-PRmedicinetimes">
                                          <div className="row">
                                      <div className="col-3 text-center">
                                          <Card className={'border' + (medication.startDateTime ? '-secondary card-hover' : ' bg-transparent')}>
                                              <CardHeader className="t-bold px-1 py-0 small text-success">Start</CardHeader>
                                              <CardBody className={'px-0 t-bold text-lg pt-1 pb-0' + (!medication.startDateTime ? 'bg-medication' : '')}>
                                                  {medication.startDateTime && (new Date(medication.startDateTime).getFullYear() !== 0o1) ? (
                                                      <Fragment>
                                                          <div className="text-dark">{ShortTimeOnlyFormat(medication.startDateTime)}</div>
                                                          <div className="px-1 py-0 small text-secondary">{ShortDateOnlyFormat(medication.startDateTime)}</div>
                                                      </Fragment>
                                                  ) :
                                              <span className="text-light bg-PRmedicationNoTime">00:00</span>
                                          }
  
                                      </CardBody>
                                  </Card>
                                          </div>
  
                              <div className="col-3 text-center">
                                  {medication.stopDateTime && (new Date(medication.stopDateTime).getFullYear() !== 0o1) ? (
                                  <Card className={'border' + (medication.stopDateTime ? '-secondary card-hover' : ' bg-transparent')}>
                                      <CardHeader className="t-bold px-1 py-0 small text-danger">Stop</CardHeader>
                                      <CardBody className={'px-0 t-bold text-lg pt-1 pb-0' + (!medication.stopDateTime ? 'bg-medication' : '')}>
                                              <Fragment>
                                                    <div className="text-dark">{ShortTimeOnlyFormat(medication.startDateTime)}</div>
                                                    <div className="px-1 py-0 small text-secondary">{ShortDateOnlyFormat(medication.startDateTime)}</div>
                                              </Fragment>
                                          
                                      </CardBody>
                                      </Card>
                                      ) :    
                                              <Fragment>
                                      <Card className="bg-PRmedicationNoTime">
                                          <CardHeader className="t-bold px-1 py-0 small">Stop</CardHeader>
                                          <CardBody className={'px-0 t-bold text-lg pt-1 pb-0' + (!medication.stopDateTime ? 'bg-medication' : '')}>
                                              <span className="text-light">00:00</span>
                                              <div className="px-1 py-0 small text-secondary">Not stopped</div>
                                          </CardBody>
                                      </Card>
                                              </Fragment>
                                          }
                              </div>
                              <div className="col-3 text-center">
                                  {medication.lastAdmin && (new Date(medication.lastAdmin).getFullYear() !== 0o1) ? (
                                  <Card className={'border' + (medication.lastAdmin ? '-secondary card-hover' : ' bg-transparent')}>
                                      <CardHeader className="t-bold px-1 py-0 small text-info">Last Admin</CardHeader>
                                      <CardBody className={'px-0 t-bold text-lg pt-1 pb-0' + (!medication.lastAdmin ? 'bg-medication' : '')}>
                                              <Fragment>
                                                    <div className="text-dark">{ShortTimeOnlyFormat(medication.lastAdmin)}</div>
                                                    <div className="px-1 py-0 small text-secondary">{ShortDateOnlyFormat(medication.lastAdmin)}</div>
                                              </Fragment>
                                          </CardBody>
                                      </Card>
                                          ) :
                                      <Fragment>
                                          <Card className="bg-PRmedicationNoTime">
                                              <CardHeader className="t-bold px-1 py-0 small">Last Admin</CardHeader>
                                              <CardBody className={'px-0 t-bold text-lg pt-1 pb-0' + (!medication.lastAdmin ? 'bg-medication' : '')}>
                                                  <span className="text-light">00:00</span>
                                                  <div className="px-1 py-0 small text-secondary">Not admin</div>
                                              </CardBody>
                                          </Card>
                                              </Fragment>
                                          }
                              </div>
                              <div className="col-3 text-center">
                                  {medication.nextAdmin && (new Date(medication.nextAdmin).getFullYear() !== 0o1) ? (
                                  <Card className={'border' + (medication.nextAdmin ? '-secondary card-hover' : ' bg-transparent')}>
                                      <CardHeader className="t-bold px-1 py-0 small text-secondary">Next Admin</CardHeader>
                                      <CardBody className={'px-0 t-bold text-lg pt-1 pb-0' + (!medication.nextAdmin ? 'bg-medication' : '')}>
                                              <Fragment>
                                                    <div className="text-dark">{ShortTimeOnlyFormat(medication.nextAdmin)}</div>
                                                    <div className="px-1 py-0 small text-secondary">{ShortDateOnlyFormat(medication.nextAdmin)}</div>
                                              </Fragment>
                                          </CardBody>
                                      </Card>
                                          ) :
                                      <Fragment>
                                          <Card className="bg-PRmedicationNoTime">
                                              <CardHeader className="t-bold px-1 py-0 small">Next Admin</CardHeader>
                                              <CardBody className={'px-0 t-bold text-lg pt-1 pb-0' + (!medication.nextAdmin ? 'bg-medication' : '')}>
                                                  <span className="text-light">00:00</span>
                                                  <div className="px-1 py-0 small text-secondary">Not admin</div>
                                              </CardBody>
                                          </Card>
                                              </Fragment>
                                          }
                              </div>  
                  </div>
              </div>
                              
                      <div className="d-none d-lg-block col-lg-2">
                          <div className="text-center">
                              {medication.status === "Active" ? (
                                  <span className="text-md badge badge-primary my-3">Active</span>
                              ) :
                                  medication.status === "Discontinue" ? (
                                      <span className="text-md badge badge-danger my-3">Discontinued</span>
                                  ) :
                                      medication.status === "Unverified" && (
                                          <span className="text-md badge badge-warning my-3">Unverified</span>
                                      )}
                          </div>
                      </div>  
                  </div>
              </li>
        )}

        const filters: IFilter[] = [
            { label: "Status", accessPath: "status" }
        ];
    
        const sorts: ISort[] = [
            { label: "Start Date", accessPath: "startDateTime", type: "Date" },
            { label: "Name", accessPath: "medicineDetails.name.description", type: "String" }
          ];

      return (
        <PatientRecordListDisplay _sorts={sorts} _filters={filters} _agentCall={loadData} _displayTemplate={displayTemplate} />
      )

}
export default observer(PatientRecordMedicationsList)

