import React, { useContext } from 'react';
import  InpatientStore  from '../../app/stores/inpatientStore';
import { Row, Col, Container } from 'reactstrap';
import RHRLogo from '../../app/images/rhr-logo-sm.png';

const Footer = () => {
    const inpatientStore = useContext(InpatientStore);

    return (
        <footer id="footer">
            <div className="bg-secondary pt-3 pb-5 text-white text-center text-sm border-top border-primary">
                <Container fluid>
                    <Row>
                        <Col md={4} className="text-left">
                            <h5 className='mt-0'>IT Service Desk</h5>
                            <p className="lh-lg mb-4">
                                Contact the TRFT IT Service Desk if you have any issues with access or anything else on this portal by calling 
                                (01709) 427970 or by emailing <a className='text-white' href="mailto:trft.itsupport@nhs.net">trft.itsupport@nhs.net</a>.
                            </p>
                            Developed 2019-{new Date().getFullYear()} and maintained by IT Development Team, Health Informatics<br />
                            &copy; The Rotherham NHS Foundation Trust 
                        </Col>
                        <Col md={4} className="pt-3">
                        {inpatientStore.IsAnonymous ? ( 
                            <>Demo</>
                        ) : (
                            <>Live</>
                        )} version <strong>{process.env.REACT_APP_RHR_VERSION}-2023</strong>
                        </Col>
                        <Col md={4} className="pt-3">
                            <img src={RHRLogo} alt="RHR Logo" className="shadow" />
                            <h5 className="mt-3 text-shadow">Rotherham Health Record</h5>
                        </Col>
                    </Row>
                </Container>
            </div>
        </footer>
    )
}
export default Footer;