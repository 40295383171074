import React, { useContext, useState } from 'react'
import { observer } from 'mobx-react-lite'
import CareHomeStore from '../../../app/stores/carehomeStore';


const CareHomeCapacityTable = () => {

    
    const carehomeStore = useContext(CareHomeStore);  

   
    useState(() => {
        carehomeStore.loadCapacity()
    });

    return (
             
            <table className=" table-bordered bg-light table-sm text-left mb-0 shadow">
                <thead>
                    <tr className=" bg-table">
                        <th className=""></th>
                        <th colSpan={2} className="text-center">General Residential</th>
                        <th colSpan={2} className="text-center">Dementia Residential</th>
                        <th colSpan={2} className="text-center">General Nursing</th>
                        <th colSpan={2} className="text-center">Dementia Nursing</th>
                        <th colSpan={2} className="text-center">Mental Health Nursing</th>
                        <th colSpan={2} className="text-center">Transitional</th>
                    </tr>
                    <tr className="bg-table">
                        <th style={{ 'width': '300px' }} className="text-center">Care Home</th>
                        <th style={{ 'width': '60px' }} className="text-center">Total</th>
                        <th style={{ 'width': '60px' }} className="text-center">Vacant</th>
                        <th style={{ 'width': '60px' }} className="text-center">Total</th>
                        <th style={{ 'width': '60px' }} className="text-center">Vacant</th>
                        <th style={{ 'width': '60px' }} className="text-center">Total</th>
                        <th style={{ 'width': '60px' }} className="text-center">Vacant</th>
                        <th style={{ 'width': '60px' }} className="text-center">Total</th>
                        <th style={{ 'width': '60px' }} className="text-center">Vacant</th>
                        <th style={{ 'width': '60px' }} className="text-center">Total</th>
                        <th style={{ 'width': '60px' }} className="text-center">Vacant</th>
                        <th style={{ 'width': '60px' }} className="text-center">Total</th>
                        <th style={{ 'width': '60px' }} className="text-center">Vacant</th>
                    </tr>
                </thead>
                <tbody>
                    {carehomeStore.carehomes && carehomeStore.isCareHomesLoaded && (
                        carehomeStore.carehomes.map((carehome, index) => (
                            <tr className="text-center">
                                <td className=""><span className="float-left pl-2 t-bold text-left">{carehome.name}</span></td>
                                <td className="">{carehome.vacancies.map((vacancy, index) => (vacancy.bedType === 'General Residential') ? (vacancy.total) :(null) )}</td>
                                <td className="">{carehome.vacancies.map((vacancy, index) => (vacancy.bedType === 'General Residential') ? (vacancy.spare) : (null))}</td>
                                <td className="">{carehome.vacancies.map((vacancy, index) => (vacancy.bedType === 'Dementia Residential') ? (vacancy.total) : (null))}</td>
                                <td className="">{carehome.vacancies.map((vacancy, index) => (vacancy.bedType === 'Dementia Residential') ? (vacancy.spare) : (null))}</td>
                                <td className="">{carehome.vacancies.map((vacancy, index) => (vacancy.bedType === 'General Nursing') ? (vacancy.total) : (null))}</td>
                                <td className="">{carehome.vacancies.map((vacancy, index) => (vacancy.bedType === 'General Nursing') ? (vacancy.spare) : (null))}</td>
                                <td className="">{carehome.vacancies.map((vacancy, index) => (vacancy.bedType === 'Dementia Nursing') ? (vacancy.total) : (null))}</td>
                                <td className="">{carehome.vacancies.map((vacancy, index) => (vacancy.bedType === 'Dementia Nursing') ? (vacancy.spare) : (null))}</td>
                                <td className="">{carehome.vacancies.map((vacancy, index) => (vacancy.bedType === 'Mental Health Nursing') ? (vacancy.total) : (null))}</td>
                                <td className="">{carehome.vacancies.map((vacancy, index) => (vacancy.bedType === 'Mental Health Nursing') ? (vacancy.spare) : (null))}</td>
                                <td className="">{carehome.vacancies.map((vacancy, index) => (vacancy.bedType === 'Transitional') ? (vacancy.total) : (null))}</td>
                                <td className="">{carehome.vacancies.map((vacancy, index) => (vacancy.bedType === 'Transitional') ? (vacancy.spare) : (null))}</td>

                            </tr>
                        ))
                       )}
                </tbody>
            </table>
    )

}
export default observer(CareHomeCapacityTable)