import * as React from "react";

const NhsNumber: React.FC<{nhsNumber:string}> = ({nhsNumber}) => { 
    return (
        <>
            {nhsNumber.substr(0,3)} {nhsNumber.substr(3,3)} {nhsNumber.substr(6,4)}
        </>
    )
}
export default NhsNumber;
