import { useState, useContext, useEffect } from "react";
import { Switch, Route } from 'react-router-dom';
import { Row, Col, Container } from 'reactstrap'
import { observer } from 'mobx-react-lite'
import OrganisationsList from "./OrganisationsList";
import UserAdministration from "./UserAdministration";
import AdminStore from '../../app/stores/adminStore';
import { IOrganisationValues } from "../../app/models/admin";
import RoleList from "./RoleList";
import { IUser } from "../../app/models/user";
import { RootStoreContext } from "../../app/stores/rootStore";
import LargeButton from "../common/LargeButton";
import SmallButton from "../common/SmallButton";
import UserView from "./UserView";
import UserEdit from "./UserEdit";
import UserCreate from "./UserCreate";
import CreateNewRole from "./CreateNewRole";
import CreateNewOrg from "./CreateNewOrg";
import RoleView from "./RoleView";
import EditRole from "./EditRole";
import OrganisationView from "./OrganisationView";
import EditOrganisation from "./EditOrganisation";
import AuditViewer from "./AuditViewer";

const AdminHomepage = () => {
    const organisationsAdminRoot = process.env.REACT_APP_URL_ADMIN_ORGANISATIONS_ROOT;
    const practicesAdminRoot = process.env.REACT_APP_URL_ADMIN_PRACTICES_ROOT;
    const rolesAdminRoot = process.env.REACT_APP_URL_ADMIN_ROLES_ROOT;
    const usersAdminRoot = process.env.REACT_APP_URL_ADMIN_USERS_ROOT;
    const auditAdminRoot = process.env.REACT_APP_URL_ADMIN_AUDIT_ROOT;
    const adminStore = useContext(AdminStore)
    const rootStore = useContext(RootStoreContext);
    const values: IOrganisationValues = { role: '' }
    const [selectedUsers, setSelectedUsers] = useState<IUser[] | null>();
    const useParams = new URLSearchParams(window.location.search);

    useState(() => {
        adminStore.loadOrganisations(values);
        adminStore.loadUsers(values, rootStore.userStore.user);
        adminStore.loadPractices();
        adminStore.loadRoleStats(rootStore.userStore.user);
        setSelectedUsers(adminStore.users);
    });

    useEffect(() => {    
        let audit = {
            module: "SystemAdmin",
            description: `${rootStore.userStore.user.username} has accessed ${window.location.pathname} ${useParams.has("u") ? "for {" + useParams.get("u") + "}" : ""}`,
            username: rootStore.userStore.user.username,
            action: "Navigation",
            page: window.location.pathname,
            documentId: null,
            patientId: null,
            visitId: null
        };
        rootStore.auditStore.addEntry(audit);
    },[window.location.pathname]);

    const checkSelectedMenu = (url: string) => window.location.pathname.startsWith(url) ? true : false;

    return (
        selectedUsers && (
            <Container fluid className='pt-5 px-5'>
                <Row>
                    <Col className="px-3" xs={12} md={4} lg={3} xl={2}>
                        <LargeButton title="Users" isSelected={checkSelectedMenu(usersAdminRoot)} badge={adminStore.users?.length} url={usersAdminRoot}>
                            <SmallButton title="Create User" url={`${usersAdminRoot}/add`} />
                        </LargeButton>
                        {(rootStore.userStore.user as IUser).roles.some(a => a.name === 'System Admin') && (
                            <>
                                <LargeButton title="Organisations" isSelected={checkSelectedMenu(organisationsAdminRoot)} badge={adminStore.organisations?.length} url={organisationsAdminRoot}>
                                    <SmallButton title="Add Organisation" url={`${process.env.REACT_APP_URL_ADMIN_ORGANISATIONS_ROOT}/add`} />
                                </LargeButton>
                                <LargeButton title="Practices" isSelected={checkSelectedMenu(practicesAdminRoot)} badge={adminStore.organisations?.length} url={practicesAdminRoot}>
                                    <SmallButton title="Add Practice" url={`${process.env.REACT_APP_URL_ADMIN_PRACTICES_ROOT}/add`} />
                                </LargeButton>
                                <LargeButton title="Roles" isSelected={checkSelectedMenu(rolesAdminRoot)} badge={adminStore.roles?.length} url={rolesAdminRoot}>
                                    <SmallButton title="Add Role" url={`${process.env.REACT_APP_URL_ADMIN_ROLES_ROOT}/add`} />
                                </LargeButton>
                                <LargeButton title="Audit" isSelected={checkSelectedMenu(auditAdminRoot)} url={auditAdminRoot} badge=":)" />
                            </>
                        )}

                        <hr className="my-5" />
                        <p>
                            You are currently logged in with the following role:
                        </p>
                        <ul className="text-bold font-weight-bold">
                            {(rootStore.userStore.user as IUser).roles.some(a => a.name === 'System Admin') && (
                                <li>System Administrator</li>
                            ) || (rootStore.userStore.user as IUser).roles.some(a => a.name.startsWith("Business Unit Admin")) && (
                                <li>Business Unit Administrator</li>
                            )}
                        </ul>

                    </Col>
                    <Col xs={12} md={8} lg={9} xl={10}>
                        <Switch>
                            <Route exact path={usersAdminRoot}>
                                <UserAdministration roles={adminStore.sortedRoles} users={adminStore.sortedUsers} handleSelectUser={null} organisations={adminStore.sortedOrganisations} practices={adminStore.sortedPractices} />
                            </Route>
                            <Route exact path={`${usersAdminRoot}/view`}>
                                <UserView users={adminStore.sortedUsers} roles={adminStore.sortedRoles} organisations={adminStore.sortedOrganisations} />
                            </Route>
                            <Route exact path={`${usersAdminRoot}/edit`}>
                                <UserEdit users={adminStore.sortedUsers} roles={adminStore.sortedRoles} organisations={adminStore.sortedOrganisations} />
                            </Route>
                            <Route exact path={`${usersAdminRoot}/add`}>
                                <UserCreate roles={adminStore.sortedRoles} practices={adminStore.practices} organisations={adminStore.sortedOrganisations} />
                            </Route>
                            <Route exact path={rolesAdminRoot}>
                                <RoleList roles={adminStore.sortedRoles} />
                            </Route>
                            <Route exact path={`${rolesAdminRoot}/add`}>
                                <CreateNewRole roles={adminStore.sortedRoles} organisations={adminStore.sortedOrganisations}/>
                            </Route>
                            <Route exact path={`${rolesAdminRoot}/view`}>
                                <RoleView roles={adminStore.sortedRoles} organisations={adminStore.sortedOrganisations}/>
                            </Route>
                            <Route exact path={`${rolesAdminRoot}/edit`}>
                                <EditRole roles={adminStore.sortedRoles} organisations={adminStore.sortedOrganisations}/>
                            </Route>
                            <Route exact path={organisationsAdminRoot}>
                                <OrganisationsList organisations={adminStore.organisations} />
                            </Route>
                            <Route exact path={`${organisationsAdminRoot}/add`}>
                                <CreateNewOrg organisations={adminStore.organisations} />
                            </Route>
                            <Route exact path={`${organisationsAdminRoot}/view`}>
                                <OrganisationView users={adminStore.sortedUsers}  organisations={adminStore.organisations} />
                            </Route>
                            <Route exact path={`${organisationsAdminRoot}/edit`}>
                                <EditOrganisation  organisations={adminStore.organisations} />
                            </Route>
                            <Route exact path={`${auditAdminRoot}`}>
                                <AuditViewer users={adminStore.users} roles={adminStore.roles} practices={adminStore.practices} organisations={adminStore.organisations} />
                            </Route>
                        </Switch>
                    </Col>
                </Row>
            </Container>
        )
    )
}
export default observer(AdminHomepage)






