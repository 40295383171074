import React, { Fragment, useContext, useEffect, useState } from "react";
import { ListGroup, ListGroupItem, Card } from "reactstrap";
import Loader from "react-loader-spinner";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { RootStoreContext } from "../../app/stores/rootStore";
import AuditStore from "../../app/stores/auditStore";
import { DateRange } from "react-date-range";
import { observer } from "mobx-react-lite";
import { addDays } from "date-fns";
import InpatientStore from "../../app/stores/inpatientStore";
import { AddEntry } from "../../app/models/audit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse, Table, UncontrolledCollapse } from "reactstrap";
import {
  faMinus,
  faPlus,
  faChevronDown,
  faHeartbeat,
} from "@fortawesome/free-solid-svg-icons";
import { ShortDateOnlyFormat } from "./common/DateTimeFunctions";

interface IProps {}

const SelectedInpatientAllergiesList: React.FC<IProps> = () => {
  const inpatientStore = useContext(InpatientStore);
  let urlParams = new URLSearchParams(window.location.search);
  let rootStore = useContext(RootStoreContext);
  const [state, setState] = useState([
    {
      startDate: addDays(new Date(), -30),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  useState(() => {
    let audit: AddEntry = {
      module: "PatientRecord",
      description:
        rootStore.userStore.user.username + " has accessed investigations",
      username: rootStore.userStore.user.username,
      action: "Investigations",
      documentId: "",
      patientId: urlParams.get("p"),
      visitId: urlParams.get("v"),
    };
    inpatientStore.loadPatientInvestigations(
      urlParams.get("p"),
      state[0].startDate,
      state[0].endDate
    );

    rootStore.auditStore.addEntry(audit);
  });
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    inpatientStore.loadPatientInvestigations(
      urlParams.get("p"),
      state[0].startDate,
      state[0].endDate
    );
  }, [state]);
  return (
    <>
      <div className="mt-3">
        <DateRange
          ranges={state}
          onChange={(item) => setState([item.selection])}
        />
      </div>
      {inpatientStore.isInvestigationsLoaded &&
      inpatientStore.recordinvestigations ? (
        inpatientStore.recordinvestigations.investigationSets.length > 0 ? (
          <Fragment>
            <div className="shadow-sm mb-3">
              <div className="">
                {isOpen ? (
                  <div className="float-right">
                    <FontAwesomeIcon onClick={toggle} icon={faMinus} />
                  </div>
                ) : (
                  <div className="float-right">
                    <FontAwesomeIcon onClick={toggle} icon={faPlus} />
                  </div>
                )}
              </div>
              <FontAwesomeIcon icon={faHeartbeat} />{" "}
              <strong>ICE Investigation Results</strong>
              <Collapse isOpen={isOpen}>
                <div className="accordion mt-2">
                  <Card>
                    <ListGroup
                      className="list-group-flush mb-0 pb-0"
                      style={{ lineHeight: 1.15 }}
                    >
                      {inpatientStore.recordinvestigations.investigationSets.map(
                        (investigation, index) => (
                            <ListGroupItem
                              id={"investigationsList" + index}
                              key={index}
                              className="text-dark py-1 t-bold text-md bg-header"
                            >
                              <div className="float-right">
                                {ShortDateOnlyFormat(investigation.rows[0].items[0].resultDateTime)}{" "}
                                -{" "}
                                {ShortDateOnlyFormat(investigation.rows[0].items[investigation.rows[0].items.length - 1].resultDateTime)}
                                <FontAwesomeIcon
                                  icon={faChevronDown}
                                  className="ml-1"
                                />
                              </div>

                              {investigation.name}
                              {/*<span className="badge badge-primary badge-pill ml-2">{investigation.rows.length}</span>*/}

                              <UncontrolledCollapse
                                toggler={"investigationsList" + index}
                              >
                                <div className="card card-body mb-2">
                                  <Table
                                    striped
                                    bordered
                                    className="table-sm bg-white mb-0 text-sm"
                                  >
                                    <tbody>
                                      <Fragment>
                                        <tr>
                                          <td> </td>
                                          {investigation.rows[0].items.map((item, index) => (
                                              <td className="text-center" key={index}>
                                                {" "}{ShortDateOnlyFormat(item.resultDateTime)}
                                              </td>
                                            )
                                          )}
                                        </tr>
                                        {investigation.rows.map(
                                          (row, index) => (
                                              <tr key={index}>
                                                <td>{row.name}</td>
                                                {row.items.map(
                                                  (item, index1) => (
                                                      <td className="text-center" key={index1}>
                                                        {item.value}
                                                        <span className="text-xs text-secondary">
                                                          {item.uom}
                                                        </span>
                                                      </td>
                                                  )
                                                )}
                                              </tr>
                                          )
                                        )}
                                      </Fragment>
                                    </tbody>
                                  </Table>
                                </div>
                              </UncontrolledCollapse>
                            </ListGroupItem>
                        )
                      )}
                    </ListGroup>
                  </Card>
                </div>
              </Collapse>
            </div>
          </Fragment>
        ) : (
          <>
            {" "}
            <li className="list-group-item list-group-item-warning px-2 pr-1 py-2 border-left-0 border-right-0 border-bottom-0">
              No investigations recorded for this patient.
            </li>
          </>
        )
      ) : (
        <Loader
          type="TailSpin"
          color="#007bff"
          className="text-center"
          height={80}
          width={80}
        />
      )}
    </>
  );
};
export default observer(SelectedInpatientAllergiesList);
