import '../index.css';
import 'react-toastify/dist/ReactToastify.css';
import React, { useContext, useEffect } from 'react';
import { Route,Switch,withRouter, useHistory} from 'react-router-dom';
import MainNavbar from '../features/nav/MainNavbar'
import { ToastContainer } from 'react-toastify';
import ProtectedRoute  from '../app/ProtectedRoute';
import { RootStoreContext } from './stores/rootStore';
import { observer } from 'mobx-react-lite';
import RegisterForm from '../features/user/RegisterForm';
import Loader from 'react-loader-spinner'
import HomePage from '../features/home/HomePage';
import SetupPassword from '../features/user/SetupPassword';
import PasswordReset from '../features/user/PasswordReset';
import Footer from 'features/common/Footer';
import { MsalProvider, useIsAuthenticated } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { CustomNavigationClient } from './azureauth/NavigationClient';
import LaunchInContext from 'features/home/LaunchInContext';
import AuthChecker from './AuthChecker';

interface IAppProps {
    pca: IPublicClientApplication
}

const App: React.FC<IAppProps> = ({ pca }) => {
    const rootStore = useContext(RootStoreContext);
    const isAuthenticated = useIsAuthenticated();
    const history = useHistory();

    const { setAppLoaded, appLoaded, token } = rootStore.commonStore;
    const { getUser, user, isLoggedIn } = rootStore.userStore;    

    const navigationClient = new CustomNavigationClient(history);
    pca.setNavigationClient(navigationClient);

    useEffect(() => {
        if (token) {
            getUser().finally(() => setAppLoaded())
        } else {
            setAppLoaded()
        }
    }, [getUser, setAppLoaded, token]);

    if (appLoaded) {
        return (
            (user && isLoggedIn) || isAuthenticated  ? ( 
                <MsalProvider instance={pca}>   
                    <ToastContainer autoClose={4000} position="top-right" />
                    <AuthChecker />
                    <Route exact path='/launch' component={LaunchInContext}/>
                    <ProtectedRoute exact path='/' user={user} />
                    <Footer />
                </MsalProvider>
            ) : (               
                <MsalProvider instance={pca}>              
                    <MainNavbar />
                    <Switch>
                        <Route exact path='/launch' component={LaunchInContext}/>
                        <Route exact path='/Register' component={RegisterForm}/>
                        <Route exact path="/SetupPassword" component={SetupPassword} />
                        <Route exact path="/ResetPassword" component={PasswordReset} />
                        <Route path='*' component={HomePage} />
                    </Switch>
                    <ToastContainer autoClose={4000} position="top-right" />
                    <Footer />
                </MsalProvider>
            )
        )
    } else {
        return(
            <Loader type="TailSpin" color="#007bff" className="text-center" height={80} width={80} />
        )
    }
}

export default withRouter(observer(App));
