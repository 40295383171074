import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col, Card, Container } from 'reactstrap';
import { IRole, IOrganisation } from "../../app/models/user";

interface IProps {
    
    roles: IRole[],
    organisations: IOrganisation[]
}

const ViewRole: React.FC<IProps> = ({ roles }) => {
    const [ role, setRole ] = useState<IRole>();
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let name = params.get('u');

    useEffect(() => {
        setRole(roles.filter(u => u.name === name)[0])
    }, [])

    return (
        (roles && (
        <Container fluid>
            <h2 className="text-gradient text-primary mb-4">
                Viewing Role - {role?.name}
            </h2>
            <Row>
                <Col>
                    <div className="pb-3">
                        <Link to={`/system/admin/roles/edit/?u=${role?.name}`} className="btn btn-primary">Edit</Link> &nbsp;
                        <Link to="/system/admin/roles" className="btn btn-link text-secondary">Back to Roles</Link> &nbsp;
                    </div>
                    <Card className="card-menu mb-5 p-5" body>
                            Role Name: {role?.name}<br />
                            Data Filter: {role?.dataFilter}<br />
                            Organisation: {role?.organisation?.name}
                    </Card>
                </Col>
            </Row>
        </Container>
    )) || <>Loading...</>
    
    );
};

export default withRouter(ViewRole);