import React from "react";
import { PersonContactReference } from "../../app/models/inpatient";
import { Table } from "reactstrap";
import { observer } from "mobx-react-lite";
import agent, { DataLoadStatus } from "app/api/agent";
import PatientRecordSummaryDisplay from "./common/PatientRecordSummaryDisplay";

const PatientRecordSummaryContacts: React.FC = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const loadData = () => 
    agent.Patient.Contacts({
      systemIdentifier: urlParams.get("p"),
      isAnonymous: process.env.REACT_APP_IS_ANONYMOUS === "true"
    })

  const displayTemplate = (data: any, status: DataLoadStatus, loader: React.ReactNode) => {

    if (status === DataLoadStatus.Loading)
      return loader;
      
    if (status === DataLoadStatus.Failed)
      return <></>;
      
    return (
    <Table size="sm" className="mb-0">
      <tbody>
      {data && data.length > 0 ?
         data.map((item: PersonContactReference, index: number) => (
        <tr key={index} className="p-0 text-sm">
          <td className="table-primary font-weight-bold t-bold text-center text-xs text-uppercase" style={{width:"60px"}}>{item.type?.description}</td>
          <td className="px-2 lh-sm">
            <div className="mb-1"><u>{item.relationship?.description}</u></div>
            <div>{item.name?.forename} {item.name?.surname}</div>
            <div>{item.contactDetails?.address.houseNumberName} {item.contactDetails?.address.line1}</div>
            <div>{item.contactDetails?.address.line2}</div>
            <div>{item.contactDetails?.address.line3}</div>
            <div>{item.contactDetails?.address.line4}</div>            
            <div>{item.contactDetails?.address.postcode}</div> 
            <hr className="my-1" />      
            <div>Landline #: {item.contactDetails?.landlineNumber}</div>      
            <div>Mobile #: {item.contactDetails?.otherNumber}</div>   
          </td>
        </tr>
      )) : <tr className="table-warning text-sm text-secondary"><td className="px-2">No contacts found.</td></tr>
    }</tbody>
    </Table>
  )}
  
  return (
    <PatientRecordSummaryDisplay _agentCall={loadData} _displayTemplate={displayTemplate} _title="Contacts" />
  )
}
export default observer(PatientRecordSummaryContacts);
