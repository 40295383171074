import React, { Fragment, useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import "../patientrecord/mig.css"
import agent from "../../app/api/agent";
import PatientRecordListDisplay from "./common/PatientRecordListDisplay";
import { RootStoreContext } from "app/stores/rootStore";
import PatientRecordStore from "app/stores/patientRecordStore";


const PatientRecordMig: React.FC = () => {
    let [ selectedTab, setSelectedTab ] = useState<string>("Summary");
    let [ selectedTabHtml, setSelectedTabHtml ] = useState<React.ReactNode>(<></>);
    let rootStore = useContext(RootStoreContext);
    let searchStore = useContext(PatientRecordStore);

    let migList = ["Summary", "Demographics", "Risks/Warnings", "Medications", "Problems", "Diagnoses", "Procedures", "Examinations", "Encounters"]
    let urlParams = new URLSearchParams(window.location.search);
    const view = urlParams.get('view');

    const loadData = () => {
        let user = rootStore.userStore.user;
        let patient = searchStore.patient;        
        let migRequest = { 
            nhsNumber: patient.nhsNumber?.value, 
            forename: user.displayName ? user.displayName.split(' ')[0] : '', 
            surname: user.displayName ? user.displayName.split(' ')[1] : '', 
            practiceCode: patient.gpDetails?.registeredPracticeCode, 
            username: user.username, 
            type: selectedTab
        }
        return agent.Patient.Mig(migRequest)
        .then(mig => mig.content.replace(/\\/g, ""))
        .then(content => [content]) 
    }

    useEffect(() => {
        if (selectedTabHtml === "loading...") {
            setSelectedTabHtml(<PatientRecordListDisplay _sorts={[]} _filters={[]} _agentCall={loadData} _displayTemplate={displayTemplate} />)
        }
    }, [selectedTabHtml])

    useEffect(() => {
        setSelectedTabHtml("loading...");
    }, [selectedTab])

    const getMenu = () => {
        return (
            <ul className="nav nav-tabs bg-secondary mb-0 mt-3" id="mignav">
            {migList.map((item, index) => (
                <li className="nav-item pt-2" key={index}>
                    <a className={`nav-link text-light ${item === selectedTab ? "active text-dark" : ""}`} onClick={() => setSelectedTab(item)}>
                        {item}
                    </a>
                </li>
            ))}
            </ul>
        )
    }

    const displayTemplate = (mig: string, index: number) => {
        return (
            <div key={index} dangerouslySetInnerHTML={{ __html: mig }}></div>
        )
    }

    return (
        <Fragment>
            {getMenu()}
            {selectedTabHtml !== <></> ? selectedTabHtml : <>Uh oh</>}
        </Fragment>
    )
}
export default observer(PatientRecordMig)