import React, { useContext, useState, Fragment } from 'react';
import { Form as FinalForm, Field } from 'react-final-form'
import { Button, Form, FormGroup, Label, Card,CardBody, Container,Row,Col, CardHeader } from 'reactstrap';
import { RootStoreContext } from '../../app/stores/rootStore';
import { IUserFormValues } from '../../app/models/user';
import { FORM_ERROR } from 'final-form';
import AdminStore from '../../app/stores/adminStore';
import { IOrganisationValues } from '../../app/models/admin';

const required = value => (value ? undefined : 'Required')
const RegisterForm = () => {
    const rootStore = useContext(RootStoreContext);
    const { register } = rootStore.userStore;
    const adminStore = useContext(AdminStore)
    const values: IOrganisationValues = { role: '' }

    useState(() => {
        adminStore.loadOrganisations(values);
    })
    return (
        <Container fluid>
            <Row className="justify-content-center">
                <Col xs="10" md="6" className="pt-5">
                    <Card>
                        <CardHeader className="text-white bg-primary t-bold">
                            Rotherham Health Record Registration Form
                        </CardHeader>
                        <CardBody>
                            <FinalForm
                                onSubmit={(values: IUserFormValues) => register(values).catch(
                                    error => ({
                                        [FORM_ERROR]: error

                                    })
                                )}
                                render={({ handleSubmit, pristine, form }) => (

                                    <Form onSubmit={handleSubmit}>
                                        
                                            <Field
                                                validate={required}
                                             name="displayName"
                                                render={({ input, meta }) => (
                                                    <FormGroup>
                                                        <Label for="displayName">Display Name</Label>
                                                        <input {...input} className="form-control" type="input" name="displayName" id="displayName" placeholder="Enter Display Name"/>
                                                        {meta.touched && meta.error && <Label className="t-bold text-danger">{meta.error}</Label>}
                                                      </FormGroup>
                                                )}
                                            />
                                        <Field
                                            validate={required}
                                            name="username"
                                            render={({ input, meta }) => (
                                                <FormGroup>
                                                    <Label for="username">Username</Label>
                                                    <input {...input} className="form-control" type="input" name="username" id="username" placeholder="Enter Username" />
                                                    {meta.touched && meta.error && <Label className="t-bold text-danger">{meta.error}</Label>}
                                                </FormGroup>
                                            )}
                                        />
                                        <Field
                                            validate={required}
                                            name="email"
                                            render={({ input, meta }) => (
                                                <FormGroup>
                                                    <Label for="email">Email</Label>
                                                    <input {...input} className="form-control" type="email" name="email" id="email" placeholder="Enter Email" />
                                                    {meta.touched && meta.error && <Label className="t-bold text-danger">{meta.error}</Label>}
                                                </FormGroup>
                                            )}
                                        />
                                        <Field
                                            validate={required}
                                            name="password"
                                            render={({ input, meta }) => (
                                                <FormGroup>
                                                    <Label for="password">Password</Label>
                                                    <input {...input} className="form-control" type="password" name="password" id="password" placeholder="Enter Password" />
                                                    {meta.touched && meta.error && <Label className="t-bold text-danger">{meta.error}</Label>}
                                                </FormGroup>
                                            )}
                                        />                                      

                                     
                                        <FormGroup>
                                            <Field type="select" className="form-control" validate={required}  component="select" name="organisation" id="organisation">

                                                <option>Select Organisation</option>

                                            {adminStore.organisations.map((organisation, index) => {
                                                return (
                                                    <Fragment key={index}>
                                                        <option value={organisation.id}>{organisation.name}</option>
                                                    </Fragment>
                                                )
                                            })}
                                            </Field>
                                            </FormGroup>
                                        <Button type="submit" color="primary">Submit</Button>
                                    </Form>
                                    )}
                                />
                        </CardBody>
                        </Card>
                </Col>
            </Row>
       </Container>
    );
};

export default RegisterForm;