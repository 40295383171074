import React from "react";
import { observer } from "mobx-react-lite";
import { RhrDocumentQuestion } from "app/models/inpatient";
import MeditechQueryResponseFix from "features/common/MeditechQueryResponseFix";

interface IQuestionProps {
    question: RhrDocumentQuestion;
    groupingName: string;
}

const DocumentQuestion: React.FC<IQuestionProps> = ({ question, groupingName }) => {

    return (
        question.type === "Query" ? (
            <div className="row border-top">
            <div className="col-5 border-left pt-1 border-right" style={{ backgroundColor:'rgba(0,0,0,0.09)'}}>
                {question.name.description}
            </div>
            <div className="col-7 question-response border-right">
                {question.subType === "group" ? (
                <span className="response"><MeditechQueryResponseFix response={question.value} /></span>
                ) : (
                    <MeditechQueryResponseFix response={question.value} /> 
                )}
            </div>
            </div>
        ) : (
            <>
            <div className="row">
                <div className="col-12 question">
                <h4 className="letter-h4">
                    {question.name.description}
                </h4>
                </div>
            </div>
            {question.type === "Group" ? (
                <MeditechQueryResponseFix response={question.value} />
            ) : (
                <MeditechQueryResponseFix response={question.value} /> 
            )}
            </> )
    )
};
export default observer(DocumentQuestion);
