import React from "react";
import { PatientProblem } from "../../app/models/inpatient";
import { ListGroupItem } from "reactstrap";
import { observer } from "mobx-react-lite";
import agent from "app/api/agent";
import PatientRecordListDisplay from "./common/PatientRecordListDisplay";
import { ShortDateTimeFormat } from "./common/DateTimeFunctions";

const PatientRecordProblemsList: React.FC = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const loadData = () => {
      return agent.Patient.Problems({
        systemIdentifier: urlParams.get("p"),
        patientIdentifier: null,
        visitIdentifier: null,
      })
  }

  const displayTemplate = (problem: PatientProblem, index: number) => {
  return (
      <ListGroupItem key={index} className="list-group-item-action py-0 px-3 lh-xs-115">
        <div className="row">

          <div className="col-md-7 col-xl-9 py-2">
            <div className="my-0 text-lg font-weight-bold">{problem.code.description}</div>
            <div className="small text-success d-block">
              {problem.comment}
            </div>
            <div className="mt-2 d-md-none">
              <div
                className="small d-inline-block mr-2 text-secondary px-3 py-1 rounded bg-light border"
                style={{ marginTop: ".2rem" }}>
                Added {ShortDateTimeFormat(problem.addedDateTime)}
              </div>
              <div className="small d-inline-block text-secondary px-3 py-1 rounded bg-light border">
                SNOMED Concept: {problem.code.primaryCode}
              </div>
            </div>
          </div>
          <div className="col-5 col-md-2 col-xl-1 py-2 border-right m-0 px-1 text-center" style={{backgroundColor:'rgba(50,60,70,.05)'}}>
            <div className={`badge badge-${["Active","Acute","Chronic"].includes(problem.status) ? "primary" : (["Suspected"].includes(problem.status) ? "warning" : "secondary")} text-md mx-auto`}>
              {problem.status}
            </div>
          </div>
          <div className="col-7 col-md-3 col-xl-2 py-2 border-right bg-problems">
            <h6 className="my-0 t-bold">{problem.code.primaryCode}</h6>
            <span className="small" style={{ marginTop: ".2rem" }}>
               Added {ShortDateTimeFormat(problem.addedDateTime)}
            </span>
          </div>
        </div>
      </ListGroupItem>
    )
  }

  return (
    <PatientRecordListDisplay _sorts={[]} _filters={[]} _agentCall={loadData} _displayTemplate={displayTemplate} />
  )
}
export default observer(PatientRecordProblemsList);
