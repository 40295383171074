import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Form as FinalForm, Field } from 'react-final-form'
import { Button, Form, FormGroup, Label, Col, Row, Card, Container } from 'reactstrap';
import { IRole, IOrganisation, IPractice } from "../../app/models/user";
import { RootStoreContext } from '../../app/stores/rootStore';
import { IUserFormValues } from '../../app/models/user';
import { FORM_ERROR } from 'final-form';
import AdminStore from '../../app/stores/adminStore';

interface IProps {
    roles: IRole[],
    organisations: IOrganisation[],
    practices: IPractice[]
}

const required = value => (value ? undefined : 'Required')
const UserCreate: React.FC<IProps> = ({ roles, organisations, practices }) => {

    const adminStore = useContext(AdminStore)
    const rootStore = useContext(RootStoreContext);

    return (
        <>
            <Container fluid>
                <h2 className="text-gradient text-primary mb-4">
                    Add a New User
                </h2>
                <Row>
                    <Col>
                        <div className="pb-3">
                            <Link to={process.env.REACT_APP_URL_ADMIN_USERS_ROOT} className="btn btn-link text-secondary">Back to Users</Link> &nbsp;
                        </div>
                        <Card body className='mb-5 card-menu'>
                            <FinalForm
                                onSubmit={(values: IUserFormValues, form) => 
                                    adminStore.AddUser(values, rootStore.userStore.user.organisation.id, rootStore.userStore.user.practice?.id)
                                    .then(() => form.reset())
                                    .catch(error => ({ [FORM_ERROR]: error }))}
                                render={({ handleSubmit, values }) => (

                                    <Form onSubmit={handleSubmit}>
                                        <Field
                                            validate={required} name="displayName" render={({ input, meta }) => (
                                                <FormGroup row>
                                                    <Label md={3} className="text-primary" for="displayName">Display Name</Label>
                                                    <Col md={9}>
                                                        <input {...input} className="form-control" type="input" name="displayName" id="displayName" placeholder="Enter Display Name" />
                                                        {meta.touched && meta.error && <Label className="t-bold text-danger">{meta.error}</Label>}
                                                    </Col>
                                                </FormGroup>)} />
                                        <Field
                                            validate={required} name="email" render={({ input, meta }) => (
                                                <FormGroup row>
                                                    <Label md={3} className="text-primary" for="email">Email</Label>
                                                    <Col md={9}>
                                                        <input {...input} className="form-control" type="email" name="email" id="email" placeholder="Enter Email" />
                                                        {meta.touched && meta.error && <Label className="t-bold text-danger">{meta.error}</Label>}
                                                    </Col>
                                                </FormGroup> )} />
                                        <Field
                                            validate={required} name="jobTitle" render={({ input, meta }) => (
                                                <FormGroup row>
                                                    <Label md={3} className="text-primary" for="jobTitle">Job Title</Label>
                                                    <Col md={9}>
                                                        <input {...input} className="form-control" type="input" name="jobTitle" id="jobTitle" placeholder="Enter Job Title" />
                                                        {meta.touched && meta.error && <Label className="t-bold text-danger">{meta.error}</Label>}
                                                    </Col>
                                                </FormGroup> )} />
                                        <Field
                                            name="contactNumber" render={({ input, meta }) => (
                                                <FormGroup row>
                                                    <Label md={3} className="text-primary" for="contactNumber">Contact Number</Label>
                                                    <Col md={9}>
                                                        <input {...input} className="form-control" type="input" name="contactNumber" id="contactNumber" placeholder="Enter Contact Number" />
                                                        {meta.touched && meta.error && <Label className="t-bold text-danger">{meta.error}</Label>}
                                                    </Col>
                                                </FormGroup> )} />
                                        <Field
                                            validate={required} name="manager" render={({ input, meta }) => (
                                                <FormGroup row>
                                                    <Label md={3} className="text-primary" for="manager">Manager</Label>
                                                    <Col md={9}>
                                                    <input {...input} className="form-control" type="input" name="manager" id="manager" placeholder="Enter Managers Name" />
                                                    {meta.touched && meta.error && <Label className="t-bold text-danger">{meta.error}</Label>}
                                                    </Col>
                                                </FormGroup> )} />
                                        <Field
                                            name="team" render={({ input, meta }) => (
                                                <FormGroup row>
                                                    <Label md={3} className="text-primary" for="team">Team</Label>
                                                    <Col md={9}>
                                                    <input {...input} className="form-control" type="input" name="team" id="team" placeholder="Enter Team / Department / Division" />
                                                    {meta.touched && meta.error && <Label className="t-bold text-danger">{meta.error}</Label>}
                                                    </Col>
                                                </FormGroup> )} />
                                        {rootStore.userStore.user.roles.some(a => a.name === 'System Admin') && (
                                            <>
                                                <FormGroup row>
                                                    <Label md={3} className="text-primary" for="organisation">Organisation</Label>
                                                    <Col md={9}>
                                                    <Field type="select" className="form-control" validate={required} component="select" name="organisation" id="organisation">
                                                        <option key="-1">Select Organisation</option>
                                                        {organisations.map((organisation, index) => (
                                                            <option key={index} value={organisation.id}>{organisation.name}</option>
                                                        ))}
                                                    </Field>
                                                    </Col>
                                                </FormGroup>
                                            </>
                                        )}            
                                        {!rootStore.userStore.user?.practice?.id && (
                                            <Field
                                                name="practice" id="practice" render={({ input, meta }) => (
                                                    <FormGroup row>
                                                        <Label md={3} className="text-primary" for="practice">Practice</Label>
                                                        <Col md={9}>
                                                        <select {...input} className="form-control" name="practice" id="practice" placeholder='Select Practice/Department' defaultValue={rootStore.userStore.user?.practice?.id}>
                                                            <option value="">Please select...</option>
                                                        {practices.map((p, index) => (
                                                            <option key={index} value={p.id}>{p.code} - {p.name}</option>
                                                        ))}
                                                        </select>
                                                        {meta.touched && meta.error && <Label className="t-bold text-danger">{meta.error}</Label>}
                                                        </Col>
                                                    </FormGroup> )} />
                                        )}                            
                                        <FormGroup row>
                                            <Label md={3} className="text-primary" for="roleName">Role</Label>
                                            <Col md={9}>
                                            <Field type="select" className="form-control" validate={required} component="select" name="roleName" id="roleName">
                                                <option key="-1">Select Role</option>
                                                {roles.map((role: IRole, index) => (
                                                    <option key={index} value={role.name}>{role.name}</option>
                                                ))}
                                            </Field>
                                            </Col>
                                        </FormGroup>
                                        <Button type="submit" color="primary">Create User</Button>

                                        <div className='text-white bg-dark p-2 rounded mt-3'>
                                        <pre className='text-white'>{JSON.stringify(values, ["displayName", "email", "jobTitle", "team", "manager", "contactNumber", "practice", "id", "name", "roleName", "organisation"], 2)}</pre>
                                        </div>
                                    </Form>
                                )}
                            />
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default UserCreate;