import agent from 'app/api/agent';
import { RootStoreContext } from 'app/stores/rootStore';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const LaunchInContext : React.FC = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const history = useHistory();
    const rootStore = useContext(RootStoreContext);

    useEffect(() => {
        let nhs = urlParams.get("nhs");
        let feature = urlParams.get("f");
        let src = urlParams.get("src");
        let sys = urlParams.get("sys");

        if (nhs) {
            agent.Patient.ConvertNhsToInternalIdentifier({
                systemIdentifier: nhs, 
                isAnonymised: false
            })
            .then(r => { 
                
            let audit = {
                module: "LaunchInContext",
                description: `${src} (${sys}) request to view Patient NHS:${nhs}`,
                username: rootStore.userStore?.user?.username || "Unknown",                
                page: window.location.pathname,
                action: "Launch",
                documentId: null,
                patientId: r,
                visitId: null
            };

            rootStore.auditStore.addEntry(audit);
            if (nhs && src && sys) {
                    let uri = r ? `/patient/${feature || "summary"}?p=${r}` : `/patient/search?number=${nhs}`;
                    history.push(`/?login_redirect=${Buffer.from(uri).toString("base64")}`);
                } else {
                    history.push("/")
                }
            })
        }
    }, [])

    return (
        <></>
    )
}

export default LaunchInContext;