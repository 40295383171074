import React from "react";
import { observer } from "mobx-react-lite";
import { PaginationItem } from "reactstrap";
import { Link } from 'react-router-dom';


interface IProps {
    total: Number,
    countPerPage: Number,
    currentPage: Number,
    clickEvent: (label: string) => void
}

const OperationOutcome: React.FC<IProps> = ({ total, countPerPage, currentPage, clickEvent }) => {
    const urlParams = new URLSearchParams(window.location.search);
    const pageCount = Math.ceil(Number((Number.isInteger(total) ? total : 0).toFixed(2)) / Number(countPerPage.toFixed(2)));
    const pages = Array.from({length: pageCount}, (v, i) => String(i + 1));

    const linkHtml = (label, index) => 
                <PaginationItem key={`pg${index}`} className={`${label == currentPage && "active"}`}>
                    <Link className="page-link px-3" onClick={(e) => clickEvent(label)} to={`${window.location.pathname}?p=${urlParams.get("p")}&v=${urlParams.get("v")}${label !== "1" ? "&yhcrPageOffset=" + label : ""}`}>
                        {label}
                    </Link>
                </PaginationItem>
                
    return (
        <div className="px-3 py-2 bg-light">
            {pageCount > 0 ?
            <div className="float-end align-middle float-right text-md pt-2 pr-3 text-secondary">
                Showing page <strong>{currentPage}</strong> of <strong>{pageCount}</strong>.
            </div> : <></>}
            <ul className="pagination align-middle mb-0">
                {pages.map((page, index) => 
                    linkHtml(page, index)
                )}
            </ul>
        </div>
    )

}
export default observer(OperationOutcome)