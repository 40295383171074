import React, { useState, Fragment, useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../app/stores/rootStore";
import InpatientStore from "../../../app/stores/inpatientStore";
import SearchStore from "../../../app/stores/searchStore";
import PatientNameForm from "../../search/PatientNameForm";
import CareHomeResults from "./CareHomeResults";
import { Patient } from "../../../app/models/inpatient";

const CarehomesMainLayout: React.FC = () => {
  const inpatientStore = useContext(InpatientStore);
  const [toggle, setToggle] = useState(false);
  const rootStore = useContext(RootStoreContext);
  const [patient, setPatient] = useState<Patient | null>();
  const searchStore = useContext(SearchStore);

  let practice: string = "";
  if (rootStore.userStore.user.practice) {
    practice = rootStore.userStore.user.practice.code;
  }
  const handleSelectPatient = (value: Patient) => {
    searchStore
      .getPatient(
        value.systemInternalIdentifier.value,
        inpatientStore.IsAnonymous
      )
      .then(() => {
        setPatient(searchStore.patient);
      });
    setToggle(!toggle);
  };
  const handleToggle = () => {
    setToggle(!toggle);
  };

  let auditEntry = {
    module: "Dashboards",
    description: "Accessed Carehomes Dashboard",
    patientId: "",
    documentId: "",
    visitId: "",
    page: window.location.pathname,
    action: "Carehomes Dashboard",
    username: rootStore.userStore.user.username,
    practiceId: practice,
  };
  useEffect(() => {
    rootStore.auditStore.addEntry(auditEntry);
  }, []);

  const canvasStyle = {
    backgroundColor: "rgba(0,0,0,.1)",
  };

  return (
    <Fragment>
      <Fragment>
        <div className="page-wrap">
          <div style={canvasStyle} className="row py-1">
            <h2 className="text-gradient font-style-1 mb-0 mx-auto">
              Carehomes View
            </h2>
          </div>
          <div className="row">
            <div className="mx-auto col-md-4">
              <div className="card my-2 p-2">
                <PatientNameForm />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="mx-auto col-md-6">
              {!toggle ? (
                <CareHomeResults handleSelectPatient={handleSelectPatient} />
              ) : (<></>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    </Fragment>
  );
};
export default observer(CarehomesMainLayout);
