import { observable, computed, action, runInAction } from 'mobx'
import agent from "../api/agent"
import { createContext } from 'react';
import { RootStore } from "./rootStore";
import { AddEntry, AuditValues, AuditItem, AuditStats } from '../models/audit';

export default class AuditStore {

    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @observable auditItems: AuditItem[] = [];
    @observable isAuditLoaded: boolean = false;
    @observable AuditStats: AuditStats = { total: null }

    @computed get sortedAudit() {
        return  this.auditItems.slice().sort((a,b) => `${b.actionDate}` >= `${a.actionDate}` ? 1 : -1)
    }

    @action addEntry = (values: AddEntry ) => {

        if (!values.page)
            values.page = window.location.pathname;

        agent.Audit.AddEntry(values).then(() => {

        })
    }
    @action byDate = (values: AuditValues) => {
        this.isAuditLoaded = false;
        agent.Audit.ByDate(values)
            .then(list => {
                      runInAction(() => {
                          this.auditItems = list;
                          this.isAuditLoaded = true;
                      })
              })
    }
}