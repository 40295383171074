import React from "react";
import { observer } from "mobx-react-lite";
import { RhrDocumentQuestionSet } from "app/models/inpatient";
import DocumentQuestion from "./DocumentQuestion";

interface IQuestionProps {
    grouping: RhrDocumentQuestionSet;
    sectionName: string;
}

const DocumentQuestionGroup: React.FC<IQuestionProps> = ({ grouping, sectionName }) => {

    return (
        <div className="container-fluid border-bottom">              
            {grouping.name.description !== sectionName && (
            <div className="row">
                <div className="col-12">
                    <h3 className="letter-h3 mb-0">{grouping.name.description}</h3>
                </div>
            </div>
            )}
            {grouping.questions.length > 0 && (
                grouping.questions.map((question, index) => 
                <DocumentQuestion question={question} key={index} groupingName={grouping.name.description} />
            ))}
        </div>
    )
};
export default observer(DocumentQuestionGroup);
