import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form as FinalForm, Field } from 'react-final-form'
import { Card, Container, Button, Form, FormGroup, Label, Col, Row } from 'reactstrap';
import { IUser, IRole, IOrganisation, IRemoveValues, IUserFormValues } from "../../app/models/user";
import { RootStoreContext } from '../../app/stores/rootStore';
import AdminStore from '../../app/stores/adminStore';
import { FORM_ERROR } from 'final-form';
import { toast } from 'react-toastify';
import agent from '../../app/api/agent';

interface IProps {
    users: IUser[],
    roles: IRole[],
    organisations: IOrganisation[]
}


const required = value => (value ? undefined : 'Required')
const UserEdit: React.FC<IProps> = ({ users, roles }) => {
    const rootStore = useContext(RootStoreContext);
    const adminStore = useContext(AdminStore)
    const [ user, setUser ] = useState<IUser>();
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let email = params.get('u');

    useEffect(() => {
        agent.Admin.User({ "email": email}).then(u => setUser(u))
    }, [])


    const handleRemove = (email: string, role: string) => {
        let values: IRemoveValues = { email: email, role: role }
        agent.Admin.remove(values);
        toast.info("Successfully removed access");
    }

    return (
        user && (
            <Container fluid>
                <h2 className="text-gradient text-primary mb-4">
                    Editing User - {user.displayName}
                </h2>
                <Row>
                    <Col>
                        <div className="pb-3">
                            <Link to={`${process.env.REACT_APP_URL_ADMIN_USERS_ROOT}/view/?u=${user.email}`} className="btn btn-primary">View</Link> &nbsp;
                            <Link to={process.env.REACT_APP_URL_ADMIN_USERS_ROOT} className="btn btn-link text-secondary">Back to Users</Link> &nbsp;
                        </div>
                        <Card body className='mb-5 card-menu'>
                            <FinalForm
                                onSubmit={(values: IUserFormValues) => {
                                    console.log(values);
                                    return agent.Admin.EditUser(values)
                                            .then(e => console.log(e))
                                            .catch(e => ({ [FORM_ERROR]: e })
                                    )
                                }}
                                initialValues={{
                                    username: user.username,
                                    email: user.email,
                                    displayName: user.displayName,
                                    azureUsername: user.azureUsername,
                                    jobTitle: user.jobTitle,
                                    team: user.team,
                                    manager: user.manager,
                                    contactNumber: user.contactNumber,
                                    organisation: user.organisation.id,
                                    roleName: user.roles.length > 0 ? user.roles[0].name : null
                                }}
                                validate={(values: IUserFormValues) => {
                                    const errors = {} as IUserFormValues;
                                    const requiredMessage = "* Required field";
                                    
                                    if (values.displayName === "" || values.email === "" || values.username === "") {
                                        errors.displayName = requiredMessage;
                                        errors.username = requiredMessage;
                                        errors.email = requiredMessage;
                                    }
                                    return errors;
                                }}
                                render={({
                                    submitError,
                                    handleSubmit,
                                    form,
                                    submitting,
                                    pristine,
                                    values
                                }) => (

                                    <Form onSubmit={handleSubmit}>
                                        <Field
                                            name="displayName" render={({ input, meta }) => (
                                                <FormGroup row>
                                                    <Label className="text-primary" for="displayName" size='lg' md={3}>Display name</Label>
                                                    <Col md={9}>
                                                        <input {...input} className="form-control" type="input" name="displayName" id="displayName" placeholder="Display Name" />
                                                        {meta.touched && meta.error && <Label className="t-bold text-danger">{meta.error}</Label>}
                                                    </Col>
                                                </FormGroup>)} />
                                        <Field
                                            name="username" render={({ input, meta }) => (
                                                <FormGroup row>
                                                    <Label className="text-primary" for="username" size='lg' md={3}>Username</Label>
                                                    <Col md={9}>
                                                        <input {...input} className="form-control" type="input" name="username" id="username" placeholder="Username" />
                                                        {meta.touched && meta.error && <Label className="t-bold text-danger">{meta.error}</Label>}
                                                    </Col>
                                                </FormGroup>)} />
                                        <Field
                                            name="azureUsername" render={({ input, meta }) => (
                                                <FormGroup row>
                                                    <Label size="lg" md={3} className="text-primary" for="azureUsername">Azure Username </Label>
                                                    <Col md={9}>
                                                    <input {...input} className="form-control" type="input" name="azureUsername" id="azureUsername" placeholder="Azure Username" />
                                                    {meta.touched && meta.error && <Label className="t-bold text-danger">{meta.error}</Label>}
                                                    </Col>
                                                </FormGroup> )} />
                                        <Field
                                            name="email" render={({ input, meta }) => (
                                                <FormGroup row>
                                                    <Label className="text-primary" for="email" size='lg' md={3}>Email</Label>
                                                    <Col md={9}>
                                                        <input {...input} className="form-control" type="email" name="email" id="email" placeholder="Email Address" />
                                                        {meta.touched && meta.error && <Label className="t-bold text-danger">{meta.error}</Label>}
                                                    </Col>
                                                </FormGroup>)} />
                                        <Field
                                            name="jobTitle" render={({ input, meta }) => (
                                                <FormGroup row>
                                                    <Label className="text-primary" for="jobTitle" size='lg' md={3}>Job Title</Label>
                                                    <Col md={9}>
                                                        <input {...input} className="form-control" type="input" name="jobTitle" id="jobTitle" placeholder="Job Title" />
                                                        {meta.touched && meta.error && <Label className="t-bold text-danger">{meta.error}</Label>}
                                                    </Col>
                                                </FormGroup>)} />
                                        <Field
                                            name="team" render={({ input, meta }) => (
                                                <FormGroup row>
                                                    <Label className="text-primary" for="team" size='lg' md={3}>Team</Label>
                                                    <Col md={9}>
                                                        <input {...input} className="form-control" type="input" name="team" id="team" placeholder="Team" />
                                                        {meta.touched && meta.error && <Label className="t-bold text-danger">{meta.error}</Label>}
                                                    </Col>
                                                </FormGroup>)} />
                                        <Field
                                            name="contactNumber" render={({ input, meta }) => (
                                                <FormGroup row>
                                                    <Label className="text-primary" for="contactNumber" size='lg' md={3}>Contact Number </Label>
                                                    <Col md={9}>
                                                        <input {...input} className="form-control" type="input" name="contactNumber" id="contactNumber" placeholder="Contact Number" />
                                                        {meta.touched && meta.error && <Label className="t-bold text-danger">{meta.error}</Label>}
                                                    </Col>
                                                </FormGroup>)} />
                                        <Field
                                            name="manager" render={({ input, meta }) => (
                                                <FormGroup row>
                                                    <Label className="text-primary" for="manager" size='lg' md={3}>Manager </Label>
                                                    <Col md={9}>
                                                        <input {...input} className="form-control" type="input" name="manager" id="manager" placeholder="Managers Name" />
                                                        {meta.touched && meta.error && <Label className="t-bold text-danger">{meta.error}</Label>}
                                                    </Col>
                                                </FormGroup>)} />
                                        {rootStore.userStore.user.practice?.id && (
                                            <Field
                                                name="practice" render={({ input, meta }) => (
                                                    <FormGroup row>
                                                        <Label className="text-primary" for="practice" size='lg' md={3}>Practice</Label>
                                                        <Col md={9}>
                                                            <input {...input} className="form-control" type="input" name="practice" id="practice" value={rootStore.userStore.user.practice.id} />
                                                            {meta.touched && meta.error && <Label className="t-bold text-danger">{meta.error}</Label>}
                                                        </Col>
                                                    </FormGroup>)} />
                                        )}
                                        
                                        {rootStore.userStore.user.roles.some(a => a.name === 'System Admin') && (
                                            
                                        <Field name="organisation" validate={required} render={({input, meta}) =>
                                            <FormGroup row>
                                                <Label className="text-primary" for="organisation" size='lg' md={3}>Organisation</Label>
                                                <Col md={9}>
                                                    <select className="form-control" defaultValue={user.organisation.id.toString()} name="organisation" id="organisation">                                                           
                                                    <option value="">Please select...</option>
                                                    {adminStore.organisations.map((organisation, index) => (
                                                        <option key={index} value={organisation.id}>{organisation.name}</option>
                                                    ))}
                                                    </select>
                                                </Col>
                                            </FormGroup>
                                         } />
                                        )}

                                        <Field name="roleName" validate={required} render={({input, meta}) =>
                                            <FormGroup row>
                                                <Label className="text-primary" for="role" size='lg' md={3}>Role</Label>
                                                <Col md={9}>
                                                    <select {...input} className="form-control" name="roleName" id="roleName" defaultValue={user.roles.length > 0 && user.roles[0]?.name.toString()}>                                                        
                                                    <option value="">Please select...</option>
                                                        {roles.map((role: IRole, index) => (
                                                            <option key={index} value={role.name}>{role.name}</option>
                                                        ))}
                                                    </select>
                                                    {meta.touched && meta.error && <Label className="t-bold text-danger">{meta.error}</Label>}
                                                </Col>
                                            </FormGroup>} />

                                        {submitError && <div className="error mb-3">{submitError}</div>}
                                        <div className="mb-3">
                                            <button type="submit" className="btn btn-primary" disabled={submitting || pristine}>Save Changes</button>
                                            <Button
                                                type="button"
                                                onClick={() => form.reset()}
                                                className="ml-2"
                                                color="secondary"
                                                disabled={submitting || pristine}>
                                                Reset
                                            </Button>
                                            <Button type="button" color="danger" className="ml-2" onClick={() => handleRemove(user.email, user.roles[0]?.name)}>Deactivate User</Button>
                                        </div>
                                        <pre>{JSON.stringify(values, ["displayName", "username", "email", "azureUsername", "jobTitle", "team", "manager", "contactNumber", "practice", "id", "name", "roleName", "organisation"], 2)}</pre>
                                        
                                    </Form>
                                )}
                            />
                        </Card>
                    </Col>
                </Row>
            </Container>
    ) || <>Loading...</>
    )
};

export default UserEdit;