import React, { Fragment } from "react";
import { observer } from "mobx-react-lite";
import { NavLink } from "react-router-dom";
import { IPatientNav } from "./PatientRecordPageLayout";

interface IPatientRecordNavBarProps {
    menuItems?: IPatientNav[]
    onPageClick?: Function
}

const PatientRecordNavBar: React.FC<IPatientRecordNavBarProps> = ({ menuItems }) => {
    const urlParams = new URLSearchParams(window.location.search);
    const pId = urlParams.get('p');
    const vId = urlParams.get('v');

    const getPageRoute = (routePath:string) => `${process.env.REACT_APP_URL_PATIENT_RECORD}${routePath}`
    const getPageQuery = () => `?p=${pId}&v=${vId}`

    return (
        <div className="d-none d-sm-flex p-0" id="patientNav">
            <nav className="sticky-top list-group list-group-flush mr-0 shadow-sm border-right">
                {menuItems.map((menuItem: IPatientNav, index: number) => (
                    (menuItem.displayOnMenu && (
                    <Fragment key={index}>
                        <NavLink exact activeClassName="active" to={{ pathname: getPageRoute(menuItem.route), search: getPageQuery(), state: { title: menuItem.title } }} className="list-group-item pl-3 pr-4 py-2 d-none d-md-block list-group-item-action" style={{whiteSpace:"nowrap"}}>
                            <img src={menuItem.imageSrc} width="48" className="mr-2" />
                            <span className="ml-3 d-none d-lg-inline font-weight-bold">{menuItem.title}</span>
                        </NavLink>
                        <NavLink exact activeClassName="active" key={index} to={{ pathname: getPageRoute(menuItem.route), search: getPageQuery(), state: {  } }} className="list-group-item px-2 py-1 d-block d-md-none list-group-item-action">
                            <img src={menuItem.imageSrc} width="36" alt={menuItem.title} />
                        </NavLink>
                    </Fragment>
                    ))
                ))}
            </nav>
        </div>
    )
}
export default observer(PatientRecordNavBar)