import { FhirJwtToken, IFhirAvailability, IFhirBundle } from 'app/models/fhir';
import { IUser } from 'app/models/user';

let yhcrJwt: FhirJwtToken = null;
let yhcrJwtNhs: string = null;
let tknRequestDetails: IJwtRequest = null;
let dtJwtExpires = null;

export interface tokenValidity {
    valid: boolean,
    nhsNumber: string,
    expires: Date,
    token: FhirJwtToken
}

const headers = (withBearer: boolean) => {
    const token = window.localStorage.getItem('jwt');
    let h:any = {
        "Content-Type": "application/json", 
        "Accept": "application/json",
    }
    if (withBearer)
        h["Authorization"] = `Bearer ${token}`;   
        
    return h;
}

const headerDocuments = (withBearer: boolean) => {
    const token = window.localStorage.getItem('jwt');
    let h:any = {
        "Content-Type": "application/json", 
        "Content-Disposition": "inline; filename=YhcrDocument",
        "Accept": "application/pdf"
    }
    if (withBearer)
        h["Authorization"] = `Bearer ${token}`;   
    
    return h;
}

const validateYhcrJwt = (nhsNumber: string) : Promise<tokenValidity> => {
    return new Promise((resolve) => {
            return resolve({ 
            valid: nhsNumber === yhcrJwtNhs && new Date() < dtJwtExpires,
            nhsNumber: yhcrJwtNhs,
            expires: dtJwtExpires,
            token: yhcrJwt
        })});
}

const updateToken = (tkn:FhirJwtToken, nhsNumber: string) => {
    dtJwtExpires = new Date();            
    dtJwtExpires = new Date(dtJwtExpires.getTime() + (tkn.expires_in * 1000));
    yhcrJwt = tkn;
    yhcrJwtNhs = nhsNumber;
}

const getToken = (req: IJwtRequest) => {
    tknRequestDetails = {
        nhsNumber: req.nhsNumber,
        isAnonymous: isAnonymous(),
        username: req.username,
        forename: req.forename,
        surname: req.surname
    }
    return Jwt.post(tknRequestDetails)
    .then(tkn => { return updateToken(tkn, req.nhsNumber) })
}

const getBaseUri = () => process.env.REACT_APP_YHCRAPI_BASE_URI;
const isAnonymous = () => process.env.REACT_APP_IS_ANONYMOUS === "true";

const requests = {    
    post: async (url: string, body: any) => fetch(`${getBaseUri()}${url}`, { method: "post", headers: headers(true), body: JSON.stringify(body)}),
    postNoHeader: async (url: string, body: any) => fetch(`${getBaseUri()}${url}`, { method: "post", headers: headers(false), body: JSON.stringify(body)}),
    resource: async (url: string, body: any) : Promise<IFhirBundle | IFhirAvailability> => {      
        let uri = `${getBaseUri()}${url}`;
        return fetch(uri, { method: "post", headers: headers(true)})
                .then(resp => resp.json())
                .then(obj => {console.log(obj);return obj })
    },
    document: async (url: string, body: IFhirDocumentRequest) => {        
        if (!yhcrJwt || dtJwtExpires < new Date()) 
            await getToken(tknRequestDetails);        

            return fetch(`${getBaseUri()}${url}`, 
            { 
                method: "post", 
                headers: headerDocuments(true), 
                body: JSON.stringify({
                yhcrJwt: body.yhcrJwt,
                isAnonymous: body.isAnonymous,
                documentId: body.documentId,
                
            }) })
    }   
}

const Jwt = {
    post: (values: IJwtRequest): Promise<FhirJwtToken> => 
            requests
                .post(`/jwt`, values)
                .then(resp => resp.json())
                .then(function(t) { window.localStorage.setItem("yhcrJwt", t.access_token); return t})
}

const Fhir = {
    get: (req: IFhirRequest): Promise<IFhirBundle> => requests.post(`/fhir/`, req).then(r => r.json()),
    paging: (req: IFhirPagingRequest): Promise<IFhirBundle> => requests.post(`/fhirPaging`, req).then(r => r.json()),
    das: (req: IFhirAvailabilityRequest): Promise<IFhirAvailability> => requests.post(`/das`, req).then(r => r.json()),
    document: (req: IFhirDocumentRequest): Promise<any> => requests.post(`/document`, req)
}

const JWT = {
    renew: (req: IJwtRequest) : Promise<void> => getToken(req),
    exists: (): boolean => !!yhcrJwt,
    isValid: (): boolean => (yhcrJwt || dtJwtExpires < new Date()) ? true : false,
    validate: (nhsNumber: string) => validateYhcrJwt(nhsNumber)
}

export interface IJwtRequest {
    nhsNumber: string,
    isAnonymous: boolean,
    username:string,
    forename: string,
    surname: string
}
export interface IFhirRequest {
    resource: string,
    nhsNumber: string,
    countPerPage: number,
    isAnonymous: boolean,
    user: IUser,
    yhcrJwt: string
}
export interface IFhirAvailabilityRequest {
    nhsNumber: string,
    isAnonymous: boolean,
    yhcrJwt: string
}
export interface IFhirDocumentRequest {
    user: IUser,
    isAnonymous: boolean,
    documentId: string,
    nhsNumber: string,
    yhcrJwt: string
}
export interface IFhirPagingRequest {
    searchGuid: string,
    pageOffset: number,
    countPerPage: number,
    isAnonymous: boolean,
    yhcrJwt: string
}

export default {
    Fhir, 
    JWT
}