import React, { useContext } from "react";
import { Patient } from "../../../app/models/inpatient";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import SearchStore from "../../../app/stores/searchStore";
import { ShortDateOnlyFormat } from "features/patientrecord/common/DateTimeFunctions";

interface IProps {
  handleSelectPatient;
}

const CareHomeResults: React.FC<IProps> = ({ handleSelectPatient }) => {
  const searchStore = useContext(SearchStore);

  return (
    <>
      <div className="card border-secondary text-center">
        <div className="card-header">
          <h4 className="mb-0">Search Results</h4>
        </div>
        <div className="card-body">
          {searchStore.isPatientsLoaded &&
            searchStore.patients &&
            searchStore.patients.length > 0 &&
            searchStore.patients.map((patient: Patient, index) => (
              <>
                <div className="list-group list-group-flush" id="Div2">
                  <div className="list-group-item">
                    <div className="row">
                      <div className="col-3">
                        <Link onClick={() => handleSelectPatient(patient)}>
                          <a className="btn btn-primary btn-sm" href="#">
                            {patient.systemPublicIdentifier.value}
                          </a>
                        </Link>
                      </div>
                      <div className="col-9">
                        <span className="float-right">
                          <small className="text-muted">
                            Born:{" "}
                            <strong>
                                {ShortDateOnlyFormat(patient.dateOfBirth)}
                            </strong>
                          </small>
                        </span>
                        <strong>
                          {patient.name.forename} {patient.name.surname}
                        </strong>
                        <div className="">
                          {patient.contactDetails && (
                            <span className="small text-muted">
                              {patient.contactDetails.address.line1}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))}
        </div>
      </div>
    </>
  );
};
export default observer(CareHomeResults);
