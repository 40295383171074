import { useEffect, useState } from 'react';
import {  Col, Row, ListGroup, ListGroupItem, Card, CardHeader, Container} from "reactstrap";

const PriorityList = () => {
    const [ data, setData ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ error, setError ] = useState();


    const Priorities = () => [
           { id: 1, label: "1 - Immediate", color: "crimson" },
           { id: 2, label: "2 - Urgent", color: "tomato" },
           { id: 3, label: "3 - Moderate", color: "goldenrod" },
           { id: 4, label: "4 - None Urgent", color: "teal" },
           { id: 5, label: "5 - Routine", color: "CornflowerBlue" },
           { id: 0, label: "0 - Reset button/ Unable to go to CRU", color: "black" }
        ];

        const dateTimeFormatter = (timeOnly) => {
            if (timeOnly) 
                return new Intl.DateTimeFormat("en-GB", {
                hour: 'numeric',
                minute: 'numeric'
                });
                return new Intl.DateTimeFormat("en-GB", {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
                 });
            }

    useEffect(() => {      

        // list out the API calls that you need to make
        // giving each one a tag to identify it after the data comes back
        let dataItems = [
            { tag: "Inpatients", url: 'http://sepia.xrothgen.nhs.uk/webservices/1.1/BulkData/Inpatients/TRNA4?includeTest=true' },
            { tag: "Queries", url: 'http://sepia.xrothgen.nhs.uk/webservices/1.1/BulkData/Queries/TRNA4?type=PrioritySubset' }
        ]

        // Indicate to the user that the page is loading; a return
        // method below shows a different view for a loading page

        setIsLoading(true);

        // Promise all executes all of the queries at once
        // - the 1st then for the Promise.all is an array of all of the fetch responses
        //   that are merged together with a forEach loop on the inpatients array

        Promise.all(dataItems.map(dataItem =>

            // use fetch to bring back the response from the API
            //  - the 1st then converts the response to JSON
            //  - the 2nd then puts the json in to a custom class so that 
            //    it can be combined with the tag again

            fetch(dataItem.url)
                .then(r => r.json())
                .then(j => {
                    return {
                        tag: dataItem.tag,
                        data: j
                    }
                })
                .catch(e => {
                    console.log(e);
                    setError(e);
                    setIsLoading(false)
                })
        )).then(r => {
            console.log(r);

            // gets the returned inpatients set, loops through it
            // adds the Queries for that patient to a Queries property

            let inpatients = r.filter(f => f.tag === "Inpatients")[0];
            inpatients.data.forEach((di) => {
                    di.Queries = r.filter(f => f.tag === "Queries")[0]
                        .data.filter(f => f.PatientLinkId === di.Patient.SystemInternalIdentifier.Value);
            })

            // set the state to the modified inpatients array
            // - the filter is used in this scenario to exclude empty beds
            //   and filter to only those patients with a specific query

            inpatients = inpatients.data.filter(f => 
                f.SystemPublicIdentifier.Value !== "" && 
                f.Queries.filter(q => q.Query.Id === "GEN.PMAN").length > 0
            );

            setData(inpatients);

            // Tell the component the data is finished loading so 
            // the correct view is displayed to the user
            setIsLoading(false);
        })

    }, [])

    // While the data is loading, display this:

    if (isLoading)
        return <div className="alert alert-info"><strong>Loading...</strong> Please wait patiently while the dashboard data loads.</div>

    // If the data fails to load, display this:

    if (error)
        return <div className="alert alert-danger"><strong>Error</strong> {error.message}</div>

    // Otherwise, display this:

    return (
        <>       
        <Container>
            <h1 className="text-center">Patient Priority Dashboard</h1>
            {Priorities().map(p => (
                <Col>
                    <Card className="m-3 mt-5 shadow" data-priority={`${p.id}`} style={{border: `solid 2px ${p.color}`}}>
                        <CardHeader className="py-1 position-relative"style={{backgroundColor: p.color}}>
                            <div className="position-absolute text-xl t-bold text-white px-3 py-2" style={{backgroundColor: p.color, zIndex:1000, top:'-36px', left: '-2px'}}>{p.label}</div>
                        </CardHeader>
                            <ListGroup flush>
                            {data && data.length ? data.filter(f => f.Queries.filter(q => q.ResponseValue === p.label).length > 0).map((d, i)  => (
                            <>
                                <ListGroupItem key={`inpatient_${i}`}>
                                    <Row>
                                    <Col md="2" className="badge text-md text-white me-3" style={{backgroundColor: p.color}}>{d.SystemPublicIdentifier?.Value}</Col>                                 
                                    <Col md="3"> {d.Patient?.Name?.Forename} {d.Patient?.Name?.Surname}  </Col>
                                    {d.Queries.filter(query => query.Query?.Id === 'GEN.PMAN')
                                    .map(q => (                                            
                                    <>
                                    <Col md="2"> 
                                    <div>
                                    {dateTimeFormatter(false).format(new Date(q.ResponseDateTime))}&nbsp;
                                    {dateTimeFormatter(true).format(new Date(q.ResponseDateTime))}
                                    </div>
                                    </Col>
                                    <Col md="1">{d.CurrentLocation.Location?.Id}</Col>
                                    <Col md="3"> Notes </Col>
                                    </>
                                    ))}                               
                                    </Row> 
                                </ListGroupItem>                                
                            </>
                            )) : <></>}
                            </ListGroup>
                    </Card>                 
                </Col>                   
            ))}        
        </Container>
        </>
    )
}

export default PriorityList;