import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { MouseEventHandler } from 'react';
import {Link} from "react-router-dom";

interface ILargeButtonProps {
    title: String,
    url: String,
    isSelected?: Boolean,
    badge: String | Number | React.ReactNode,
    onClick?: MouseEventHandler,
    children?: React.ReactNode
}

const LargeButton: React.FC<ILargeButtonProps> = (props: ILargeButtonProps) => {

    return (
        <>
        <div className="card border border-secondary card-menu nav-menu nav-link rounded list-group list-group-flush p-0 mb-3 text-xl" onClick={props.onClick}>
            <Link className={"dropdown-item  pl-4 py-3" + (props.isSelected ? ' active' : '')}  to={props.url ? props.url : '#'}>
                
                    <span className="badge badge-primary bg-primary text-white text-md float-right rounded-pill">
                        {props.badge ? <>{props.badge}</> : <FontAwesomeIcon icon={faSpinner} spin />}
                    </span>
                {props.title}
            </Link>
        </div>
        {(props.isSelected) && (
            <div className='mb-4 pl-3 ps-3'>
            {props.children}
            </div>
        )}
        </>
    )

}

export default LargeButton