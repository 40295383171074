import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { RootStoreContext } from 'app/stores/rootStore';
import React, { useContext, useEffect } from 'react';

const AuthChecker: React.FC = () => {    
    const history = useHistory();
    const rootStore = useContext(RootStoreContext);

    console.log(rootStore.userStore.isLoggedIn, rootStore.commonStore.isTokenValid())
    if (rootStore.userStore.isLoggedIn && !rootStore.commonStore.isTokenValid().valid) {
        // let uri = window.location.pathname + window.location.search;
        // history.push(`/?login_redirect=${Buffer.from(uri).toString("base64")}&err=${rootStore.commonStore.isTokenValid()?.reason}`);
        // rootStore.userStore.logout();
        // console.log("Auth checker logged the user out of the system.");
    }

    return (
        <></>
    )
}
export default observer(AuthChecker)

