import React, { Fragment, useContext } from "react";
import { observer } from "mobx-react-lite";
import { Col } from "reactstrap";
import { IFhirDocument } from "app/models/fhir";
import yhcragent from "app/api/yhcragent";
import { RootStoreContext } from "app/stores/rootStore";
import PatientRecordStore from "app/stores/patientRecordStore";

interface IFhirDocumentProps {
    document: IFhirDocument
}

const YhcrDocuments: React.FC<IFhirDocumentProps> = ({ document }) => {
    const rootStore = useContext(RootStoreContext);
    const patientRecordStore = useContext(PatientRecordStore);

    const onDocumentClick = (document: IFhirDocument) => {
        if (document.content && document.content.length > 0) {
            let id: string[] = document.content[0].attachment.url?.value.split('/');
            let docRequest = { 
                documentId: id.pop(), 
                user: rootStore.userStore.user, 
                isAnonymous: process.env.REACT_APP_IS_ANONYMOUS === "true", 
                yhcrJwt: window.localStorage.getItem("yhcrJwt"),            
                nhsNumber: process.env.REACT_APP_IS_ANONYMOUS === "true" ? 
                    window.localStorage.getItem("yhcrSandpitPatient") : 
                    patientRecordStore.patient?.nhsNumber?.value 
                };
                console.log(docRequest);
                yhcragent.Fhir.document(docRequest) 
                    .then((r: Response) => { 
                        console.log(r);
                        return r.blob()
                    })
                    .then((b: Blob) => {   
                        console.log(b);         
                        const fileURL = URL.createObjectURL(b);
                        window.open(fileURL);
                    })
        }
    }

    return (
        <Fragment>
            <Col className="py-2 lh-xs-115" onClick={(e) => onDocumentClick(document)}>       
                {document.type.coding ? document.type.coding?.map((t, i) => (
                    <div className={`font-weight-bold text-sm pr-2 mr-2 text-lg`} key={i}>{t.display?.value}</div>
                )) : <div className={`font-weight-bold text-sm pr-2 mr-2 text-lg`}>{document.type?.text?.value}</div>} 
                <div className="text-md text-primary">{document.description?.value}</div>
            </Col>
            <Col xl={3} lg={4} sm={6} xs={5} className="lh-xs-115 py-2">
            {document.author && document.author.length > 0 ? (
                    <><span className="text-xs text-uppercase">Author(s) </span>
                    {document.author.map((a, i) => (
                    <div className="text-md font-weight-bold" key={i}>&bull; {a.display?.value}</div>
                    ))} 
                    </>) : <></>}  
            </Col>
            <Col xs="2" className="py-2 text-center">
                <span className={`badge text-md badge-${document.docStatus?.value === "final" ? "primary" :"secondary"}`}>{document.docStatus?.value}</span>
            </Col>
        </Fragment>
    )

}
export default observer(YhcrDocuments)