import React, { Fragment } from 'react'
import { Container } from 'reactstrap'
import { observer } from 'mobx-react-lite'
import CareHomeCapacityTable from './CareHomeCapacityTable'


const CareHomeCapacityMain = () => {
    return (
        <Fragment>
            <Container fluid="true" className="ml-3 p-2" >
                <h2 className="text-gradient mb-0">Care Homes</h2>
                <p>Care Homes data is provided by Rotherham Metropolitan Borough Council's Connect to Support service.</p>
                <CareHomeCapacityTable />
            </Container>
        </Fragment>
    )

}
export default observer(CareHomeCapacityMain)