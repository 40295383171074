import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ListGroup, Row, Col, Card, Container, CardBody } from 'reactstrap';
import { observer } from 'mobx-react-lite';
import { IUser, IRole, IOrganisation, IPractice } from "../../app/models/user";
import AdminStore from '../../app/stores/adminStore';
import { RootStoreContext } from 'app/stores/rootStore';


interface IProps {
    users: IUser[],
    handleSelectUser,
    roles: IRole[],
    organisations: IOrganisation[],
    practices?: IPractice[]
}

const UserAdministration: React.FC<IProps> = ({ roles, organisations, practices }) => {
    const adminStore = useContext(AdminStore)
    const rootStore = useContext(RootStoreContext);
    const refOrg = React.useRef<HTMLSelectElement>(null);
    const refPractice = React.useRef<HTMLSelectElement>(null);
    const refRole = React.useRef<HTMLSelectElement>(null);
    const refText = React.useRef<HTMLInputElement>(null);
    const [selectedUsers, setSelectedUsers] = useState<IUser[]>(null);

    const handleUserSearch = (searchText: string) => adminStore.searchText(searchText, rootStore.userStore.user);    
    const handleFilter = () => {
        let organisation: string = (refOrg.current && refOrg.current.value) || "";
        let role: string = (refRole.current && refRole.current.value) || "";
        let practice: string = (refPractice.current && refPractice.current.value) || "";
        
        localStorage.setItem("rhr_UserAdminFilter", JSON.stringify({ organisation, role, practice }));

        if (organisation === "" && role === "" && practice === "") 
            setSelectedUsers(adminStore.sortedUsers)
        else
            setSelectedUsers(
                adminStore.sortedUsers.filter(u =>
                    (organisation ? u.organisation.name === organisation : true) &&
                    (practice ? u.practice && u.practice.name === practice : true) &&
                    (role ? u.roles.filter(r => r.name === role).length > 0 : true)
                )
            )
    }

    const handleReset = () => {
        if (refText.current) refText.current.value = "";
        handleUserSearch("");
        if (refOrg.current) refOrg.current.selectedIndex = 0;
        if (refPractice.current) refPractice.current.selectedIndex = 0;
        if (refRole.current) refRole.current.selectedIndex = 0;
        handleFilter();
    }

    useEffect(() => {
        setSelectedUsers(adminStore.sortedUsers)  
        if (localStorage.getItem("rhr_UserAdminFilter")) {
            if (refOrg.current) refOrg.current.value = JSON.parse(localStorage.getItem("rhr_UserAdminFilter")).organisation;
            if (refPractice.current) refPractice.current.value = JSON.parse(localStorage.getItem("rhr_UserAdminFilter")).practice;
            if (refRole.current) refRole.current.value = JSON.parse(localStorage.getItem("rhr_UserAdminFilter")).role;
            handleFilter();
        }
        
    }, [adminStore.users])

    return (
        <Container fluid>
            <h2 className="text-gradient text-primary mb-4">
                User Administration
            </h2>
            <Row>
                <Col>
                    <Card className="card-menu mb-5">
                        <CardBody className='bg-secondary text-white py-1 text-xl'>
                            <span className='text-bold t-bold font-weight-bold'>{selectedUsers && selectedUsers.length}</span> users found.
                        </CardBody>
                        <ListGroup flush>
                            {selectedUsers && selectedUsers.map((user: IUser, index: number) => (
                                <Link key={index} className="lh-xs-125 p-0 align-text-top list-group-item list-group-item-action" to={`/system/admin/users/view/?u=${user.email}`}>
                                    <div className={"d-inline-block align-top pe-2 pr-2 " + (user.dateDeleted?.toString() === '0001-01-01T00:00:00' || user.dateDeleted === null ? "bg-success" : "bg-danger")} style={{ width: "45px", height: '45px' }}>&nbsp;</div>
                                    <div className="d-inline-block ps-3 pl-3 pt-1">
                                        <span className="text-xl text-bold t-bold font-weight-bold">{user.displayName || user.email}</span>
                                        <div className="text-sm">
                                            {(user.practice ? user.practice.name : user.organisation.name)}
                                            {user.roles.length ? (
                                                <>&nbsp; - {user.roles.map((role: IRole, index: number) => <span key={index}>{role.name}&nbsp;</span>)}</>
                                            ) : (
                                                <>&nbsp; - No role</>
                                            )}
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </ListGroup>
                    </Card>
                </Col>
                <Col>
                    <Card className="card-menu text-xl lh-lg mb-5 bg-light" body>
                        <label htmlFor='adminUsersSearchTextBox' className="text-bold t-bold font-weight-bold">Search</label>
                        <input id="adminUsersSearchTextBox" ref={refText} className="form-control my-1" onChange={e => handleUserSearch(e.target.value)} type="text" placeholder="Search name, email, manager etc" />
                        <hr />
                        {(rootStore.userStore.user as IUser).roles.some(a => a.name === 'System Admin') && (
                            <>
                                <label htmlFor="organisationSelect" className="text-bold t-bold font-weight-bold">Organisation Filter</label>
                                <select id="organisationSelect" ref={refOrg} onChange={e => handleFilter()} className="form-control">
                                    <option value="">Show all</option>
                                    {organisations.filter((org: IOrganisation) => org.isActive).map((org: IOrganisation, index) => (
                                        <option key={index} value={org.name}>{org.name} ({org.code})</option>
                                    ))}
                                </select>
                                <hr />
                            </>
                        )}
                        <label htmlFor="practiceSelect" className="text-bold t-bold font-weight-bold">Department/Practice Filter</label>
                        <select id="practiceSelect" ref={refPractice} onChange={e => handleFilter()} className="form-control">
                            <option value="">Show all</option>
                            {practices.map((practice: IPractice, index) => (
                                <option key={index} value={practice.name}>{practice.name}</option>
                            ))}
                        </select>
                        <hr />
                        <label htmlFor="roleSelect" className="text-bold t-bold font-weight-bold">Role Filter</label>
                        <select id="roleSelect" ref={refRole} onChange={e => handleFilter()} className="form-control">
                            <option value="">Show all</option>
                            {refOrg.current && refOrg.current.value ?                            
                            roles.filter((role:IRole) => role.organisation && role.organisation.name === refOrg.current.value).map((role: IRole, index) => (
                                <option key={index} value={role.name}>{role.name}</option>
                            )) :
                            roles.map((role: IRole, index) => (
                                <option key={index} value={role.name}>{role.name}</option>
                            ))
                            }
                        </select>
                        <hr />
                        <button className="btn btn-large btn-secondary" type="reset" onClick={(e) => handleReset()}>Reset</button>
                    </Card>
                </Col>
            </Row>
        </Container>
        
    )
}
export default observer(UserAdministration)