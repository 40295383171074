import React, { useContext } from "react";
import { ListGroup, CardBody, Row, Col, Card, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { IRole } from "../../app/models/user";
import AdminStore from '../../app/stores/adminStore';
import { RootStoreContext } from "../../app/stores/rootStore";

interface IProps {    
    roles: IRole[]
}

const RoleList: React.FC<IProps> = ({ roles }) => {
    const adminStore = useContext(AdminStore)
    const rootStore = useContext(RootStoreContext);
    const minWidthStyle = {
        minWidth: '110px'
    }
    return (

        <Container fluid>
            <h2 className="text-gradient text-primary mb-4">
                Roles Administration
            </h2>
            <Row>
                <Col xs="6">
                    <Card className="card-menu mb-5">
                        <CardBody className='bg-secondary text-white py-1 text-xl'>
                            <span className='text-bold t-bold font-weight-bold'>{adminStore.adminStats?.roles?.data.length}</span> Roles found.
                        </CardBody>
                        <ListGroup flush>                      
                        {roles.map((role: IRole, index) => (    
                                <Link key={`roleList${index}`} className="lh-xs-125 p-0 align-text-top list-group-item list-group-item-action" to={`/system/admin/roles/view/?u=${role.name}`}>
                                    <div className=" ps-3 pl-3 pt-1">
                                    <span className="text-xl text-bold t-bold font-weight-bold">{role.name}</span>
                                        <div className="text-sm">                                            
                                            {(role.organisation?.name && (
                                                <>{role.organisation?.name}</>
                                            )) || (
                                                <>No Organisation</>
                                            )}
                                        </div>
                                    </div>                                   
                                </Link>
                            ))}
                        </ListGroup>                     
                    </Card>
                </Col>                
            </Row>
        </Container>
    // <Container fluid={true}>
    //     <Card className="shadow mt-3  border-secondary">
    //         <CardHeader className="mx-0 " style={{ backgroundColor: '#3c3c64', opacity: '0.9' }}>
    //             <div className="pb-3 px-3 pt-0">
    //                 <h3 className="py-2 my-0 text-center text-light">
    //                     Roles Admin
    //                     </h3>
    //             </div>
    //             {rootStore.userStore.user.roles.some(a => a.name === 'System Admin') && (
    //                 <Row>
    //                     <Col sm='6' lg='9'>
    //                         <CreateNewRole /> &nbsp;
    //                         <AddUserToRole roles={roles} users={adminStore.users} />
    //                     </Col>
    //                 </Row>
    //                 )}
    //         </CardHeader>
    //         <ListGroup flush>
    //         {roles.map((role: IRole, index) => (
    //             <ListGroupItem key={`roleList${index}`}>
    //                 <Row>
    //                     {rootStore.userStore.user.roles.some(a => a.name === 'System Admin') && (
    //                         <Col lg="1" className="border-right">
    //                             <div className="text-lg text-center mt-2">
    //                                 <EditRole role={role} />
    //                             </div>
    //                         </Col>
    //                     )}
    //                     <Col lg="2" className="lh-xs-125 pt-2 text-center border-right" style={minWidthStyle}>
    //                         <div className="text-lg text-primary pointer t-bold mt-2 ">
    //                             <u>{role.name}</u>
    //                         </div>
    //                     </Col>
    //                     {role.organisation?.name ? (
    //                         <Col lg="3" className="border-right" >
    //                             <div className="text-lg mt-2 text-center lh-sm">
    //                                 {role.organisation?.name}
    //                             </div>
    //                             <div className="text-lightgrey text-muted small text-center">
    //                                 Organisation
    //                             </div>
    //                         </Col>) : (
    //                         <Col lg="3" className="border-right" >
    //                             <div className="text-lg mt-3 text-center lh-sm">

    //                             </div>
    //                             <div className="text-danger small text-center">
    //                                 No Organisation
    //                             </div>
    //                         </Col>
    //                     )}                            
    //                     {role.dataFilter ? (
    //                     <Col lg="2" className="border-right" >
    //                         <div className="text-lg  mt-2 text-center lh-sm">
    //                             {role.dataFilter}
    //                             <div className="text-lightgrey text-muted small text-center">
    //                                 Data Filter
    //                             </div>
    //                         </div>
    //                     </Col>
    //                     ) : (
    //                     <Col lg="2" className="border-right" >
    //                         <div className="text-lg  mt-3 text-center lh-sm">
    //                             <div className="text-danger small text-center">
    //                                 No Data Filter
    //                             </div>
    //                         </div>
    //                     </Col>
    //                     )}
    //                     <Col lg="3" className="border-right" >
    //                         <div className="text-lg  mt-3 text-center lh-sm">
    //                             <div className="small text-center">
    //                                 {role.userAccess.map((access, index) => (
    //                                     <span>{access.name}, </span>
    //                                 ))}
    //                             </div>
    //                         </div>
    //                     </Col>
    //                     <Col lg="1" className="border-right text-center" >               
    //                         <Button size="sm" className="my-3" color="danger" ><FontAwesomeIcon icon={faTimesCircle} /></Button>
    //                     </Col>
    //                 </Row>
    //             </ListGroupItem>
    //         ))}
    //         </ListGroup>
    //     </Card>
    // </Container>
    )}
export default observer(RoleList)