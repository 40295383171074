
/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken 
 */
export async function GraphUserInvite(accessToken, invitedUserEmailAddress, invitedUserDisplayName) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    const endpoint = `https://graph.microsoft.com/v1.0/invitations`;

    headers.append("Authorization", bearer);

    const body = {
        invitedUserEmailAddress,
        invitedUserDisplayName,
        inviteRedirectUrl: "https://rhr.care",
        invitedUserType: "Guest",
        sendInvitationMessage: false
    }

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body)
    };

    return fetch(endpoint, options)
        .then(response => response.json())
        .then(json => { 
            console.log(json); 
        })
        .catch(error => console.log(endpoint, error));
}