import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ListGroup, Row, Col, Card, Container, CardBody } from 'reactstrap';
import { observer } from 'mobx-react-lite';
import { IUser, IRole, IOrganisation, IPractice } from "../../app/models/user";
import { RootStoreContext } from 'app/stores/rootStore';
import AuditStore from "../../app/stores/auditStore";
import { AuditValues, AuditItem } from 'app/models/audit';

interface IProps {
    users: IUser[],
    roles: IRole[],
    organisations: IOrganisation[],
    practices?: IPractice[]
}

const AuditViewer: React.FC<IProps> = ({ users, roles, organisations, practices }) => {
    
    const date = new Date();
    const rootStore = useContext(RootStoreContext);
    const refOrg = React.useRef<HTMLSelectElement>(null);
    const refPractice = React.useRef<HTMLSelectElement>(null);
    const refRole = React.useRef<HTMLSelectElement>(null);
    const refUser = React.useRef<HTMLSelectElement>(null);
    const [dateTo, setDateTo] = useState<Date>(new Date(date.setDate(date.getDate())));
    const [dateFrom, setDateFrom] = useState<Date>(new Date(date.setDate(date.getDate() - 14)));
    const [selectedAuditItems, setSelectedAuditItems] = useState<AuditItem[]>([]);
    const [selectedDefaultAuditItems, setSelectedDefaultAuditItems] = useState<AuditItem[]>([]);
  
    useEffect(()=> {
        let auditValues: AuditValues = { fromDate: dateFrom, toDate: dateTo }
        console.log(auditValues);
        rootStore.auditStore.byDate(auditValues);
    }, [])

    useEffect(() => {
        setSelectedAuditItems(rootStore.auditStore.sortedAudit)
        setSelectedDefaultAuditItems(rootStore.auditStore.sortedAudit);
    }, [rootStore.auditStore.isAuditLoaded])

    const handleFilter = () => {
        let organisation: string = (refOrg.current && refOrg.current.value) || "";
        let role: string = (refRole.current && refRole.current.value) || "";
        let practice: string = (refPractice.current && refPractice.current.value) || "";
        let user: string = (refUser.current && refUser.current.value) || "";
    }

    const handleReset = () => {
        if (refOrg.current) refOrg.current.selectedIndex = 0;
        if (refPractice.current) refPractice.current.selectedIndex = 0;
        if (refRole.current) refRole.current.selectedIndex = 0;
        if (refUser.current) refUser.current.selectedIndex = 0;
        handleFilter();
    }

    return (
        <Container fluid>
            <h2 className="text-gradient text-primary mb-4">
                Audit Viewer
            </h2>
            <Row>
                <Col md={8}>
                    <Card className="card-menu mb-5">
                        <CardBody className='bg-secondary text-white py-1 text-xl'>
                            <span className='text-bold t-bold font-weight-bold'>{selectedAuditItems && selectedAuditItems.length}</span> entries found.
                        </CardBody>
                        <ListGroup flush>
                            {selectedAuditItems && selectedAuditItems.map((audit: AuditItem, index: number) => (
                                <div key={index} className="lh-xs-125 p-0 align-text-top list-group-item">
                                    <div className="row">
                                        <div className="col-2 text-lg text-center p-2">{new Date(audit.actionDate).toLocaleDateString()}</div>
                                        <div className="col-1 text-lg text-center p-2">{new Date(audit.actionDate).toLocaleTimeString().substring(0,5)}</div>
                                        <div className="col-9 pt-2 lh-md">
                                            <strong>{audit.username}</strong> {audit.description.replace(audit.username, "")}
                                            <div className="text-xs py-1 text-secondary">
                                                {audit.module} 
                                                {audit.module === "PatientRecord" && audit.patientId ?
                                                <> <Link className="text-secondary text-decoration-underline" to={`/patient/summary?p=${audit.patientId}&v=`}>{audit.patientId}</Link></>
                                                : <></>}
                                                &gt; {audit.action}                                     
                                                {audit.module === "PatientRecord" && audit.documentId ?
                                                <> <Link className="text-secondary text-decoration-underline" to={`/patient/documents?p=${audit.patientId}&v=&d=${audit.documentId}`}>{audit.documentId}</Link></>
                                                : <></>}
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </ListGroup>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card className="card-menu text-xl lh-lg mb-5 bg-light" body>
                        {(rootStore.userStore.user as IUser).roles.some(a => a.name === 'System Admin') && (
                            <>
                                <label htmlFor="organisationSelect" className="text-bold t-bold font-weight-bold">Organisation Filter</label>
                                <select id="organisationSelect" ref={refOrg} onChange={e => handleFilter()} className="form-control">
                                    <option value="">Show all</option>
                                    {organisations.filter((org: IOrganisation) => org.isActive).map((org: IOrganisation, index) => (
                                        <option key={index} value={org.name}>{org.name} ({org.code})</option>
                                    ))}
                                </select>
                                <hr />
                            </>
                        )}
                        <label htmlFor="practiceSelect" className="text-bold t-bold font-weight-bold">Department/Practice Filter</label>
                        <select id="practiceSelect" ref={refPractice} onChange={e => handleFilter()} className="form-control">
                            <option value="">Show all</option>
                            {practices.map((practice: IPractice, index) => (
                                <option key={index} value={practice.name}>{practice.name}</option>
                            ))}
                        </select>
                        <hr />
                        <label htmlFor="roleSelect" className="text-bold t-bold font-weight-bold">Role Filter</label>
                        <select id="roleSelect" ref={refRole} onChange={e => handleFilter()} className="form-control">
                            <option value="">Show all</option>
                            {refOrg.current && refOrg.current.value ?                            
                            roles.filter((role:IRole) => role.organisation && role.organisation.name === refOrg.current.value).map((role: IRole, index) => (
                                <option key={index} value={role.name}>{role.name}</option>
                            )) :
                            roles.map((role: IRole, index) => (
                                <option key={index} value={role.name}>{role.name}</option>
                            ))
                            }
                        </select>
                        <hr />
                        <button className="btn btn-large btn-secondary" type="reset" onClick={(e) => handleReset()}>Reset</button>
                    </Card>
                </Col>
            </Row>
        </Container>
        
    )
}
export default observer(AuditViewer)