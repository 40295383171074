import React from "react";
import { Link } from 'react-router-dom';
import { VisitAppointment } from "../../app/models/inpatient";
import { Row, Col } from "reactstrap";
import { observer } from "mobx-react-lite";
import agent from "app/api/agent";
import PatientRecordListDisplay, { IFilter, ISpecialFilter, ISort } from "./common/PatientRecordListDisplay";
import { ShortDateOnlyFormat, ShortTimeOnlyFormat } from "./common/DateTimeFunctions";

const SelectedInpatientAppointmentsList: React.FC = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const loadData = () => {
    return agent.Patient.Appointments({
        systemIdentifier: urlParams.get("p"),
        patientIdentifier: null,
        visitIdentifier: null,
      })
  };

  const sorts: ISort[] = [
    { label: "Date", accessPath: "appointmentDateTime", type: "Date" },
    { label: "Type", accessPath: "type.description", type: "String" }
  ]

  const filters: IFilter[] = [
    { label: "Clinician", accessPath: "clinician.name", specialType: "Clinician" } as ISpecialFilter,
    { label: "Status", accessPath: "status"},
    { label: "Location", accessPath: "location.location.description" },
    { label: "Date", accessPath: "appointmentDateTime", specialType: "Date" } as ISpecialFilter
  ]

  const statusToBadge = (status: string) => {
    let newStatus = "";
    let color = {
        foreground: "white",
        background: "secondary"
    }
    switch (status) {
        case "DNA":
        case "dna":
            newStatus = "dna";
            color.background = "danger";
            break;
        case "BOOKED":
        case "booked":
            newStatus = "booked";
            color.background = "info";
            break;
        case "ATTENDED":
        case "attended":
            newStatus = "attended";
            color.background = "success";
            break;
        case "CANCELLED":
        case "cancelled by patient":
        case "cancelled by unit":
            newStatus = "cancelled";
            color.background = "warning";
            color.foreground = "dark";
            break;
        default: 
            newStatus = status;
            break;
    }
    return <span className={`badge bg-${color.background} badge-${color.background} text-${color.foreground} text-md mx-auto`}>{newStatus}</span>
}

const onAppointmentClick = (appointment: VisitAppointment) => {
    document.querySelectorAll("a.appointment-item").forEach((el) => {
        el.classList.remove('active');
    });

    document.querySelector(`a.appointment-item[data-visit='${appointment.systemInternalIdentifier.value}']`).classList.add('active');
}

const displayTemplate = (appointment: VisitAppointment, index: number) => {
  return (
    <Link data-visit={appointment.systemInternalIdentifier.value}
          key={index} 
          className={`appointment-item${urlParams.get('v') != '' && urlParams.get('v') === appointment.systemInternalIdentifier.value ? " active" : ""} list-group-item-action lh-xs-115 list-group-item py-0 ${appointment.status === "ADM IN" ? "list-group-item-warning" : ""}`}
          to={`${process.env.REACT_APP_URL_PATIENT_RECORD}/appointments?p=${urlParams.get('p')}&v=${appointment.systemInternalIdentifier.value}`}
          onClick={() => onAppointmentClick(appointment)}>
        <Row>
            <Col xs={3} sm={"auto"} xl={1} className="py-2" style={{minWidth:"130px"}}>
                <div className="text-md mb-0 text-center">
                    {ShortDateOnlyFormat(appointment.appointmentDateTime)}
                </div>
                <div className="font-weight-bold h4 mb-0 text-center">
                    {ShortTimeOnlyFormat(appointment.appointmentDateTime)}
                </div>
            </Col>
            <Col xs={9} sm={7} md={5} lg={6} xl={7} className="py-2">
                <span className="font-weight-bold text-lg">{appointment.type.description}</span> &nbsp;
                <div>
                {appointment.plannedDuration && <span className="text-sm">{appointment.plannedDuration} min appointment </span>}                
                {appointment.clinician?.name?.nickname && <span className="text-sm"> with {appointment.clinician.name.nickname}</span>}  
                {appointment.referralDetails?.specialty?.description && <span className="text-sm"> of {appointment.referralDetails.specialty.description}</span>}
                {appointment.location?.location?.description && <span className="text-sm"> at {appointment.location.location.description}</span>}
                {appointment.referralDetails?.reason?.description && <span className="text-sm"> for {appointment.referralDetails.reason.description}</span>}
                </div>
            </Col>
            <Col xs={6} sm={2} md={1} lg={1} xl={1} className="py-2 text-center">
               {statusToBadge(appointment.status)}
            </Col>
            <Col xs={6} md={3} lg={2} className="py-2 d-sm-none d-md-block text-right">
                <span className="text-sm text-secondary">{appointment.systemPublicIdentifier.value ? appointment.systemPublicIdentifier.value : "via SystmOne"}</span>
            </Col>
        </Row>
    </Link>
  )
}    

  return (
    <PatientRecordListDisplay _sorts={sorts} _filters={filters} _agentCall={loadData} _displayTemplate={displayTemplate} />
  )
};
export default observer(SelectedInpatientAppointmentsList);
