import { useContext } from "react";
import { Link } from "react-router-dom";
import { RootStoreContext } from "../../app/stores/rootStore";
import LargeButton from "features/common/LargeButton";
import PatientSearch from "./PatientSearch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const NavMenu = () => {
  const rootStore = useContext(RootStoreContext);
  const { user } = rootStore.userStore;

  return (
    <div className="mb-5">
      <h3 className="text-gradient text-primary mb-4">User Access Menu</h3>
      {user.roles.some(r => r.userAccess?.some((a) => a.name === "Patient Search")) && (
        <LargeButton 
            title="Patient Search" 
            isSelected={true} 
            url={process.env.REACT_APP_URL_PATIENT_SEARCH} 
            badge={<FontAwesomeIcon icon={faSearch} />}>
          <PatientSearch />
        </LargeButton>
     
      )}
      {user.roles.some((a) => a.name === "System Admin" || a.name.startsWith("Business Unit Admin")) && (
       
          <div className="card border border-secondary card-menu nav-menu nav-link rounded list-group list-group-flush p-0 mb-3 text-xl">
            <Link
              className="dropdown-item pl-4 py-3"
              to={process.env.REACT_APP_URL_ADMIN_USERS_ROOT}>
              System Administration
            </Link>
          </div>
        )}          
      {user.roles.some((a) => a.name === "System Admin") && (
          <div className="card border border-secondary card-menu nav-menu nav-link rounded list-group list-group-flush p-0 mb-3 text-xl">
            <Link className="dropdown-item pl-4 py-3" to={process.env.REACT_APP_URL_ADMIN_AUDIT_ROOT}>
              System Audit Viewer
            </Link>
          </div>        
      )}

      {user.roles.some(r => r.userAccess?.some((a) => a.name === "Carehomes")) && (
        <div className="card border border-secondary card-menu nav-menu nav-link rounded list-group list-group-flush p-0 mb-3 text-xl">
          <Link
            className="dropdown-item pl-4 py-3"
            to={process.env.REACT_APP_URL_CAREHOMES_CAPACITY}>
            Carehomes Capacity View
          </Link>
        </div>
      )}
      
    </div>
  );
};

export default NavMenu;
