import React, { useContext, useEffect } from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';
import WhatsNew from './WhatsNew';
import NavMenu from './NavMenu';
import { Row, Col, Container, Card, CardBody, Alert } from 'reactstrap';
import LoginForm from 'features/user/LoginForm';
import { useHistory } from 'react-router-dom';

const HomePage = () => {
    const rootStore = useContext(RootStoreContext);
    const { user } = rootStore.userStore;
    const urlParams = new URLSearchParams(window.location.search);
    const history = useHistory();
    
    const redirectAfterLogin = () => {        
        history.push(urlParams.get('login_redirect') !== null ? Buffer.from(urlParams.get('login_redirect'), "base64").toString("ascii") : '/')
    }

    useEffect(()=> {
        if (rootStore.userStore.isLoggedIn)
            redirectAfterLogin();
    }, [])

    return (
        <>
            {process.env.REACT_APP_IS_ANONYMOUS === "true" ? (
                <div className="text-xl alert bg-success text-white">
                    You're on the Anonymised Portal
                </div>
            ) : (
                <div className="text-xl px- py-2 text-center bg-danger text-white">
                    You're on the Live Portal 
                    &nbsp; &nbsp; &nbsp;    
                    <strong>All actions are audited</strong>
                    &nbsp; &nbsp; &nbsp; 
                    <em>Searching for yourself, family members or friends will lead to disciplinary action or dismissal.</em>
                </div>
            )}
            
        <Container fluid className="pt-5 px-xl-4 px-3">

<Row>
    <Col md={4}>                        
        <h3 className="text-gradient text-primary mb-4">Welcome to Rotherham Health Record</h3>
        
        <Card className="card-menu text-xl lh-lg mt-3 mb-5">
            <CardBody>
                {user && (
                <>           
                <h3 className="text-gradient text-primary mb-4">
                Hi <strong>{user.displayName}</strong>,
                </h3>         
                <p className="lh-lg mb-4">
                    Use the <span className="d-none d-md-inline">right</span><span className="d-inline d-md-none">below</span> menu
                    to navigate to the feature you require.  If you do not see the features you require, you probably do not have
                    the correct access set up on your account.
                </p>
                </>
                )}
                <h5>Training for RHR.care</h5>
                <p className="lh-lg mb-4">
                    Training is available through the IT Development Team using a demo / anonymised version of this Portal. If you
                    or your team require training, please contact the IT Development Team by email <a href="mailto:trft.sepia@nhs.net">trft.sepia@nhs.net</a>.
                </p>
                <h5>Support for Issues and Access</h5>
                <p className="lh-lg mb-4">
                    Contact the TRFT IT Service Desk if you have any issues with access or anything else on this portal by calling 
                    (01709) 427970 or by emailing <a href="mailto:trft.itsupport@nhs.net">trft.itsupport@nhs.net</a>.
                </p>
                    
            </CardBody>
        </Card>
        </Col>
        <>
            <Col md={4}> 
            {rootStore.userStore.isLoggedIn && (<NavMenu />) || (
                <>
                <h3 className="text-gradient text-primary mb-4">Login</h3>
                                
                <div className="pb-1">
                    <Alert color="warning" className="border-warning">
                        You must adhere to the Rotherham Health Record Access Control Policy before logging in or using this system.
                        <hr />
                        Click <a className="alert-link" href="https://rotherhamhealthrecord.org/wp-content/uploads/2021/06/Access-Control-Policy-RHR-V2-FINAL.pdf" target="_blank">here</a>  for the policy.
                    </Alert>
                </div>
            </>
            )}
            
            <LoginForm />
            </Col>
            <Col md={4}>
                <WhatsNew />                   
            </Col>
        </>
</Row>
</Container>
        </>
    )
}

export default HomePage