import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import DataLoadStatusBadge from "../DataLoadStatusBadge";
import DataLoadErrorAlert from "../DataLoadErrorAlert";
import { DataLoadStatus } from "app/api/agent";
import Loader from "react-loader-spinner";
import FhirLink from "../yhcr/FhirLink";
import { IFhirBundle } from "app/models/fhir";

interface IDataItemListProps<T> {
    recordsPerPage?: number;
    _agentCall: (id: string, pageOffset: Number, countPerPage: Number) => Promise<T>
    _displayTemplate: (dataItem: T, index: number) => React.ReactNode;
}

const PatientRecordYhcrListDisplay: React.FC<IDataItemListProps<any>> = ({_agentCall, _displayTemplate, recordsPerPage = 20}) => {
    const urlParams = new URLSearchParams(window.location.search);
    const [data, setData] = useState<IFhirBundle>();
    const [currentPage, setCurrentPage] = useState<Number>(Number(urlParams.get("yhcrPageOffset")) || 1);
    const countPerPage = Number(window.localStorage.getItem("yhcrDisplayCount")) || 10;
    const [error, setError] = useState("");
    const [status, setStatus] = useState(DataLoadStatus.NotAttempted);

    useEffect(() => {
        loadData();
    }, [,currentPage]);  

    const linkClick = (label: string) => {
        setCurrentPage(Number(label));
    }

    const loadData = () => {
        if (status !== DataLoadStatus.Loading) {
          setStatus(DataLoadStatus.Loading);
          _agentCall(data?.id?.value || "", currentPage, countPerPage)
            .then((response: IFhirBundle) => {
                setData(response);        
                setStatus(DataLoadStatus.Successful);
            })
            .catch((ex) => {
                setStatus(DataLoadStatus.Failed);
                setError(ex);  
            });
        }
    }

    const includedOrganisations = () => data && data.entry?.length ? [...new Set(data.entry.filter(e => e.search.mode?.value === "match").map(e => e.resource.meta.tag.filter(t => t.system?.value === "https://yhcr.nhs.uk/Source")[0].display?.value))] : []
    const failedOrganisations = () => data && data.entry?.length ? [...new Set(data.entry.filter(e => e.search.mode?.value === "outcome").map(e => e.resource.meta ? e.resource.meta.tag.filter(t => t.system?.value === "https://yhcr.nhs.uk/Source")[0]?.display?.value : undefined))] : []

    return (       
        <> 
        {status === DataLoadStatus.Successful ?
        <>
            <DataLoadStatusBadge status={status} /> 
            <DataLoadErrorAlert error={error} />    
            <div className={`text-sm text-muted pt-2 pb-1 border-top ${failedOrganisations().length === 0 && " border-bottom pb-2"}`}>
                <span className="d-inline-block" style={{width: "150px"}}>Showing data from </span>
                {includedOrganisations().length > 0 ?
                    includedOrganisations().map((org, index) => 
                    <span key={index} className="bg-light px-2 py-1 border border-success font-weight-bold mr-2">{org}</span>
                 ) : <>no organisations</>
                }
            </div>
                {failedOrganisations().length > 0 && (
                <div className={`text-sm text-danger py-2 border-bottom ${includedOrganisations().length === 0 && " border-top"}`}>
                <span className="d-inline-block" style={{width: "150px"}}>Data failed from </span>
                        {failedOrganisations().filter(o => o).map((org, index) => 
                        <span key={index} className="bg-light px-2 py-1 border border-danger font-weight-bold mr-2">{org}</span>
                    )}
                </div>
                )}
            <FhirLink countPerPage={countPerPage} total={data.total?.value} currentPage={currentPage} clickEvent={linkClick} />
            {((data && data.entry) && data.entry.length > 0) ? 
            <ul className="list-group list-group-flush mb-2">
                {data.entry.map((dataItem, index) => (
                    _displayTemplate(dataItem, index)
                ))}
            </ul> : 
            <>
            <h4 className="py-3 text-secondary">No data found for this tab.</h4>
            </>}
            <FhirLink countPerPage={countPerPage} total={data.total?.value} currentPage={currentPage} clickEvent={linkClick} />
        </>
         : status === DataLoadStatus.Loading ? 
            <Loader
                type="TailSpin"
                color="#007bff"
                className="text-center"
                height={80}
                width={80}
            />
         :  <></>
        
            
        }
    </>
    )
}

export default observer(PatientRecordYhcrListDisplay);