import * as React from 'react';
  
export function ShortDateTimeFormat (date: Date) { return Intl.DateTimeFormat("en-GB", {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  hour12: false
}).format(new Date(date)) }

export function ShortDateOnlyFormat (date: Date) { return Intl.DateTimeFormat("en-GB", {
  year: "numeric",
  month: "numeric",
  day: "numeric"
}).format(new Date(date)) }

export function CompareDayMonthFormat (date: Date) { return Intl.DateTimeFormat("en-GB", {
  month: "numeric",
  day: "numeric"
}).format(new Date(date)) }

export function LongDateOnlyFormat (date: Date) { return Intl.DateTimeFormat("en-GB", {
  year: "numeric",
  month: "long",
  day: "numeric"
}).format(new Date(date)) }

export function ShortTimeOnlyFormat (date: Date) { return Intl.DateTimeFormat("en-GB", {
  hour: "numeric",
  minute: "numeric",
  hour12: false
}).format(new Date(date)) }