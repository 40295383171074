import React, { Fragment, useContext, useEffect, useState } from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';
import { Card, CardTitle, CardBody } from 'reactstrap';
import { useIsAuthenticated, useAccount, useMsal } from '@azure/msal-react';
import Loader from 'react-loader-spinner'

const NoRoles = () => {
    const isAuthenticated = useIsAuthenticated();
    const rootStore = useContext(RootStoreContext);
    const { user, loading } = rootStore.userStore;
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [name, setName] = useState("");

    useEffect(() => {
        if (account && account.name) 
            setName(account.name);
    }, [account]);

    return (
        <Fragment>
            {loading ? (
            <Loader type="TailSpin" color="#007bff" className="text-center" height={80} width={80} />
            ) : (
            <Card color="warning">
                <CardTitle className="t-bold ml-3 mt-2" >
                    {user && (
                        <span>Currently no roles assigned to user {user.username}</span>
                    )}
                    {isAuthenticated && !user && (
                        <span>Currently no roles as`signed to AD user :  {name}</span>
                    )}
                </CardTitle>
                <CardBody></CardBody>
            </Card>
            )}
        </Fragment>
    )
}

export default NoRoles