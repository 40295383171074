import React, { Fragment } from "react";
import { observer } from "mobx-react-lite";
import { Col, Badge } from "reactstrap";
import { ShortDateOnlyFormat } from "../common/DateTimeFunctions";
import { IFhirAllergy } from "app/models/fhir";

interface IFhirPatientProps {
    patient: IFhirAllergy
}

const YhcrPatient: React.FC<IFhirPatientProps> = ({ patient }) => {

    return (
        <Fragment>
            <Col className="py-2"></Col>
        </Fragment>
    )

}
export default observer(YhcrPatient)