import 'bootstrap/dist/css/bootstrap.css';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import App from './app/App';
import ScrollToTop from './app/layouts/ScrollToTop';
import { createBrowserHistory } from 'history';
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult } from '@azure/msal-browser';
import { msalConfig } from './app/azureauth/AuthConfig';

export const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
    }
});

export const history = createBrowserHistory();

ReactDOM.render(
    <Router history={history}>
        <ScrollToTop>
            <App pca={msalInstance} />
        </ScrollToTop>
    </Router>,
    document.getElementById('root')
);


