import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col, Card, Container } from 'reactstrap';
import { IRole, IOrganisation } from "../../app/models/user";

interface IProps {
    
    roles: IRole[],
    organisations: IOrganisation[]
}

const ViewOrganisation: React.FC<IProps> = ({ organisations }) => {
    const [ organisation, setOrganisation ] = useState<IOrganisation>();
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let name = params.get('u');

    useEffect(() => {
        console.log(name,organisations.filter(u => u.name === name)[0])
        setOrganisation(organisations.filter(u => u.name === name)[0])
    }, [])

    return (
        (organisation && (
        <Container fluid>
            <h2 className="text-gradient text-primary mb-4">
                Viewing Organisations - {organisation?.name}
            </h2>
            <Row>
                <Col>
                    <div className="pb-3">
                        <Link to={`/system/admin/organisations/edit/?u=${encodeURIComponent(organisation?.name)}`} className="btn btn-primary">Edit</Link> &nbsp;
                        <Link to="/system/admin/organisations" className="btn btn-link text-secondary">Back to Organisations</Link> &nbsp;
                    </div>
                    <Card className="card-menu mb-5 p-5" body>
                            Organisation Name: {organisation?.name}<br />
                            Organisation Code: {organisation?.code}<br />
                            Organisation ID: {organisation?.id}
                    </Card>
                </Col>
            </Row>
        </Container>
    )) || <>Loading...</>
    
    );
};

export default withRouter(ViewOrganisation);