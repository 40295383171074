import { observable, action, runInAction } from 'mobx'
import agent from "../api/agent"
import { createContext } from 'react';
import {  Patient, PatientValues } from '../models/inpatient';
import { ISearchSubmitValues } from '../models/search';

class SearchStore {
    @observable isPatientsLoaded = false;
    @observable isSearchPending = false;
    @observable patient: Patient;
    @observable patients: Patient[] = [];
    @action ByName = (values: ISearchSubmitValues) => {
        this.patients = [];
        this.isPatientsLoaded = false;
        this.isSearchPending = true;
        agent.Search.ByName(values)
            .then(patients => {
                runInAction(() => {
                    this.patients = patients;
                    this.isPatientsLoaded = true;
                    this.isSearchPending = false;
                    })
            })
    }

    @action Search = (values: ISearchSubmitValues) => {
        this.patients = [];
        console.log(values);
        this.isSearchPending = true;
        if (values !== null) {
            console.table(values);
            this.isPatientsLoaded = false;
            agent.Search.Advanced(values)
                .then(patients => {
                    runInAction(() => {
                        this.patients = patients;
                        this.isPatientsLoaded = true;
                        this.isSearchPending = false;
                    })
                })
        }
    }

    @action setPatient = (patient:Patient) => {
        runInAction(() => { this.patient = patient; })
    }

    @action setIsSearchPending = (isPending: boolean) => {
        runInAction(() => { this.isSearchPending = isPending; })
    }

    @action clearPatients = () => 
    runInAction (() => { 
        this.patients = [];
        this.isPatientsLoaded = false;
    });

    @action getPatient = (patientId: string, isAnonymous) => {
        let patientValues: PatientValues = { systemIdentifier: patientId, isAnonymised: isAnonymous }
        return new Promise<void>((resolve, reject) => {
            agent.Patient.Get(patientValues)
                .then(patient => {
                    runInAction(() => {
                        this.patient = patient;
                        resolve();
                    })
                })
        })
    }

    @action searchByNumber = (patientId: string, isAnonymous) => {
        let patientValues: PatientValues = { systemIdentifier: patientId, isAnonymised: isAnonymous }
        this.patients = [];
        this.isSearchPending = true;
        agent.Patient.Get(patientValues)
            .then(patient => {
                runInAction(() => {
                    this.patients.push(patient);
                    this.isPatientsLoaded = true;
                    this.isSearchPending = false;
                })
            });
    }
}

export default createContext(new SearchStore());