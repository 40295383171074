import React, { useContext } from "react";
import { ListGroup, Row, CardBody,  Col,  Card,  Container,  CardHeader,} from "reactstrap";
import { observer } from "mobx-react-lite";
import { RouteComponentProps } from "react-router";
import { IOrganisation } from "../../app/models/user";
import AdminStore from '../../app/stores/adminStore';
import { Link } from 'react-router-dom';

interface IProps {
  organisations: IOrganisation[];
  toggle;
}

const OrganisationsList: React.FC<RouteComponentProps<IProps>> = ({organisations}) => {
  const adminStore = useContext(AdminStore)  
  
  return (
    <>
      <Container fluid>
        <h2 className="text-gradient text-primary mb-4">
        Organisation Administration
        </h2>
        <Row>
            <Col xs="6">
                <Card className="card-menu mb-5">
                    <CardBody className='bg-secondary text-white py-1 text-xl'>
                        <span className='text-bold t-bold font-weight-bold'>{adminStore.adminStats?.organisation?.data.length}</span> Organisations found.
                    </CardBody>
                    <ListGroup flush>                      
                    {organisations.map((organisation: IOrganisation, index) => (    
                          <Link key={`organisationList${index}`} className="lh-xs-125 p-0 align-text-top list-group-item list-group-item-action" to={`/system/admin/organisations/view/?u=${organisation.name}`}>
                              <div className=" ps-3 pl-3 pt-1">
                                <span className="text-xl text-bold t-bold font-weight-bold">{organisation.name}</span>                                        
                              </div>                                   
                          </Link>
                        ))}
                    </ListGroup>                     
                </Card>
            </Col>                
        </Row>
      </Container>
    </>
  );
};
export default observer(OrganisationsList);