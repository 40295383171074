import React, {Fragment, useContext} from "react";
import { Link, useHistory } from 'react-router-dom';
import { observer } from "mobx-react-lite";
import { Form as FinalForm, Field } from "react-final-form";
import { RootStoreContext } from '../../app/stores/rootStore';
import { Card, CardBody, CardFooter, Label, InputGroup, InputGroupAddon } from 'reactstrap';

interface IQuickSearch {
    searchFor: string;
}

const PatientSearch: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const history = useHistory();
    const onSubmit = (values: IQuickSearch) => {        
        if (values.searchFor !== "") {
            
            let audit = {
                module: "Search",
                description: `${rootStore.userStore.user.username} used the homepage search to find: ${values.searchFor}`,
                username: rootStore.userStore.user.username,
                action: "Homepage Search",
                documentId: "",
                patientId: "",
                visitId: "",
                practiceId: (rootStore.userStore.user.practice?.name || '')
            };
            rootStore.auditStore.addEntry(audit);

            if (values.searchFor.length > 3 && 
                (values.searchFor.substring(0, 2).toUpperCase() === "RU" || values.searchFor.substring(0, 2).toUpperCase() === "RA") &&                 
                !isNaN(Number(values.searchFor.substring(2, values.searchFor.length)))) {
                    let r = values.searchFor.substring(0, 2).toUpperCase();
                    if (r === "RU") {
                        r = r + values.searchFor.substring(2, values.searchFor.length).padStart(8, "0");
                    } else if (r === "RA") {
                        r = r + values.searchFor.substring(2, values.searchFor.length).padStart(10, "0");
                    }
                console.log(`RU or RA: ${r}`);
                history.push(`${process.env.REACT_APP_URL_PATIENT_SEARCH}?number=${r}`);
            } else if (!isNaN(parseInt(values.searchFor.replaceAll(' ', '')))) {
                console.log(`NHS: ${values.searchFor}`);
                history.push(`${process.env.REACT_APP_URL_PATIENT_SEARCH}?number=${values.searchFor.replaceAll(' ', '')}`);
            } else if (values.searchFor.indexOf(' ') > -1) {
                console.log(`Name: ${values.searchFor}`);
                history.push(`${process.env.REACT_APP_URL_PATIENT_SEARCH}?name=${values.searchFor}`);
            } else {
                console.log(`Surname only: ${values.searchFor}`);
                history.push(`${process.env.REACT_APP_URL_PATIENT_SEARCH}?name=${values.searchFor}`);
            }
        }
    }
  
    return (
        <Fragment>
            <Card className="card-menu mb-5">
                <CardBody>
                    <FinalForm                        
                        onSubmit={onSubmit} 
                        render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <Label for="searchFor">ID or Name</Label>
                            <InputGroup>
                                <Field name="searchFor">
                                    {({ input, meta }) => (
                                        <>
                                            <input {...input} type="text" className="form-control" placeholder="NHS #, Hospital #, Account #, Name" />
                                            {meta.error && meta.touched}
                                        </>
                                    )}
                                </Field>
                                <InputGroupAddon addonType="append">
                                    <button className="btn btn-primary" type="submit">Search</button>
                                </InputGroupAddon>
                            </InputGroup>
                        </div>
                    </form>
                        )} />
                </CardBody>
                <CardFooter className="small">
                    Go to <Link className="card-link font-weight-bold mx-1" to="/patient/search">Patient Search</Link>
                    for more search options.
                </CardFooter>
            </Card>
        </Fragment>
    )

}
export default observer(PatientSearch)

