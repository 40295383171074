import React, {Dispatch} from "react";
import { Label, InputGroup, InputGroupAddon, Button } from 'reactstrap'
import { observer } from 'mobx-react-lite'
import { Fragment, useContext } from "react"; 
import { RootStoreContext } from "../../app/stores/rootStore";
import { Form as FinalForm, Field } from "react-final-form";
import { ISearchSubmitValues } from "../../app/models/search";

interface IProps {
    state?: ISearchSubmitValues,
    stateSetter?: Dispatch<React.SetStateAction<ISearchSubmitValues>>
}

const PatientNameForm: React.FC<IProps> = ({ state, stateSetter }) => {
    const rootStore = useContext(RootStoreContext);

    const onSubmit = (values: ISearchSubmitValues) => {
        console.table(values);
        if (values.patientNumber)
            stateSetter({ ...state, patientNumber: values.patientNumber });
        else {
            stateSetter({
                ...state,
                forename: values.forename || null,
                surname: values.surname || null,
                dateOfBirth: values.dateOfBirth ? values.dateOfBirth : null,
                addressLine1: values.addressLine1 || null,
                postCode: values.postCode || null
            });
        }

        let practice: string = "";
        if (rootStore.userStore.user.practice) {
            practice = rootStore.userStore.user.practice.name;
        }

        let auditDescription: string = "";
        let patientNumber: string = "";
        let actionName: string = "";

        if (values.patientNumber) {
            auditDescription = `${rootStore.userStore.user.username} has searched for patient ID:${values.patientNumber}`;
            patientNumber = values.patientNumber;
            actionName = "ByNumber";
        } else {
            auditDescription = `${rootStore.userStore.user.username} has searched for ${values.forename} ${values.surname}`;
            patientNumber = "";
            actionName = "ByName";
        }            

        let audit = {
            module: "Search",
            description: auditDescription,
            username: rootStore.userStore.user.username,
            action: actionName,
            documentId: "",
            patientId: patientNumber,
            visitId: "",
            practiceId: practice
        }

        rootStore.auditStore.addEntry(audit)
    }

    return (
        <Fragment>
            <FinalForm 
            onSubmit={onSubmit} 
            initialValues={state}
            render={({
                submitError,
                handleSubmit,
                form,
                submitting,
                pristine
            }) => (
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <Label for="patientNumber">NHS / Hospital #</Label>
                        <InputGroup>
                            <Field name="patientNumber" >
                                {({ input, meta }) => (
                                    <>
                                        <input {...input} type="text" className="form-control" placeholder="NHS or Hospital #" />
                                        {meta.error && meta.touched}
                                    </>
                                )}
                            </Field>
                            <InputGroupAddon addonType="append">
                                <button className="btn btn-primary" type="submit">Search</button>
                            </InputGroupAddon>
                        </InputGroup>
                    </div>
                    <hr />
                    <Label>Fill in any details and click the Search button.</Label>
                    <div className="form-group mb-1">
                        <Field  name="forename" >
                        {({ input, meta }) => (
                            <>
                            <input {...input} type="text" className="form-control" placeholder="Forename" />
                            {meta.error && meta.touched }
                            </>
                        )}
                        </Field>
                    </div>
                    <div className="form-group mb-1">
                        <Field  name="surname" >
                            {({ input, meta }) => (
                                <>
                                    <input {...input} type="text" className="form-control" placeholder="Surname" />
                                    {meta.error && meta.touched}
                                </>
                            )}
                        </Field>
                    </div>
                    <div className="form-group mb-1">
                        <Field name="dateOfBirth" >
                            {({ input, meta }) => (
                                <>
                                    <input {...input} type="text" className="form-control" placeholder="Date of birth" />
                                    {meta.error && meta.touched}
                                </>
                            )}
                        </Field>
                    </div>
                    <div className="form-group mb-1">
                        <Field name="addressLine1" >
                            {({ input, meta }) => (
                                <>
                                    <input {...input} type="text" className="form-control" placeholder="Address Line 1" />
                                    {meta.error && meta.touched}
                                </>
                            )}
                        </Field>
                    </div>
                    <div className="form-group mb-1">
                        <Field name="postCode" >
                            {({ input, meta }) => (
                                <>
                                    <input {...input} type="text" className="form-control" placeholder="Postcode" />
                                    {meta.error && meta.touched}
                                </>
                            )}
                        </Field>
                    </div>
                    <div className="form-group mt-4 mb-2">
                        <button type="submit" className="btn btn-primary btn-lg" disabled={submitting || pristine}>Search</button>
                        <Button
                            onClick={() => {
                                let vals = {
                                    forename: null,
                                    surname: null, 
                                    dateOfBirth: null,
                                    addressLine1: null,
                                    postCode: null,
                                    patientNumber: null,
                                    isAnonymised: process.env.REACT_APP_IS_ANONYMOUS === "true"
                                }
                                stateSetter(vals);
                                form.reset(vals);
                            }}
                            className="ml-2" size="lg"
                            color="secondary"
                            disabled={submitting}>
                            Reset
                        </Button>
                    </div>                                        

                </form>
            )} />

        </Fragment>
    )

}
export default observer(PatientNameForm)