import { observable, computed, action, runInAction } from 'mobx'
import {  IUserFormValues, ISetupPasswordForm, IAzureUsername, IUser} from "../models/user"
import agent from "../api/agent"
import { RootStore } from "./rootStore";
import { history } from '../..';
import { toast } from 'react-toastify';


export default class UserStore {

    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @observable user: IUser = null;
    @observable loading:boolean = false;
    @computed get isLoggedIn() { 
        return this.user !== null && this.user != undefined && this.user.token.length > 0;
    }

    @action login = async (values: IUserFormValues) => {
        try {
            const user = await agent.User.login(values);
            runInAction(() => {
                this.user = user;
                this.rootStore.commonStore.setToken(user.token);
            });
        }
        catch (error) {
            toast.error("Those details don't match our records. Check your username and password.")
            throw error;
        }
    }

    @action Azurelogin = async (values: IAzureUsername) => {
        try {
            this.loading = true;
            const user = await agent.User.azurelogin(values);           
            runInAction(() => {
                this.user = user;
                console.log(user);
                this.rootStore.commonStore.setToken(user.token);
                this.loading = false;
                
                let audit = {
                    module: "User",
                    description: `${values.azureUsername} has logged in using O365 SSO`,
                    username: values.azureUsername,
                    action: "Login SSO",
                    documentId: "",
                    patientId: "",
                    visitId: "",
                    practiceId: ""
                };
                
                this.rootStore.auditStore.addEntry(audit);
            });
        }
        catch (error) {
            toast.error("That didn't work. Check your username and password.")
            throw error;
        }
    }

    @action setuppassword = async (values: ISetupPasswordForm) => {

        try {
            const user = await agent.User.setuppassword(values);
            runInAction(() => {
                this.user = user;
                this.rootStore.commonStore.setToken(user.token);
                history.push('/');
            });
        }
        catch (error) {
            toast.info('The Passwords needs to contain an uppercase character, lowercase character, a digit, and a non-alphanumeric character. Passwords must be at least eight characters long.')
            throw error;
        }
    }
    @action register = async (values: IUserFormValues) => {
        try {
            const user = await agent.User.register(values);
            runInAction(() => {
                this.user = user;
                this.rootStore.commonStore.setToken(user.token);                
                toast.info('Registration Successful! Please check for your emails for confirmation. ')
            });
        }
        catch (error) {
            throw error;
        }
    }
    @action logout = () => {
        this.rootStore.commonStore.setToken(null);
        this.user = null;
        localStorage.removeItem("jwt");
        localStorage.removeItem("tokendatetime");
        sessionStorage.clear();
        window.location.href = `/?message=${Buffer.from("You are successfully logged out.").toString("base64")}`;
        
    }
    @action getUser = async () => {
        try {
            const user = await agent.User.current();
            runInAction(() => {
                this.user = user;
            })
        }
        catch (error) {
            localStorage.clear();
        }
    }

}