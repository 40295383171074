import React from "react";
import { observer } from "mobx-react-lite";
import { ShortDateOnlyFormat, ShortTimeOnlyFormat } from "./DateTimeFunctions";

interface IDateTimeTileProps {
    date?: Date,
    label: string
}

const DateTimeTile: React.FC<IDateTimeTileProps> = ({date, label}) => {    
        
    return (   
        <div className="border border-secondary mb-1 mx-1 shadow d-inline-block align-top lh-xs-115" style={{width: '90px'}}>
            <div className="text-xs text-center text-uppercase text-white" style={{ backgroundColor: 'rgba(0,133,255,.9)'}}>
                {label}
            </div>
            <div className="font-weight-bold text-center pt-2 px-2" style={{ backgroundColor: 'rgba(245,245,245,.9)'}}>      
                    {date ?
                        <span className="text-dark text-lg">{ShortTimeOnlyFormat(date)}</span> :
                        <span className="text-white text-lg">--:--</span>
                    }          
                <div className="pb-1">                    
                {date ?
                        <span className="text-dark text-xs">{ShortDateOnlyFormat(date)}</span> :
                        <span className="text-white text-xs">&nbsp;</span>
                    }         
                </div>
            </div>
        </div>         
    )
}

export default observer(DateTimeTile);