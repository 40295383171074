import { observable, action, runInAction, toJS } from 'mobx'
import { IInpatient, IInpatientValues, IPatientValues, ILocation, IInvestigationValues, IMedicationsValues, VisitAdmission, Patient, PatientProblem, PatientAllergy, VisitAppointment, MigResponse, MigSubmitValues, IMedication, DocumentInformation, DivisionStats, IContactValues,  DocumentRows,  Investigation, RecordInvestigation, IDTLocations, IDTStats, DocumentReference, IDTInpatient, CareHomesStats, PersonContactReference, VisitRequest, IdDescriptionPair, RhrDocument} from '../models/inpatient'
import agent from '../api/agent';
import { createContext } from 'react';
import { toast } from 'react-toastify';
import { ShortDateTimeFormat } from 'features/patientrecord/common/DateTimeFunctions';
class InpatientStore {
    @observable IsAnonymous = process.env.REACT_APP_IS_ANONYMOUS === "true";
    @observable inpatients: IInpatient[] = [];
    @observable discharges: IInpatient[] = [];
    @observable stats: DivisionStats[] = [];
    @observable inpatient: IInpatient;
    @observable patient: Patient;
    @observable locations: ILocation[] = [];
    @observable admissions: VisitAdmission[] = [];
    @observable problems: PatientProblem[] = [];
    @observable referrals: VisitRequest[] = [];
    @observable allergies: PatientAllergy[] = [];
    @observable investigations: Investigation[] = null;
    @observable recordinvestigations: RecordInvestigation = null;
    @observable CarehomesInpatients: IInpatient[] = [];
    @observable CarehomeStats: CareHomesStats = { all: null, covid:null }
    @observable CarehomesFilter: string[] = [];
    @observable DefaultCarehomesInpatients: IInpatient[] = [];
    @observable IDTInpatients: IDTInpatient[];
    @observable IDTLocations: IDTLocations[];
    @observable IDTStats: IDTStats = {all : null,accepted:null,completed:null,rejected:null,toAction:null,pdd:null,pdd1:null}
    @observable appointments: VisitAppointment[] = [];
    @observable mig: MigResponse;
    @observable documents: DocumentInformation[] = [];
    @observable medications: IMedication[] = [];
    @observable assessments: DocumentReference[] = [];
    @observable bays: string[];
    @observable contacts: PersonContactReference[] = [];
    @observable document: RhrDocument;
    @observable caseloads: IdDescriptionPair[] = [];
    @observable loadingInitial = false;
    @observable isLoaded = false;
    @observable isDischargesLoaded = false;
    @observable isContactsLoaded = false;
    @observable isImagingLoaded = false;
    @observable isReferralsLoaded = false;
    @observable isProblemsLoaded = false;
    @observable isObservationsLoaded = false;
    @observable isAllergiesLoaded = false;
    @observable isInvestigationsLoaded = false;
    @observable isMedicationsLoaded = false;
    @observable isCaseloadsLoaded = false;
    @observable isInpatientLoaded = false;
    @observable isPatientRecord = false;
    @observable isAppointmentsLoaded = 0;
    @observable isAdmissionsLoaded = false;
    @observable isMigLoaded = false;
    @observable isDocumentsLoaded = false;
    @observable IsStatsLoaded = false;
    @observable isDocumentLoaded = false;    
    @action loadInpatients = (location: string, role: string, isAnonymised: boolean) => {
        this.inpatients = [];
        this.bays = [];
        this.isLoaded = false;
        let inpatientValues: IInpatientValues = { location: location, role: role, isAnonymised: isAnonymised }
        agent.Inpatient.list(inpatientValues)
            .then(inpatients => {
                inpatients.forEach((inpatient) => {
                    runInAction(() => {
                            this.inpatients.push(inpatient);
                    })
                })
                runInAction(() => {
                    this.bays = [...new Set(this.inpatients.map(item => item.currentLocation.room.description))];
                    this.isLoaded = true;
                })

            })

    }
    @action loadBedInpatients = (location: string, role: string, isAnonymised: boolean,practice:string) => {
        this.inpatients = [];
        this.bays = [];
        this.isLoaded = false;
        let inpatientValues: IInpatientValues = { location: location, role: role, isAnonymised: isAnonymised }
        agent.Inpatient.list(inpatientValues)
            .then(inpatients => {
                inpatients.forEach((inpatient) => {
                    runInAction(() => {
                        this.inpatients.push(inpatient);
                    })
                })
                runInAction(() => {
                    if (practice !== "") {
                        this.inpatients = this.inpatients.filter(x => x.patient?.gpDetails?.registeredPracticeCode === practice)
                    }
                    this.bays = [...new Set(this.inpatients.map(item => item.currentLocation.room.description))];
                    this.isLoaded = true;
                })

            })

    }
    @action filterCarehomesInpatients = (value: string) => {
        if (value === 'All') {
            this.CarehomesInpatients = this.DefaultCarehomesInpatients;
            this.CarehomeStats.all.data = this.DefaultCarehomesInpatients;
            this.CarehomeStats.covid.data = this.DefaultCarehomesInpatients.filter(x => x.patient.flags.cov19);

        } else {
            this.CarehomesInpatients = this.DefaultCarehomesInpatients.filter(x => x.currentLocation.location.description === value);
            this.CarehomeStats.all.data = this.DefaultCarehomesInpatients.filter(x => x.currentLocation.location.description === value);
            this.CarehomeStats.covid.data = this.DefaultCarehomesInpatients.filter(x => x.currentLocation.location.description === value && x.patient.flags.cov19);

        }
    }
    @action loadCarehomesDashboard = (isAnonymised: boolean) => {
        this.isLoaded = false;
        toast.info("Currently Loading Data..");
        this.CarehomesInpatients = [];
        let inpatientValues: IInpatientValues = { location: "DAVIESCT", role: null, isAnonymised: isAnonymised }
        agent.Inpatient.list(inpatientValues)
            .then(inpatients => {
                runInAction(() => {
                    this.CarehomesInpatients = inpatients.filter(x => x.patient !== null);
                    this.DefaultCarehomesInpatients = inpatients.filter(x => x.patient !== null);
                })

                    let inpatientValues2: IInpatientValues = { location: "ATHORPE", role: null, isAnonymised: isAnonymised }
                    agent.Inpatient.list(inpatientValues2).then(inpatients2 => {
                        runInAction(() => {

                            let filteredInpatients = inpatients2.filter(x => x.patient != null);
                            this.CarehomesInpatients = this.CarehomesInpatients.concat(filteredInpatients);
                            this.DefaultCarehomesInpatients = this.DefaultCarehomesInpatients.concat(filteredInpatients);
                        })
                    }).finally(() => {
                        runInAction(() => {

                            this.CarehomeStats.all = {
                                dataItemName: 'All',
                                title: 'All Inpatients',
                                description: 'Any current inpatient that belongs to carehomes.',
                                data: this.CarehomesInpatients
                            }
                            this.CarehomeStats.covid = {
                                dataItemName: 'Covid',
                                title: 'Covid Inpatients',
                                description: 'Any current inpatient that belongs to selected carehomes with covid.',
                                data: this.CarehomesInpatients.filter(x => x.patient.flags.cov19)
                            }
                            toast.success("Successfully loaded " + inpatients.length + " inpatients.")
                            this.CarehomesFilter = [...new Set(this.CarehomesInpatients.map(item => item.currentLocation.location.description ))];

                            console.log(toJS(this.CarehomesInpatients));
                            this.isLoaded = true;
                        })
                    
                    })
            })

    }
    @action loadIDTDashboard = (isAnonymised: boolean) => {
        this.isLoaded = false;
        this.IDTInpatients = [];
        this.IDTLocations = [];
        let _referralDescription = 'IDT Single Referral Order';        
        agent.Patient.Assessments({})
            .then(assessments => {
                runInAction(() => {
                    this.assessments = assessments;
                    console.log(assessments);
                })
           
            })
        let inpatientValues: IInpatientValues = { location: null, role: null, isAnonymised: isAnonymised }
        agent.Inpatient.list(inpatientValues)
            .then(inpatients => {
              
                runInAction(() => {
                    this.IDTInpatients = inpatients.filter(pt => pt.patient.systemInternalIdentifier.value !== '' && pt.patient.referrals && pt.patient.referrals.filter(rf => rf.procedure.description === _referralDescription).length > 0);
                    for (let i = 0; i < this.IDTInpatients.length; i++) {                        
                        if (this.IDTInpatients[i].patient.systemInternalIdentifier.value !== '' && this.IDTInpatients[i].patient.referrals && this.IDTInpatients[i].patient.referrals.filter(rf => rf.type.description === _referralDescription).length > 0) {
                            this.IDTInpatients[i].idtReferral = this.IDTInpatients[i].patient.referrals.filter(rf => rf.procedure.description === _referralDescription).sort((a, b) => b.requestDateTime - a.requestDateTime)[0];
                            this.IDTInpatients[i].idtInfo = this.assessments.filter(a => a.accountLinkId === this.IDTInpatients[i].systemInternalIdentifier.value);
                            if (this.MedicallyFit(this.IDTInpatients[i].idtInfo)) {
                                this.IDTInpatients[i].notes = this.Notes(this.IDTInpatients[i].idtInfo);
                                this.IDTInpatients[i].mffd = this.Mffd(this.IDTInpatients[i].idtInfo);
                                this.IDTInpatients[i].exitStrategy = this.ExitStrategy(this.IDTInpatients[i].idtInfo);
                                this.IDTInpatients[i].pdd = this.Pdd(this.IDTInpatients[i].idtInfo);
                                this.IDTInpatients[i].dtoc = this.dtoc(this.IDTInpatients[i].idtInfo);
                            }
                        }
                    }
                    this.IDTStats.all = {
                        dataItemName: 'All',
                        title: 'All Referred Inpatients',
                        description: 'Any current inpatient that has a IDT Single Referral order submitted.',
                        data: this.IDTInpatients
                    }
                    this.IDTStats.accepted = {
                        dataItemName: 'Accepted',
                        title: 'Accepted Referred Inpatients',
                        description: 'Any current inpatient that has a IDT Single Referral order submitted and is accepted by IDT.',
                        data: this.IDTInpatients.filter(inpatient => inpatient.idtReferral.resultStatus === 'Accept')
                    }
                    this.IDTStats.rejected = {
                        dataItemName: 'Rejected',
                        title: 'Rejected Referred Inpatients',
                        description: 'Any current inpatient that has a IDT Single Referral order submitted and is rejected by IDT.',
                        data: this.IDTInpatients.filter(inpatient => inpatient.idtReferral.resultStatus === 'Reject')
                    }
                    this.IDTStats.toAction = {
                        dataItemName: 'toAction',
                        title: 'Incoming Referrals',
                        description: 'Any current inpatient that has a IDT Single Referral order submitted and is awaiting action by IDT.',
                        data: this.IDTInpatients.filter(inpatient => inpatient.idtReferral.status === 'In Process')
                    }
                    this.isLoaded = true;
                })
             

            })

    }
    @action loadRecentlyDischarged = (location: string,  isAnonymised: boolean) => {
        this.discharges = [];
        this.isDischargesLoaded = false;
      
        let inpatientValues: IInpatientValues = { location: location, isAnonymised: isAnonymised, role:null, patientId:null }
        agent.Inpatient.discharges(inpatientValues)
            .then(inpatients => {
                inpatients.forEach((inpatient) => {
                    runInAction(() => {
                        if (inpatient.patient !== null || inpatient.systemInternalIdentifier !== null) {
                            let query = inpatient.patient.queries.filter(x => x.grouping === 'Discharge Information');
                            inpatient.dischargeSummary = { nursingComments: null, additionalInformation: null, managementPlan: null, servicesArranged: null };
                            inpatient.dischargeSummary.nursingComments = query.filter(f => f.query.id === 'GEN.NDC')[0];
                            inpatient.dischargeSummary.managementPlan = query.filter(f => f.query.id === 'GEN.SMPFU3')[0];
                            inpatient.dischargeSummary.servicesArranged = query.filter(f => f.query.id === 'SD.DC1')[0];
                            inpatient.dischargeSummary.additionalInformation = query.filter(f => f.query.id === 'GEN.ADI')[0];

                            this.discharges.push(inpatient);    

                        }

                    }
                    )
                })
                runInAction(() => {
                    this.isDischargesLoaded = true;
                })

            })

    }

    @action loadlocations = () => {
        this.locations = [];
        agent.Location.list()
            .then(locations => {
                locations.forEach((location) => {
                    runInAction(() => {
                        this.locations.push(location);
                    }

                    )
                })
                runInAction(() => {

                })


            })

    }
    @action setInpatient = (inpatient: IInpatient) => {
        runInAction(() => {
            this.inpatient = inpatient;
        })
    }
    @action getInpatient = (isAnonymised = false, visitId:string,patientId:string) => {
        this.isPatientRecord = false;
        if (visitId !== null) {
            let inpatientValues: IInpatientValues = { location: '', isAnonymised: false, patientId: visitId, role: '' }
            agent.Inpatient.get(inpatientValues)
                .then(inpatient => {
                    runInAction(() => {
                        this.setInpatient(inpatient);
                        this.isPatientRecord = true;
                    })
                })
 
        }
    }
    @action setModalInpatient = (inpatient:IInpatient) => {
        this.inpatient = inpatient;
            }
    @action loadContacts = (patient :Patient , isAnonymous:boolean) => {
        this.isContactsLoaded = false;
        if (this.inpatient !== undefined) {
            this.inpatient.patient.contacts = [];
        }
        let patientValues: IContactValues = { systemIdentifier: patient.systemInternalIdentifier.value, isAnonymous: isAnonymous  }
        agent.Patient.Contacts(patientValues)
            .then(contacts => {
                runInAction(() => {
                    if (this.inpatient !== undefined) {

                        this.inpatient.patient.contacts = contacts;
                    } else {
                        this.contacts = contacts;
                    }
                    this.isContactsLoaded = true;


                })
            })

    }

    @action loadProblems = (inpatient: IInpatient) => {
        this.isProblemsLoaded = false;
        //this.inpatient.patient.problems = [];
        let patientValues: IPatientValues = { systemIdentifier: inpatient.patient.systemInternalIdentifier.value, patientIdentifier: null, visitIdentifier: null }
        agent.Patient.Problems(patientValues)
            .then(problems => {
                runInAction(() => {
                    this.inpatient.patient.problems = problems
                    this.isProblemsLoaded = true;

                    })
                })


    }
    @action loadPatientProblems = (patientId :string) => {
        this.isProblemsLoaded = false;
        this.problems = [];
        let patientValues: IPatientValues = { systemIdentifier: patientId, patientIdentifier: null, visitIdentifier: null }
        agent.Patient.Problems(patientValues)
            .then(problems => {
                runInAction(() => {
                    this.problems = problems
                    this.isProblemsLoaded = true;

                })
            })


    }
    @action loadAppointments = (inpatient: IInpatient) => {
        this.isAppointmentsLoaded = 1;
        this.inpatient.patient.appointments = [];
        let patientValues: IPatientValues = { systemIdentifier: inpatient.patient.systemInternalIdentifier.value, patientIdentifier: null, visitIdentifier: null }
        agent.Patient.Appointments(patientValues)
            .then(appointments => {
                runInAction(() => {
                    this.inpatient.patient.appointments = appointments;
                    this.isAppointmentsLoaded = 2;

                })
            })


    }
    @action loadPatientAppointments = (patientId:string) => {
        this.isAppointmentsLoaded = 1;
        this.appointments = [];
        let patientValues: IPatientValues = { systemIdentifier: patientId, patientIdentifier: patientId, visitIdentifier: null }
        agent.Patient.Appointments(patientValues)
            .then(appointments => {
                runInAction(() => {
                    this.appointments = appointments;
                    this.isAppointmentsLoaded = 2;
                })
            })

    }
    @action loadImaging = (inpatient: IInpatient) => {
        this.isImagingLoaded = false;
        this.inpatient.patient.imaging = [];

        let patientValues: IPatientValues = { systemIdentifier: null, patientIdentifier: inpatient.patient.systemInternalIdentifier.value, visitIdentifier: null }
        agent.Patient.Imaging(patientValues)
            .then(imaging => {
                runInAction(() => {

                    this.inpatient.patient.imaging = imaging;
                    this.isImagingLoaded = true;
                })
            })

        runInAction(() => {
        })

    }
    @action loadCaseloads = (patient: Patient) => {
        this.isCaseloadsLoaded = false;
        if (this.inpatient !== undefined) {
            this.inpatient.patient.caseloads = [];
        }
        let patientValues: IPatientValues = { systemIdentifier: null, patientIdentifier: patient.systemInternalIdentifier.value, visitIdentifier: null }
        agent.Patient.Caseloads(patientValues)
            .then(caseloads => {
                runInAction(() => {
                    if (this.inpatient !== undefined) {
                        this.inpatient.patient.caseloads = caseloads;
                    } else {
                        this.caseloads = caseloads;
                    }
                    this.isCaseloadsLoaded = true;

                })
            })

        runInAction(() => {
        })

    }
    @action loadPatientInvestigations = (patientId:string, startDate:Date, endDate:Date) => {
        this.isInvestigationsLoaded = false;
        this.investigations = null;
        let investigationValues: IInvestigationValues = { systemIdentifier: patientId, fromDate: startDate, toDate:endDate }
        agent.Patient.RecordInvestigations(investigationValues)
            .then(investigations => {
                runInAction(() => {
                    console.log(investigations);

                    this.recordinvestigations = investigations;
                    this.isInvestigationsLoaded = true;

                })
            })


    }
    @action loadInvestigations = (inpatient: IInpatient) => {
        this.isInvestigationsLoaded = false;
        this.inpatient.patient.investigations = null;
        let date = new Date();
        date.setMonth(date.getMonth() - 1);
        let fromDate: Date = inpatient.admissionDetails ? inpatient.admissionDetails.admissionDateTime : date;
        let investigationValues: IInvestigationValues = { systemIdentifier: inpatient.patient.systemInternalIdentifier.value, fromDate:fromDate , toDate : new Date() }
        agent.Patient.Investigations(investigationValues)
            .then(investigations => {
                runInAction(() => {
                    console.log(investigations);
                    this.inpatient.patient.investigations = investigations;
                    this.isInvestigationsLoaded = true;

                })
            })


    }
   
    @action loadReferrals= (patient: Patient) => {
        this.isReferralsLoaded = false;
        if (this.inpatient !== undefined) {
            this.inpatient.patient.referrals = [];
        }
        let patientValues: IPatientValues = { systemIdentifier: null, patientIdentifier: patient.systemInternalIdentifier.value, visitIdentifier: null }
        agent.Patient.Referrals(patientValues)
            .then(referrals => {
                runInAction(() => {
                    if (this.inpatient !== undefined) {
                        this.inpatient.patient.referrals = referrals;
                    } else {
                        this.referrals = referrals;
                    }
                    this.isReferralsLoaded = true;

                })
            })


    }
    @action loadStats = (isCarehomes :boolean) => {
        this.IsStatsLoaded = false;
        let patientValues: IInpatientValues;
        if (isCarehomes) {
            patientValues = { isAnonymised: false, patientId: null, location: "carehomes", role: null }
        } else {
            patientValues = { isAnonymised: false, patientId: null, location: null, role: null }

        }
            agent.Inpatient.stats(patientValues)
            .then(stats => {
                runInAction(() => {
                    this.stats = stats;
                    console.log(stats);
                    this.IsStatsLoaded = true;

                })
            })


    }
    @action loadObservations = (inpatient: IInpatient) => {
        this.isObservationsLoaded = false;
        this.inpatient.patient.observations = [];

        let patientValues: IPatientValues = { systemIdentifier: null, patientIdentifier:null, visitIdentifier: inpatient.systemInternalIdentifier.value }
        agent.Patient.Observations(patientValues)
            .then(observations => {
                runInAction(() => {
                    this.inpatient.patient.observations = observations;
                    this.isObservationsLoaded = true;

                })
            })

        runInAction(() => {
        })

    }
    @action loadAllergies = (systemIdentifier: string) => {
        this.isAllergiesLoaded = false;
        this.inpatient.patient.observations = [];
        let patientValues: IPatientValues = { systemIdentifier: systemIdentifier, patientIdentifier: null, visitIdentifier: null }
        agent.Patient.Allergies(patientValues)
            .then(allergies => {
                runInAction(() => {
                    this.inpatient.patient.allergies = allergies;
                    this.isAllergiesLoaded = true;

                })
            })

        runInAction(() => {
        })

    }
    @action loadPatientAllergies = (patientId) => {
        this.isAllergiesLoaded = false;
        this.allergies = [];
        let patientValues: IPatientValues = { systemIdentifier: patientId, patientIdentifier: null, visitIdentifier: null }
        agent.Patient.Allergies(patientValues)
            .then(allergies => {
                runInAction(() => {
                    this.allergies = allergies;
                    this.isAllergiesLoaded = true;

                })
            })
        }
   
  



    @action loadMedications = (inpatient: IInpatient) => {
        this.inpatient.patient.medications = [];
        this.isMedicationsLoaded = false;

        let patientValues: IMedicationsValues = { Identifier: inpatient.systemInternalIdentifier.value }
        agent.Patient.Medications(patientValues)
            .then(medications => {
                runInAction(() => {
                    this.inpatient.patient.medications = medications;
                    this.isMedicationsLoaded = true;
                })
            })

    }
    @action loadPatientMedications = (patientId: string) => {
        this.medications = [];
        this.isMedicationsLoaded = false;

        let patientValues: IMedicationsValues = { Identifier: patientId }
        agent.Patient.Medications(patientValues)
            .then(medications => {
                runInAction(() => {
                    this.medications = medications;
                    this.isMedicationsLoaded = true;
                })
            })

    }
    @action loadPatientAdmissions = (patientId :string) => {
        this.isAdmissionsLoaded = false;
        this.admissions = [];
        let patientValues: IPatientValues = { systemIdentifier: patientId, patientIdentifier:null,visitIdentifier:null }
        agent.Patient.Admissions(patientValues)
            .then(admissions => {
                runInAction(() => {
                    this.admissions = admissions;
                    this.isAdmissionsLoaded = true;

                })
            })
        runInAction(() => {
        })
    }
    @action loadPatientDocuments = (patientId: string) => {
        this.isDocumentsLoaded = false;
        this.documents = [];
        let patientValues: IPatientValues = { systemIdentifier: patientId, patientIdentifier: null, visitIdentifier: null }
        agent.Patient.Documents(patientValues)
            .then(documents => {
                runInAction(() => {
                    this.documents = documents;
                    this.isDocumentsLoaded = true;

                })
            })
        runInAction(() => {
        })
    }
    @action loadPatientDocument = (docId: string) => {
        this.isDocumentLoaded = false;
        this.document = null;
        let patientValues: IPatientValues = { systemIdentifier: docId, patientIdentifier: null, visitIdentifier: null }
        agent.Patient.Document(patientValues)
            .then(document => {
                runInAction(() => {
                    this.document = document;
                    this.isDocumentLoaded = true;

                })
            })
        runInAction(() => {
        })
    }
    @action loadMig = (values:MigSubmitValues ) => {
        this.isMigLoaded = false;

        agent.Patient.Mig(values)
            .then(mig => {
                runInAction(() => {
                    console.log(values,mig);
                    mig.content = mig.content.replace(/\\/g, "");
                    this.mig = mig;
                    this.isMigLoaded = true;
                })
            })

        runInAction(() => {
        })

    }
    getQuery(document, query) {
        if (document && document.sections) {
            let questions = document.sections.map(s => s.questions)[0];
            if (questions.length) {
                let question = questions.filter(q => q.queryType.id === query);
                if (question) {
                    return question[0];
                }
            }
        }
        return;
    }
    MedicallyFit(array) {
        return toJS(array.filter(a => a.document.id === "TOC.MEDFFD"))[0];
    }
    dtoc(array) {
        let query = this.getQuery(toJS(this.MedicallyFit(array)), 'TOC.START');
        let response = query ? query.responseValue : undefined;
        return response;
    }
    Mffd(array) {
        let query = this.getQuery(toJS(this.MedicallyFit(array)), 'TOC.STATUS');
        let value = query ? query.responseValue : '';
        return value;
    }

    StatusColour(status:string) {
        switch (status) {
            case 'Accept':
                return "rgba(0, 150, 50)";
            case 'Reject':
                return "rgba(150, 0, 50)";
            default:
                return "rgba(0, 60, 180)";
        }
    }
    Pdd(array) {
        let query = this.getQuery(toJS(this.MedicallyFit(array)), 'PDD');
        let response = query ? query.responseValue : undefined;
        return response;
    }
    ExitStrategy(array) {
        let query = this.getQuery(toJS(this.MedicallyFit(array)), 'TOC.EXIT');
        let value = query ? query.responseValue : '';
        return value;
    }
    Notes(array) {
        let query = this.getQuery(toJS(this.MedicallyFit(array)), 'TOC.NOTES');
        let value = query ? query.responseValue : '';

        if (value) {
            value = value.replace(/\[AND\]/g, '<br />\n');
            value = value.replace(/\x20MFFD/g, ' <span class="text-success t-bold">MFFD</span>');
            value = value.replace(/\x20NMFFD/g, ' <span class="text-danger t-bold">NMFFD</span>');
            value = value.replace(/^\x20?(\d+[-\/]\d+(\/\d+)?)(.+)$/gm, '<span class="text-primary align-top t-bold d-inline-block text-left mr-2 px-2 py-1 bg-light h-100" style="width:80px;">$1</span><li class="list-group-item d-inline-block text-left px-2 py-1" style="width:70%">$3</li><div class=""></div>');
        }
        return value;
    }
    getMomentDate(jsonDate) {
        return ShortDateTimeFormat(jsonDate);
    }
}

function pad(str, max) {
    str = str.toString();
    return str.length < max ? pad("0" + str, max) : str;
}
const inpatientStore = createContext(new InpatientStore());

export  default inpatientStore;




