import React, { Fragment } from "react";
import { observer } from "mobx-react-lite";
import { Col, Row } from "reactstrap";
import { ShortDateOnlyFormat } from "../common/DateTimeFunctions";
import { IFhirCondition } from "app/models/fhir";

interface IFhirConditionProps {
    condition: IFhirCondition
}

const YhcrConditions: React.FC<IFhirConditionProps> = ({ condition }) => {
    
    return (
        <Fragment>
            <Col className="py-2 lh-xs-115">       
                {condition.code.coding ? condition.code.coding?.map((t, i) => (
                    <div className={`font-weight-bold text-sm pr-2 mr-2 text-lg`} key={i}>{t.display?.value}</div>
                )) : <div className={`font-weight-bold text-sm pr-2 mr-2 text-lg`}>{condition.code?.text?.value}</div>} 
                {condition.category && condition.category.map((s, i) => (
                    <div className="text-sm text-secondary font-italic" key={i}>{s.coding[0].display?.value}</div>
                ))}             
            </Col>
            <Col xl={3} lg={4} sm={6} xs={5} className="text-center text-sm lh-xs-115 py-2">
                <Row>
                <div className="col-6"><div className="text-xs text-secondary">Asserted</div>{condition.assertedDate ? ShortDateOnlyFormat(condition.assertedDate?.value) : "-"}</div>
                <div className="col-6"><div className="text-xs text-secondary">Asserter</div>{condition.asserter?.display?.value || "-"}</div>
                </Row>
            </Col>
            <Col xs="2" className="py-2 text-center">
                <span className={`badge text-md badge-${condition.clinicalStatus?.value === "active" ? "primary" :"secondary"}`}>{condition.clinicalStatus?.value}</span>
            </Col>
        </Fragment>
    )

}
export default observer(YhcrConditions)