import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Row, Col } from "reactstrap";
import PatientRecordSummaryProblems from "./PatientRecordSummaryProblems";
import PatientRecordSummaryAllergies from "./PatientRecordSummaryAllergies";
import PatientRecordSummaryCaseloads from "./PatientRecordSummaryCaseloads";
import PatientRecordSummaryContacts from "./PatientRecordSummaryContacts";
import PatientRecordSummaryQueries from "./PatientRecordSummaryQueries";
import PatientRecordSummaryReferrals from "./PatientRecordSummaryReferrals";
import PatientRecordStore from "app/stores/patientRecordStore";
import { CompareDayMonthFormat, LongDateOnlyFormat } from "./common/DateTimeFunctions";

const PatientRecordSummary: React.FC = () => {
  const patientRecordStore = useContext(PatientRecordStore);
  const patient = patientRecordStore.patient;

  const subjectPronoun = patient.gender === "M" ? "He" : "She";
  const possessivePronoun = patient.gender === "M" ? "His" : "Her";
  const forename = patient.name.forename;
  const surname = patient.name.surname;
  const name = forename + " " + surname;
  const tense = patient.dateOfDeath === null ? "is" : "was";

  return (
    <>
    <Row>
      <Col>
        <p className="mb-4 text-sm">
          This Summary page shows the latest or "Active" Allergies, Problems, Contacts, Queries/Indicators, Community Caseloads 
          and Internal Referrals. Full details can be found on the other Patient Record tabs.</p>
      </Col>
    </Row>
    
    {patient.dateOfDeath !== null ?
    <blockquote className="ps-4 pl-4 pe-5 pr-5 py-3 mb-3 text-danger ml-2 ms-2" style={{borderLeft:"solid 6px #c72626", backgroundColor:"rgb(199 38 38 / 0.09)"}}>                    
        <strong>
            {name}
        </strong> has sadly passed away. {(patient.gender == "M" ? "He" : "She")} lived {LongDateOnlyFormat(patient.dateOfBirth)} - {LongDateOnlyFormat(patient.dateOfDeath)}.
    </blockquote> :
     CompareDayMonthFormat(patient.dateOfBirth) === CompareDayMonthFormat(new Date()) &&
      <blockquote className="ps-4 pl-4 pe-5 pr-5 py-3 mb-3 text-dark ml-2 ms-2" style={{borderLeft:"solid 6px rgb(36 211 45)", backgroundColor:"rgb(36 211 45 / 0.12)"}}>                    
          Remember to wish {forename} a <strong>Happy Birthday</strong> today!
      </blockquote>
    }
    <blockquote className="ps-4 pl-4 pe-5 pr-5 py-3 mb-5 text-dark  ml-2 ms-2" style={{borderLeft:"solid 6px rgb(36, 211, 45)", backgroundColor:"rgb(36 56 76 / 0.1)"}}>                    
     <strong>{name}</strong> {tense} a <strong>{patient.age} yr old</strong> {patient.gender === "M" ? "male" : "female"} born <strong>{LongDateOnlyFormat(patient.dateOfBirth)}</strong>.
      {patient.careHome?.description && <> {subjectPronoun} currently resides in the nursing/care home "{patient.careHome?.description}". </>}
      {patient.religion.id && !["Not Known","None"].includes(patient.religion.description) ? 
          <> {subjectPronoun} {tense} of <strong>{patient.religion?.description}</strong> religion</> : 
          patient.religion.description !== "None" ? 
          <> {possessivePronoun} religion {tense} <strong>not known</strong></> :
          <> {subjectPronoun} {tense} not religious</>} and 
          {patient.maritalStatus?.id && !["NOTKNOWN"].includes(patient.maritalStatus.id) ? 
          <> is <strong>{patient.maritalStatus?.id.toLowerCase()}</strong></> : 
          !patient.maritalStatus.id ? 
          <> {possessivePronoun.toLowerCase()} marital status {tense} <strong>not known</strong></> :
          <></>}.
        {patient.ethnicity?.description && !["Not stated"].includes(patient.ethnicity.description) ? 
          <> {subjectPronoun} {tense} of <strong>{patient.ethnicity?.description.toLowerCase()}</strong> ethnicity</> : 
          !patient.ethnicity.description ? 
          <> {possessivePronoun} ethnicity {tense} <strong>not known</strong></> :
          <> {possessivePronoun} ethnicity is <strong>not stated</strong></>} 
          {patient.language?.id && !["Not known"].includes(patient.language.id) ? 
          <>{patient.ethnicity?.description && !["Not stated"].includes(patient.ethnicity.description) ? " and" : ", but"} <strong>{patient.language?.id.toLowerCase()}</strong> {tense} {possessivePronoun.toLowerCase()} primary language</> : 
          !patient.language.id ? 
          <>{patient.ethnicity?.description && !["Not stated"].includes(patient.ethnicity.description) ? ", but" : " and"} {possessivePronoun.toLowerCase()} primary language {tense} <strong>not known</strong></> :
          <></>}.
    </blockquote>
    
    <Row>
      <Col xs={12} md={6}>
        <PatientRecordSummaryAllergies />
        <PatientRecordSummaryProblems />
        <PatientRecordSummaryContacts />
      </Col>
      <Col xs={12} md={6}>      
        <PatientRecordSummaryQueries />
        <PatientRecordSummaryCaseloads />
        <PatientRecordSummaryReferrals />
      </Col>
    </Row>
    </>
  )
}
export default observer(PatientRecordSummary);
