import axios, { AxiosResponse } from 'axios';
import { IUser, IRole, IRoleValues, ISetupPasswordForm, IAddOrganisationForm, IAddRoleValues, IUserAccess, IAzureUsername, IRemoveValues, IEditRoleValues, IEditOrganisationValues, IPractice, IUserFetch } from '../models/user';
import { IUserFormValues, IOrganisation } from '../models/user';
import { IInpatient, IInpatientValues, IPatientValues, VisitAdmission, PatientAllergy, VisitAppointment, PatientCasenoteInformation, PersonContactReference,  PatientProblem, VisitRequest, PatientObservations, ILocation, IInvestigationValues, IMedicationsValues, IMedication, Investigation, IdDescriptionPair, PatientValues, MigSubmitValues, MigResponse, DocumentInformation, DivisionStats, IContactValues, IDocumentValues, DocumentRows, RecordInvestigation, DocumentReference, DocumentQueryInformation, RhrDocument, Queries } from '../models/inpatient';
import { IOrganisationValues } from '../models/admin';
import { ICareHomeCapacity } from '../models/carehomes';
import { AddEntry, AuditValues, AuditItem } from '../models/audit';
import {  ISearchSubmitValues } from '../models/search';
import { Patient as PatientSearch } from '../models/inpatient';
import { EmergencyQueryValues, EmsAlert, SimpleDocumentReference, VisitEmergencyDepartment } from '../models/emergencydepartment';
import { VisitAppointmentWithPatient, WaitingListQueryValues } from '../models/scheduling';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URI;

export enum DataLoadStatus {
    NotAttempted,
    Loading,
    Successful,
    Failed
 }

axios.interceptors.request.use((config) => {
    const token = window.localStorage.getItem('jwt');
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
}, error => Promise.reject(error))

axios.interceptors.response.use(undefined, (error) => {
    throw error.response || error;
})

const responseBody = (response: AxiosResponse) => response.data;

const requests = {
    get: (url: string) => axios.get(url).then(responseBody),
    post: (url: string, body: {}) => axios.post(url, body, { headers: { 'content-type': 'application/json' } }).then(responseBody),
    put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
    del: (url: string) => axios.delete(url).then(responseBody),
    image: (url: string, body: {}) => axios.post(url, body, { responseType: 'arraybuffer', headers: { 'content-type': 'application/json' }  }).then(responseBody)    
}

const User = {
    current: (): Promise<IUser> => requests.get('/user/currentUser'),
    login: (user: IUserFormValues): Promise<IUser> => requests.post('/user/login', user),
    azurelogin: (user: IAzureUsername): Promise<IUser> => requests.post('/user/azurelogin', user),
    register: (user: IUserFormValues): Promise<IUser> => requests.post('/user/register', user),
    setuppassword: (user: ISetupPasswordForm): Promise<IUser> => requests.post('/user/setuppassword', user),
    resetpassword: (user: ISetupPasswordForm): Promise<IUser> => requests.post('/user/resetpassword', user)
}

const Admin = {
    Organisations: (filter : IOrganisationValues) : Promise<IOrganisation[]> => requests.post('/admin/organisations', filter ),  
    Users: (filter: IOrganisationValues): Promise<IUser[]> => requests.post('/admin/users', filter),
    User: (filter: IUserFetch): Promise<IUser> => requests.post('/admin/singleuser', filter),
    RemoveRoleFromUser: (values: IRoleValues) : Promise<IUser[]> => requests.post('/admin/removerolefromuser', values),
    AddRoleToUser: (values: IRoleValues): Promise<IUser[]> => requests.post('/admin/addroletouser', values),
    AddUser: (user: IUserFormValues): Promise<IUser> => requests.post('/user/add', user),
    EditUser: (user: IUserFormValues): Promise<string> => requests.post('/user/edit', user),
    EditRole: (role: IEditRoleValues): Promise<string> => requests.post('/admin/edit', role),
    EditOrganisation: (organisation: IEditOrganisationValues): Promise<string> => requests.post('/admin/edit', organisation),
    AddOrganisation: (organisation: IAddOrganisationForm): Promise<string> => requests.post('/admin/addorganisation', organisation),
    AddRole: (role: IAddRoleValues): Promise<string> => requests.post('/admin/addrole', role),
    Roles: (): Promise<IRole[]> => requests.get('/admin/roles'),
    Practices: (): Promise<IPractice[]> => requests.get('/admin/practices'),
    UserAccess: (): Promise<IUserAccess[]> => requests.get('/admin/useraccess'),
    remove: (user: IRemoveValues): Promise<string> => requests.post('/user/remove', user)
}

const CareHome = {
    get: (): Promise<ICareHomeCapacity[]> => requests.get('/carehomes/get')
}

const Inpatient = {
    list: (location: IInpatientValues): Promise<IInpatient[]> => requests.post('/bulkdata/inpatients', location),
    get : (values : IInpatientValues) : Promise<IInpatient> => requests.post('/bulkdata/inpatient', values),
    discharges: (values: IInpatientValues): Promise<IInpatient[]> => requests.post('/bulkdata/recentdischarges', values),
    stats: (values: IInpatientValues): Promise<DivisionStats[]> => requests.post('/bulkdata/locationstats', values)
}
const Patient = {
    Admissions: (values: IPatientValues): Promise<VisitAdmission[]> => requests.post('/patient/admissions', values),
    Attendances: (values: IPatientValues): Promise<VisitEmergencyDepartment[]> => requests.post('/patient/emergency', values),
    Assessments: (values: {}): Promise<DocumentReference[]> => requests.post('/patient/assessments', values),
    Allergies: (values: IPatientValues): Promise<PatientAllergy[]> => requests.post('/patient/allergies', values),
    Appointments: (values: IPatientValues): Promise<VisitAppointment[]> => requests.post('/patient/appointments', values),
    Casenotes: (values: IPatientValues): Promise<PatientCasenoteInformation[]> => requests.post('/patient/casenotes', values),
    Caseloads: (values: IPatientValues): Promise<IdDescriptionPair[]> => requests.post('/patient/caseloads', values),
    Contacts: (values: IContactValues): Promise<PersonContactReference[]> => requests.post('/patient/contacts', values),
    Documents: (values: IPatientValues): Promise<DocumentInformation[]> => requests.post('/patient/documents', values),
    Document: (values: IPatientValues): Promise<RhrDocument> => requests.post('/patient/document', values),
    Observations: (values: IPatientValues): Promise<PatientObservations[]> => requests.post('/patient/observations', values),
    Problems: (values: IPatientValues): Promise<PatientProblem[]> => requests.post('/patient/problems', values),
    Queries: (values: IPatientValues): Promise<Queries[]> => requests.post('/patient/queries', values),
    RecordInvestigations: (values: IInvestigationValues): Promise<RecordInvestigation> => requests.post('/patient/recordinvestigations', values),
    Investigations: (values: IInvestigationValues): Promise<Investigation> => requests.post('/patient/investigations', values),
    Referrals: (values: IPatientValues): Promise<VisitRequest[]> => requests.post('/patient/referrals', values),
    Imaging: (values: IPatientValues): Promise<VisitRequest[]> => requests.post('/patient/imaging', values),
    Medications: (values: IMedicationsValues): Promise<IMedication[]> => requests.post('/patient/medications', values),
    Get: (values: PatientValues): Promise<PatientSearch> => requests.post('/patient/get', values),
    GetNoAllergies: (values: PatientValues): Promise<PatientSearch> => requests.post('/patient/getNoAllergies', values),
    Mig: (values: MigSubmitValues): Promise<MigResponse> => requests.post('/patient/mig', values),
    MigConsent: (values: MigSubmitValues): Promise<MigResponse> => requests.post('/patient/mig/consent', values),
    CacheDocument: (values: IDocumentValues): Promise<Blob> => requests.post('/patient/ScannedDocumentsByDocumentId', values),
    PdfDocument: (values: IDocumentValues): Promise<Blob> => requests.image('/patient/DocumentGet', values),
    ConvertNhsToInternalIdentifier: (values: PatientValues) : Promise<string> => requests.post('/patient/convertNhsToInternalIdentifier', values)
}

const Location = {
    list: (): Promise<ILocation[]> => requests.get('/bulkdata/locations')
}

const Search = {
    ByName: (values: ISearchSubmitValues): Promise<PatientSearch[]> => requests.post('/patient/search', values),
    Advanced: (values: ISearchSubmitValues): Promise<PatientSearch[]> => requests.post('/patient/searchAdvanced', values)
}

const Audit = {
    AddEntry: (values: AddEntry): Promise<AddEntry> => requests.post('/audit/addentry', values),
    ByDate: (values: AuditValues): Promise<AuditItem[]> => requests.post('/audit/bydate', values),
}

const EmergencyDepartment = {
    Ems: (): Promise<EmsAlert> => requests.get('/emergencydepartment/ems'),
    Emergency: (values : EmergencyQueryValues): Promise<VisitEmergencyDepartment[]> => requests.post('/bulkdata/emergency', values),
    EmergencySimpleNotes: (): Promise<SimpleDocumentReference[]> => requests.post('/bulkdata/emergencysimplenotes', {})
}

const Scheduling = {
    WaitingList: (values: WaitingListQueryValues): Promise<VisitAppointmentWithPatient[]> => requests.post('/scheduling/waitinglist', values)
}

export default {
    User,
    Inpatient,
    Admin,
    Patient,
    Location,
    CareHome,
    Audit,
    Search,
    EmergencyDepartment,
    Scheduling
}