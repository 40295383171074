import { observable, computed, action, runInAction, toJS } from 'mobx'
import { IOrganisation, IRole,IRoleValues, IUserFormValues, IAddOrganisationForm, IAddRoleValues, IEditRoleValues, IEditOrganisationValues, IPractice } from "../models/user"
import { IUser} from '../models/user'
import agent from "../api/agent"
import { toast } from 'react-toastify';
import { createContext } from 'react';
import { IOrganisationValues, AdminStats } from '../models/admin';

class AdminStore {

    @observable defaultUsers: IUser[] = [];
    @observable organisations: IOrganisation[] = [];
    @observable practices: IPractice[] = [];
    @observable adminStats: AdminStats = {total:null}
    @observable users: IUser[] = [];
    @observable usersAll: IUser[] = [];
    @observable roles: IRole[] = [];
    @observable isRolesLoaded = false;
    @observable isPracticesLoaded = false;
    @observable isUsersLoaded = false;

    @computed get sortedUsers() {
        return  this.users.slice().sort((a,b) => `${(a.displayName || a.email)} ${(a.organisation.name || a.practice.name)}` >= `${(b.displayName || b.email)} ${(b.organisation.name || b.practice.name)}` ? 1 : -1)
    }
    @computed get sortedOrganisations() {
        return  this.organisations.slice().filter(a => a.isActive).sort((a,b) => a.name >= b.name ? 1 : -1)
    }
    @computed get sortedPractices() {
        return  this.practices.slice().filter(a => a.isActive).sort((a,b) => a.name >= b.name ? 1 : -1)
    }
    @computed get sortedRoles() {
        return  this.roles.slice().sort((a,b) => a.name >= b.name ? 1 : -1)
    }

    @action loadOrganisations = (values: IOrganisationValues) => {

        agent.Admin.Organisations(values)
            .then(organisations => {
                    runInAction(() => {
                          this.organisations = organisations;
                    })
            })
    }

    @action loadPractices = () => {

        agent.Admin.Practices()
            .then(practices => {
                runInAction(() => {
                        this.practices = practices;
                        })
                })
            
    }
    @action searchText = (text: string, user: IUser) => {
        if (text) {
            this.filterUsers(this.defaultUsers, user);
            this.users = this.users.slice().filter(x =>
                (x.username.toLowerCase() +
                (x.displayName && x.displayName.toLowerCase()) +
                (x.email && x.email.toLowerCase()) +
                (x.jobTitle && x.jobTitle.toLowerCase()) +
                (x.manager && x.manager.toLowerCase())).match(text.toLowerCase()))
        } else {
              this.filterUsers(this.defaultUsers, user);
        }
    }
    @action loadUsers = (values: IOrganisationValues, user: IUser) => {

        agent.Admin.Users(values)
            .then(users => {
                runInAction(() => {
                    this.filterUsers(users, user);
                    this.defaultUsers = users;
                    this.isUsersLoaded = true;
                })
            })
    }
    @action filterUsers = (users:IUser[], user: IUser) => {
        if (user.roles?.some(x => x.name.startsWith('Business Unit Admin'))) {
            this.users = users.filter(x => x.roles.length && x.organisation?.id === user.organisation?.id)
        } else {
            // System Admin Only
            this.users = users
        }
    }
    @action loadRoles = (organisation: string) => {
        this.roles = [];
        this.isRolesLoaded = false;
        agent.Admin.Roles()
            .then(roles => {
                roles.forEach((role) => {
                    runInAction(() => {
                        if (role.organisation !== null) {
                            if (role.organisation.name === organisation) {
                                this.roles.push(role);
                            }
                        }
                    })
                    runInAction(() => {
                        this.isRolesLoaded = true;
                    })                  
                })
            })
    }
    @action loadRoleStats = (user) => {
        let roleloop = []
        agent.Admin.Roles()
            .then(roles => {
                roles.forEach((role) => {
                    if (user.organisation.name === role.organisation?.name) {
                        roleloop.push(role);
                    } 
                })
                runInAction(() => {
                    this.roles = user.roles.some(a => a.name === 'System Admin') ? roles :  roleloop;
                })
            })
        }

     
    @action removeRole = (values: IRole, username:string) => {
        let role: IRoleValues = { roleName : values.name , userName : username}
        agent.Admin.RemoveRoleFromUser(role).then(() => {
            toast.info(`Removed user "${username}" from role "${values.name}" successfully.`)
        })
    }
    @action addUserToRole = (values: IRoleValues) => {
        agent.Admin.AddRoleToUser(values).then(() => {
            toast.info('Successfully added user to role');
        });
    }
    @action addRole = (values: IAddRoleValues) => {
        agent.Admin.AddRole(values).then(() => {
            toast.info(`Role "${values.name}" successfully created.`)
        });
    }
    @action addOrganisation = (values: IAddOrganisationForm) => {
        agent.Admin.AddOrganisation(values).then(() => {
            toast.info(`Organisation "${values.name}" successfully created.`)
        });
    }
    @action AddUser = async (values: IUserFormValues, organisation: string, practice: string ) => {
        try {
            if (!values.organisation) {
                values.organisation = organisation;
            }
            if (practice) {
                values.practice = practice;
            }
            values.azureUsername = values.email;
            values.username = values.email.split('@')[0];

            console.log(values);
            await agent.Admin.AddUser(values).then(() => {
                toast.info(`Successfully added "${values.displayName}" as a user.`);
                console.log(values);
            });
        }
        catch (error) {
            throw error;
        }
    }
    @action EditUser = async (values: IUserFormValues) => {
        // This is no longer used
        console.log(toJS(values));
        let user: IUserFormValues = {
            username: values.username,
            displayName: values.displayName,
            email: values.email,
            contactNumber: values.contactNumber,
            jobTitle: values.jobTitle,
            manager: values.manager,
            roleName: values.roleName,
            organisation: values.organisation,
            azureUsername: values.azureUsername
        }
        try {
            await agent.Admin.EditUser(user);
        }
        catch (error) {
            toast.error(error);
            throw error;
        }
    }
    @action EditRole = async (values: IEditRoleValues) => {
        console.log(toJS(values));
        let role: IEditRoleValues = {
            id: values.id,
            name: values.name,
            dataFilter: values.dataFilter,
            organisation: values.organisation
        }
        try {
            await agent.Admin.EditRole(role);
        }
        catch (error) {
            throw error;
        }
    }
    @action EditOrganisation = async (values: IEditOrganisationValues) => {
        console.log(toJS(values));
        let organisation: IEditOrganisationValues = {
            code: values.code,
            name: values.name,                
        }
        try {
            await agent.Admin.EditOrganisation(organisation);
        }
        catch (error) {
            throw error;
        }
    }
}

export default createContext(new AdminStore());