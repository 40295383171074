
/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken 
 */
export async function GraphUserDelete(accessToken, userId) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    const endpoint = `https://graph.microsoft.com/v1.0/users/${userId}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "Delete",
        headers: headers
    };

    return fetch(endpoint, options)
        .then(response => response.json())
        .then(json => { 
            console.log(json); 
        })
        .catch(error => console.log(endpoint, error));
}