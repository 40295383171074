import React, { Fragment } from "react";
import { observer } from "mobx-react-lite";
import { RhrDocumentSection } from "app/models/inpatient";
import DocumentQuestionGroup from "./DocumentQuestionGroup";

interface IQuestionProps {
    section: RhrDocumentSection,
    index: number
}

const DocumentSection: React.FC<IQuestionProps> = ({ section, index }) => {

    return (
        <Fragment key={index}>
          <h2 className="letter-h2">{section.name.description}</h2>
          {section.questionGroups.length > 0 && (
            section.questionGroups.map((grouping, index) =>      
            <DocumentQuestionGroup grouping={grouping} key={`_${index}`} sectionName={section.name.description} />
          ))}
        </Fragment>
    )
};
export default observer(DocumentSection);
