import { Card } from 'reactstrap';

const WhatsNew = () => {
    return (
        <div className="mb-5 pr-2" style={{overflowY: "scroll", maxHeight: "100vh"}}>
            <h3 className="text-gradient text-primary mb-4">RHR.care Latest News</h3>
            <Card body className="py-3 px-3 card-menu mb-3">
                <h5 className="mb-1">Single Sign On (SSO) Soon to be Mandatory</h5>
                <small>19 April 2024</small>
                <hr className="mt-1 mb-3" />
                <p className="mb-2">
                    Due to the requirement for web-facing NHS IT systems to use 
                    Multi-Factor Authentication (MFA) the introduction of Single
                    Sign On (SSO) as the only Login option will be switched on
                    by the end of May.
                </p>
            </Card>
            <Card body className="py-3 px-3 card-menu mb-3">
                <h5 className="mb-1">Yorkshire &amp; Humber Care Record (YHCR) Goes Live</h5>
                <small>18 April 2023</small>
                <hr className="mt-1 mb-3" />
                <p className="mb-2">
                    The YHCR view within RHR allows users to go further than viewing 
                    Rotherham data; users (in organisations that have signed up to the 
                    YHCR information sharing agreement) will be able to access data 
                    from Barnsley, Doncaster, Sheffield, Leeds, Hull, Mid Yorkshire and more*. 
                </p>
                <p>
                    * This is dependent on each organisations progress on implementing the YHCR.
                </p>
            </Card>
            <Card body className="py-3 px-3 card-menu mb-3">
                <h5 className="mb-1">Major Update 1.5.0</h5>
                <small>15 April 2023</small>
                <hr className="mt-1 mb-3" />
                <p className="mb-2">
                    This update includes a new Admin area, an improved Audit, simplified User Setup and Roles structure, 
                    new icons for the Patient Record, fixes for notifications and lots more. 
                </p>
            </Card>
            <Card body className="py-3 px-3 card-menu mb-3">
                <h5 className="mb-1">Launch in Patient Context</h5>
                <small>06 April 2023</small>
                <hr className="mt-1 mb-3" />
                <p className="mb-2">
                    We've added a feature known as Launch in Patient Context which allows other systems
                    that you might already use to launch RHR. The patient that you have open in your local system
                    will also be open in RHR after you've logged in (without having to search for them first).
                </p>
                <p>
                    Want to know more? Email us at <a href="mailto:trft.sepia@nhs.net">trft.sepia@nhs.net</a>.
                </p>
            </Card>
            <Card body className="py-3 px-3 card-menu mb-3">
                <h5 className="mb-1">Improvements and Fixes</h5>
                <small>30 March 2023</small>
                <hr className="mt-1 mb-3" />
                <p className="mb-2">
                    The RHR team have implemented new improvements to the way that the
                    patient search and patient record works. There's also been a number
                    of fixes for some pesky bugs. 
                </p>
                <p>
                    If you do have any suggestions or you have spotted any more bugs
                    please let us know by contacting the TRFT IT Service Desk.
                </p>
            </Card>
            <Card body className="py-3 px-3 card-menu mb-3">
                <h5 className="mb-1">Rotherham Health Record Onboarding Update</h5>
                <small>29 March 2023</small>
                <hr className="mt-1 mb-3" />
                <p className="mb-2">
                    On the 27<sup>th</sup> March Barnsley Hospital NHS Foundation Trust signed 
                    the Information Sharing Agreement (ISA) for Rotherham Health Record to enable
                    the Diabetic Eye Screening team access.
                </p>
                <p>
                    Continuing Healthcare (CHC) team were successfully onboarded last week.
                </p>
                <p>
                    RHR now has 300+ users onboarded in total across 5 organisations.
                </p>
            </Card>
            <Card body className="py-3 px-3 card-menu mb-3">
                <h5 className="mb-1">Single Sign-On (SSO) is Live</h5>
                <small>17 February 2023</small>
                <hr className="mt-1 mb-3" />
                <p className="mb-2">
                    As well as lots of little improvements and small bug fixes, we've
                    now implemented Single Sign-On (SSO) to RHR from any of our 
                    Rotherham Place partners. This means user can use the email and 
                    password combination that they use in their own organisation without
                    having to remember yet another password. 
                </p>
                <p>
                    SystmOne GP and SystmOne Community Launch-in-Patient-Context will be rolled out very
                    soon too. This means users can click the RHR button on their toolbar in SystmOne
                    and RHR will open with the same patient in context. RHR might prompt the user to login
                    if they haven't before, but the SSO Login button will mean the login can happen automatically
                    if there's a valid authorised login stored in the browser.
                </p>
            </Card>
            <Card body className="py-3 px-3 card-menu mb-3">
                <h5 className="mb-1">Patient Record Improvements</h5>
                <small>28 December 2022</small>
                <hr className="mt-1 mb-3" />
                <p className="mb-2">
                    With the new homepage and the improvements to the overall speed
                    being a hit with users, we haven't stopped there. There are lots of
                    improvements being made across the site and specifically to the 
                    Patient Record. We've...
                </p>
                <ul>
                    <li>Restructured the whole project so it's easier to make future changes</li>
                    <li>Updated our document algorithm so documents display better from MEDITECH</li>
                    <li>Improved the tabs to show as selected when in use and then improved the content on each page</li>
                    <li>Added a loader widget, implemented data paging for faster pages, super filters and easy sorts</li>
                    <li>Overall GP Data (MIG) performance is improved</li>
                </ul>
            </Card>
        </div>
    )
}

export default WhatsNew;