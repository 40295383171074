import React, { useEffect, useState } from "react";
import { PatientProblem } from "../../app/models/inpatient";
import { ListGroup, ListGroupItem, Table } from "reactstrap";
import { observer } from "mobx-react-lite";
import agent, { DataLoadStatus } from "app/api/agent";
import { ShortDateOnlyFormat, ShortDateTimeFormat } from "./common/DateTimeFunctions";
import PatientRecordSummaryDisplay from "./common/PatientRecordSummaryDisplay";

const PatientRecordSummaryProblems: React.FC = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const loadData = () => 
    agent.Patient.Problems({
      systemIdentifier: urlParams.get("p"),
      patientIdentifier: null,
      visitIdentifier: null
    })

  const displayTemplate = (data: any, status: DataLoadStatus, loader: React.ReactNode) => {

    if (status === DataLoadStatus.Loading)
      return loader;
      
    if (status === DataLoadStatus.Failed)
      return <></>;
      
    return (
    <>
      <div className="bg-white text-info py-1 pl-2 text-sm mb-3 border rounded">
        Only the <strong>latest 10</strong> Active, Acute and Chronic problems are displayed on this panel.
      </div>
    
    <Table size="sm" className="mb-0">
      <tbody>
      {data && data.length > 0 ? 
      
      data
        .filter((item: PatientProblem) => item.status === "Acute" || item.status === "Chronic" || item.status === "Active")
        .slice(0,10)
        .map((item: PatientProblem, index: number) => (
        <tr key={index} className="p-0 text-sm">
          <td className="table-primary font-weight-bold t-bold text-center text-xs text-uppercase" style={{width:"60px"}}>{item.status}</td>
          <td className="px-2">
            <div className="float-end float-right text-secondary text-right text-xs">
              {ShortDateOnlyFormat(item.addedDateTime).substring(6,10)}
            </div>
            {item.problem?.description}</td>
        </tr>
        )) : <tr className="table-warning text-sm text-secondary"><td className="px-2">No problems recorded.</td></tr>
      }
      </tbody>
    </Table>
    </>
  )}
  
  return (
    <PatientRecordSummaryDisplay _agentCall={loadData} _displayTemplate={displayTemplate} _title="Conditions / Problems" />
  )
}
export default observer(PatientRecordSummaryProblems);
