import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { CardBody, FormGroup, Label } from "reactstrap";
import { IFilter, ISort } from "./PatientRecordListDisplay";

interface IListFilterProps {
    _filters: IFilter[];
    _sorts: ISort[];
    handleFilterFunction: (element: HTMLSelectElement) => void
    handleSortFunction: (element: HTMLSelectElement) => void
}

const PatientRecordFilter: React.FC<IListFilterProps> = ({_filters, _sorts, handleFilterFunction, handleSortFunction}) => {
    const [filters, setFilters] = useState<IFilter[]>();
    const [sorts, setSorts] = useState<ISort[]>([]);

    useEffect(() => { 
        setSorts(_sorts);
        setFilters(_filters);

        _filters.forEach((filter:any, index: number) => {
            if (filter?.specialType && filter.specialType === "Date") {
                let arr = [];
                switch (filter.specialType) {
                    case "Date":
                        arr.push("Last week");
                        arr.push("Last fortnight");
                        arr.push("Last month");
                        arr.push("Last 3 months");
                        arr.push("Last 6 months");
                        arr.push("Last year");
                        break;
                    default:
                        break;
                }
                filter.values = arr;
            }
        })
    }, []);
      
    return (  
        filters || sorts ? (
            (filters && filters.length) ? (
                <CardBody className="py-3 px-4 table-secondary">
                    <h4 className="text-gradient mb-0">Filters</h4>
                {filters.map((filter:IFilter, index:number) => (
                    <FormGroup key={`${index}_${filter.label.toLowerCase()}`} className="d-inline-block px-3 py-2">
                        <Label className="col--4">{filter.label}</Label>
                        <div className="col--8">
                            <select 
                                data-access-path={filter.accessPath} 
                                className="form-control"
                                id={(`${filter.label.toLowerCase()}Filter`)} 
                                onChange={(e) => {handleFilterFunction(e.target)}}>
                                    <option value="">Select...</option>
                                {filter.values && filter.values.map((value: string, valueIndex: number) => (
                                    <option key={valueIndex * 100} value={value}>{value}</option>
                                ))}
                            </select>
                        </div>
                    </FormGroup>
                ))}
                {sorts && sorts.length ? (                                
                    <FormGroup className="d-inline-block border-left border-start px-3 py-2">
                    <Label className="font-weight-bold text-primary text-gradient">
                        Sort by
                        <select
                            className="form-control"
                            id="sortSelect"
                            onChange={(e) => {handleSortFunction(e.target)}}>
                        <option value="">Select...</option>
                        {sorts.map((sort: ISort, index: number) => (
                            <React.Fragment key={index}>
                            <option key={`${index}_desc`} data-key={`${index}_desc`} data-direction="descending" data-type={sort.type} data-label={sort.label} value={sort.accessPath}>{sort.label} {sort.type === "Date" ? " (Latest first)" : (sort.type === "String" ? " (Z-A)" : (sort.type === "Number" ? " (Highest first)" : ""))}</option>
                            <option key={`${index}_asc`} data-key={`${index}_asc`} data-direction="ascending" data-type={sort.type} data-label={sort.label} value={sort.accessPath}>{sort.label} {sort.type === "Date" ? " (Oldest first)" : (sort.type === "String" ? " (A-Z)" : (sort.type === "Number" ? " (Lowest first)" : ""))}</option>
                            </React.Fragment>
                        ))}
                        </select>
                    </Label>
                    </FormGroup>
                ) : <></>}
                    <FormGroup>
                        <div className="col--8">


                            <button type="button" className="btn btn-outline-secondary" onClick={() => {
                                (document.getElementById("sortSelect") as HTMLSelectElement).selectedIndex = 0;
                                filters.forEach((filter) => {
                                    (document.getElementById(`${filter.label.toLowerCase()}Filter`) as HTMLSelectElement).selectedIndex = 0;
                                })
                                handleFilterFunction(document.getElementById(`${filters[0].label.toLowerCase()}Filter`) as HTMLSelectElement)
                            }}>
                                Reset
                            </button>
                        </div>
                    </FormGroup>
                </CardBody>
            ) : (<span className="float-end float-right text-xs">No filters or sorting available</span>)  
        ) : (<></>)    
    ) 
}

export default observer(PatientRecordFilter);