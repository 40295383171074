import { useContext, useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import { RootStoreContext } from '../../app/stores/rootStore';
import { useMsal, useAccount } from "@azure/msal-react";
import { useHistory } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { SignInButton } from 'app/azureauth/SignInButton';

const LoginForm = () => {
    const rootStore = useContext(RootStoreContext);
    const { login, logout } = rootStore.userStore;
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [name, setName] = useState("");
    const urlParams = new URLSearchParams(window.location.search);
    const history = useHistory();

    useEffect(() => {
        if (account && account.name) {
            setName(account.username.split(" ")[0]);
            const azureLogin = {
                azureUsername: account.username,
                key: account.username
            };
            rootStore.userStore.Azurelogin(azureLogin).then(() => {   
                redirectAfterLogin();
            });
        } else {
            setName("");
        }
    }, [account]);

    const redirectAfterLogin = () => {        
        history.push(urlParams.get('login_redirect') !== null ? Buffer.from(urlParams.get('login_redirect'), "base64").toString("ascii") : '/')
    }

    const message = urlParams.get('message');

    return (
        <div className='mb-5'>   
                
        {message ? (
            <div className="alert alert-primary text-xl border-2 mb-3">
                {Buffer.from(message, "base64").toString("ascii")}
            </div>
        ) : <></>}
        
        <UnauthenticatedTemplate>
            <div className="text-sm mb-4">
                Login with your Microsoft 365 Account 
                <SignInButton />
            </div>
        </UnauthenticatedTemplate>         
        <AuthenticatedTemplate>
            <div className="text-sm mb-4">
                You are signed in with your Microsoft 365 account. <br /> 
                <Button
                    size="lg"
                    onClick={() => {logout()}}
                    color="primary">
                    Logout
                </Button>
            </div>
        </AuthenticatedTemplate> 
        <hr className="my-3 border-none border-0" />
        {/* <Card className="border text-xl shadow">
            <CardHeader className="text-white bg-secondary t-bold">
                Email Address and Password
            </CardHeader>
            <CardBody>
                <FinalForm
                    onSubmit={(values: IUserFormValues) =>
                        login(values).then(
                            (response) => {
                                let audit: AddEntry = {
                                    module: "User",
                                    description: `${rootStore.userStore.user.username} has logged in`,
                                    username: rootStore.userStore.user.username,
                                    action: "Login",
                                    documentId: "",
                                    patientId: "",
                                    visitId: "",
                                    practiceId: (rootStore.userStore.user.practice?.name || '')
                                };
                                rootStore.auditStore.addEntry(audit);
                                return response;
                            }).
                            then(() => {
                                redirectAfterLogin();
                            })
                            .catch(
                                error => ({ [FORM_ERROR]: error })
                            )}
                    render={({ handleSubmit, submitting, pristine }) => (
                        <Form onSubmit={handleSubmit}>
                            <FormGroup>
                                <Label for="RhrEmail">Email</Label>
                                <Field className="form-control" type="email" name="email" component="input" id="RhrEmail" placeholder="Enter Email"></Field>
                            </FormGroup>
                            <FormGroup>
                                <Label for="Rhrpassword">Password</Label>
                                <Field className="form-control" type="password" name="password" component="input" id="Rhrpassword" placeholder="Enter Password" />
                            </FormGroup>
                            <a href="/resetpassword" className="btn btn-outline-secondary float-right">Reset Password</a>
                            <Button type="submit" color="primary" disabled={submitting || pristine}>Login</Button>
                        </Form>
                        )} />
            </CardBody>
        </Card>  */}
        </div>
    );
};

export default LoginForm; 