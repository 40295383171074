import React, { useState, useContext, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Col, Row, Card, Container} from 'reactstrap';
import { IRole, IOrganisation, IEditRoleValues  } from '../../app/models/user';
import { Form as FinalForm, Field } from 'react-final-form'
import AdminStore from '../../app/stores/adminStore';
import { FORM_ERROR } from 'final-form';
import { RootStoreContext } from '../../app/stores/rootStore';
import { Link } from 'react-router-dom';


interface IProps {
    roles: IRole[]
    organisations: IOrganisation[]  
}

// const required = value => (value ? undefined : 'Required')
const EditRole: React.FC<IProps> = ({ roles, organisations }) => {
    const rootStore = useContext(RootStoreContext);
    const adminStore = useContext(AdminStore)
    const [role, setRole ] = useState<IRole>();
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let name = params.get('u');

    useEffect(() => {
        setRole(roles.filter(u => u.name === name)[0])
    }, [])

    // const handleRemove = ( role: string) => {
    //     let values: IRemoveValues = { email: email, role: role }
    //     agent.Admin.remove(values);
    //     toast.info("Successfully removed access");
    // }

    return (
      
        (roles && (
                <Container fluid>
                <h2 className="text-gradient text-primary mb-4">
                    Editing Role - {role?.name}
                </h2>
                    <Row>
                        <Col>
                        <div className="pb-3">
                            <Link to={`/system/admin/roles/view/?u=${role?.name}`} className="btn btn-primary">View</Link> &nbsp;
                            <Link to="/system/admin/roles" className="btn btn-link text-secondary">Back to Roles</Link> &nbsp;
                        </div>                            
                        <Card body className='mb-5 card-menu'>
                        <FinalForm

                        onSubmit={(values: IEditRoleValues) => {
                                    return adminStore.EditRole(values).catch(
                                        error => ({
                                            [FORM_ERROR]: error
                                        })
                                    )
                                }}
                                initialValues={role}                                                     
                            render={({ handleSubmit,  values }) => (

                                <Form onSubmit={handleSubmit}>
                                   <Field
                                            name="name" render={({ input, meta }) => (
                                                <FormGroup row>
                                                    <Label className="text-primary" for="name" size='lg' md={3}>Role Name</Label>
                                                    <Col md={9}>
                                                        <input {...input} className="form-control" type="text" name="name" id="name" placeholder="name" />
                                                        {meta.touched && meta.error && <Label className="t-bold text-danger">{meta.error}</Label>}
                                                    </Col>
                                                </FormGroup>)} />

                                                <Field
                                            name="organisation.name" render={({ input, meta }) => (
                                                <FormGroup row>
                                                    <Label className="text-primary" for="organisation" size='lg' md={3}>Organisation</Label>
                                                    <Col md={9}>
                                                        <input {...input} className="form-control" type="text" name="organisation" id="organisation" placeholder="organisation" />
                                                        {meta.touched && meta.error && <Label className="t-bold text-danger">{meta.error}</Label>}
                                                    </Col>
                                                </FormGroup>)} /> 
                            <Button type="submit" color="primary">Submit</Button>

                            <pre>{JSON.stringify(values, ["name", "organisation"], 2)}</pre>

                                </Form>
                            )}
                        />
                                    
                        </Card>
                        </Col>
                    </Row>
                </Container>
            )) || <>Loading...</>  
      
           
    );
   
}

export default EditRole;