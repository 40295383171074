import React, { MouseEventHandler } from 'react';
import {Link} from "react-router-dom";

interface ISmallButtonProps {
    title: String,
    url: String,
    onClick?: MouseEventHandler
}

const SmallButton: React.FC<ISmallButtonProps> = (props: ISmallButtonProps) => {

    return (
        <div className="card border border-secondary card-menu nav-menu nav-link rounded list-group list-group-flush p-0 mb-2 text-lg" onClick={props.onClick}>
            <Link className="dropdown-item  pl-4 py-1"  to={props.url ? props.url : '#'}>
                {props.title}
            </Link>
        </div>
    )

}

export default SmallButton