import { observable, action, runInAction } from 'mobx'
import agent from "../api/agent"

import { createContext } from 'react';
import { ICareHomeCapacity } from '../models/carehomes';

class CareHomeStore {

    @observable carehomes: ICareHomeCapacity[] = [];
    @observable isCareHomesLoaded = false;
   

    @action loadCapacity = () => {
        this.carehomes = [];
        this.isCareHomesLoaded = false;
        agent.CareHome.get()
            .then(carehomes => {
                runInAction(() => {
                        this.carehomes = carehomes;
                        this.isCareHomesLoaded = true;
                    })
            })
    }

}
export default createContext(new CareHomeStore());