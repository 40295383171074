import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import DataLoadStatusBadge from "../DataLoadStatusBadge";
import DataLoadErrorAlert from "../DataLoadErrorAlert";
import { DataLoadStatus } from "app/api/agent";
import PatientRecordListFilter from "./PatientRecordListFilter";
import PatientRecordListCounts from "./PatientRecordListCounts";
import Loader from "react-loader-spinner";
import PatientRecordListPaging from "./PatientRecordListPaging";

export interface IFilter {
    label: string;
    accessPath: string;
    values?: string[];
}

export interface ISpecialFilter extends IFilter {
    specialType: string;
}

export interface ISort {
    label: string;
    accessPath: string;
    type: string;
}

interface IDataItemListProps<T> {
    _filters: any[];
    _sorts: ISort[];
    recordsPerPage?: number;
    _agentCall: () => Promise<T>
    _displayTemplate: (dataItem: T, index: number) => React.ReactNode;
}

const PatientRecordListDisplay: React.FC<IDataItemListProps<any>> = ({_filters, _sorts, _agentCall, _displayTemplate, recordsPerPage = 20}) => {
    const [data, setData] = useState<unknown[]>([]);
    const [filteredData, setFilteredData] = useState<unknown[]>([]);
    const [filteredDataCount, setFilteredDataCount] = useState<number>(-1);
    const [pagingSlice, setPagingSlice] = useState<unknown[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [error, setError] = useState("");
    const [status, setStatus] = useState(DataLoadStatus.NotAttempted);

    useEffect(() => {
        loadData();
    }, []);  

    const loadData = () => {
        if (status !== DataLoadStatus.Loading) {
          setStatus(DataLoadStatus.Loading);
          _agentCall()
            .then((response) => {
                setData(response);        
                setStatus(DataLoadStatus.Successful);
            })
            .catch((ex) => {
                setStatus(DataLoadStatus.Failed);
                setError(ex);
            });
        }
    }

    useEffect(() => { 
        if (data.length) {
            setFilteredData(data);
            _filters.forEach((f:any, i) => {
                if (f?.specialType) {
                    if (f.specialType === "Clinician") {
                        f.values = [...new Set(data.map(d => resolvePath(d, `${f.accessPath}.forename`, "") + " " + resolvePath(d, `${f.accessPath}.surname`, "")))];
                    }
                }
                else 
                    f.values = [...new Set(data.filter(d => resolvePath(d, f.accessPath, "") !== "").map(d => resolvePath(d, f.accessPath, "")))];
                
                if (f.values && f.values.length)
                    f.values.sort()
            })
        }
    }, [data]);
    
    useEffect(() => { 
        if (data.length && filteredData) {
            let arr = filteredData.slice((currentPage - 1) * recordsPerPage, currentPage * recordsPerPage);
            setFilteredDataCount(filteredData.length);
            setPagingSlice(arr);
        } else {
            setFilteredDataCount(0);
        }
    }, [currentPage,filteredData]);
    
    const resolvePath = (object, path, defaultValue) => path
        .split('.')
        .reduce((o, p) => o ? o[p] : defaultValue, object)
  
    const handleFilterFunction = (e: HTMLSelectElement) => {
        let d = data;
    
        _filters.forEach((filter:IFilter | ISpecialFilter, idx:number) => {
            const element = document.getElementById(`${filter.label.toLowerCase()}Filter`) as HTMLSelectElement;
            const value = element.selectedOptions[0].value;
            const path = element.dataset.accessPath;
            if (value) {
                let f = filter as ISpecialFilter;
                if (f.specialType) {
                    switch (f.specialType) {
                        case "Date":                            
                            d = handleDateFilterFunction(d, element, value, path);
                            break;
                        case "Clinician":                            
                            d = handleClinicianFilterFunction(d, element, value, path);
                            break;
                        default:
                            break;
                    }
                } else {                    
                    d = d.filter(dataItem => resolvePath(dataItem, path, "") === value)
                }
            }
        })    
        setCurrentPage(1);
        setFilteredDataCount(d.length);
        setFilteredData(d);
    }

    const handleClinicianFilterFunction = (d: any[], e: HTMLSelectElement, value: string, path: string) : any[] => {        
        return d.filter(dataItem => resolvePath(dataItem, `${path}.forename`, "") + " " + resolvePath(dataItem, `${path}.surname`, "") === value);
    }

    const handleDateFilterFunction = (d: any[], e: HTMLSelectElement, value: string, path: string) : any[] => {
        let dt = new Date();

        switch (value) {
            case "Last week":
                dt.setDate(dt.getDate() - 7);
                break;
            case "Last fortnight":
                dt.setDate(dt.getDate() - 14);
                break;
            case "Last month":
                dt.setDate(dt.getDate() - 30);
                break;
            case "Last 3 months":
                dt.setDate(dt.getDate() - 91);
                break;
            case "Last 6 months":
                dt.setDate(dt.getDate() - 181);
                break;
            case "Last year":
                dt.setFullYear(dt.getFullYear() - 1);
                break;
            default:
                break;
        }
        return d.filter(dataItem => dt <= new Date(resolvePath(dataItem, path, "")));
    }

    const handlePagingClick = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    }

    const handleSortChange = (e: HTMLSelectElement) => {
        const element = document.getElementById("sortSelect") as HTMLSelectElement;
        const path = element.selectedOptions[0].value;
        const label = element.selectedOptions[0].dataset.label;
        const type = element.selectedOptions[0].dataset.type;
        const direction = element.selectedOptions[0].dataset.direction;

        filteredData.sort((a,b) => {
            let aValue = resolvePath(a, path, "");
            let bValue = resolvePath(b, path, "");
            
            if (direction === "ascending") {
                if (type === "Date") {
                    return new Date(String(aValue)).getTime() - new Date(String(bValue)).getTime();
                } else if (type === "Number") {
                    return Number(aValue) - Number(bValue);
                } else {
                    return String(aValue).localeCompare(String(bValue));
                }
            } else {
                if (type === "Date") {
                    return new Date(String(bValue)).getTime() - new Date(String(aValue)).getTime();
                } else if (type === "Number") {
                    return Number(bValue) - Number(aValue);
                } else {
                    return String(bValue).localeCompare(String(aValue));
                }
            }
        });

        setFilteredDataCount(filteredData.length);
        setPagingSlice(filteredData.slice((currentPage - 1) * recordsPerPage, currentPage * recordsPerPage));
    }

    return (        
    <>
        <DataLoadStatusBadge status={status} />
        <DataLoadErrorAlert error={error} />    
        {data && pagingSlice && filteredDataCount > -1 && status === DataLoadStatus.Successful ? (
        <>
            <PatientRecordListFilter _sorts={_sorts} _filters={_filters} handleFilterFunction={handleFilterFunction} handleSortFunction={handleSortChange} /> 
            <PatientRecordListCounts total={data.length} filtered={filteredData.length} recordsPerPage={recordsPerPage} currentPage={currentPage} />
            <PatientRecordListPaging records={filteredDataCount} perPage={recordsPerPage} currentPage={currentPage} handlePagingClick={handlePagingClick} />
            <ul className="list-group list-group-flush">
            {pagingSlice.length > 0 && pagingSlice.map((dataItem, index) => (
                _displayTemplate(dataItem, index)
            ))}
            </ul>
            <PatientRecordListPaging records={filteredDataCount} perPage={recordsPerPage} currentPage={currentPage} handlePagingClick={handlePagingClick} />
        </>
        ) : status === DataLoadStatus.Loading ? 
            <Loader
                type="TailSpin"
                color="#007bff"
                className="text-center"
                height={80}
                width={80}
            />
         :  <></>
        
            
        }
    </>
    )
}

export default observer(PatientRecordListDisplay);