import React from "react";
import { VisitAdmission } from '../../app/models/inpatient';
import { Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom';
import { observer } from "mobx-react-lite";
import agent from "app/api/agent";
import PatientRecordListDisplay, { IFilter, ISpecialFilter, ISort } from "./common/PatientRecordListDisplay";
import { ShortDateOnlyFormat } from "./common/DateTimeFunctions";

const SelectedInpatientAdmissionsList: React.FC = () => {
  const urlParams = new URLSearchParams(window.location.search);
  
    const loadData = () => {
        return agent.Patient.Admissions({
          systemIdentifier: urlParams.get("p"),
          patientIdentifier: null,
          visitIdentifier: null,
        })
    };

    const statusToVerb = (status: string) => {
        let newStatus = "";
        switch (status) {
            case "ADM IN":
                newStatus = "Current inpatient";
                break;
            case "PRE IN":
                newStatus = "Pre-admitted";
                break;
            case "DIS IN":
                newStatus = "Discharged";
                break;
            case "SCH IN":
                newStatus = "Scheduled";
                break;
            case "PRE REF":
            case "REG REF":
            case "DEP REF":
                newStatus = "Referral";
                break;
            default: 
                break;
        }
        return newStatus;
    }

    const statusToBadge = (status: string) => {
        let newStatus = "";
        let color = {
            foreground: "white",
            background: "secondary"
        }
        switch (status) {
            case "ADM IN":
                newStatus = "in-progress";
                color.background = "danger";
                break;
            case "PRE IN":
                newStatus = "pre-adm";
                color.background = "info";
                break;
            case "DIS IN":
                newStatus = "finished";
                color.background = "success";
                break;
            case "SCH IN":
                newStatus = "booked";
                color.background = "primary";
                break;
            case "PRE REF":
            case "REG REF":
            case "DEP REF":
                newStatus = "referral";
                color.background = "warning";
                color.foreground = "dark";
                break;
            default: 
                break;
        }
        return <span className={`badge bg-${color.background} badge-${color.background} text-${color.foreground} text-md float-right float-end`}>{newStatus}</span>
    }

    const onAdmissionClick = (admission: VisitAdmission) => {
        document.querySelectorAll("a.admission-item").forEach((el) => {
            el.classList.remove('active');
        });

        document.querySelector(`a.admission-item[data-visit='${admission.systemInternalIdentifier.value}']`).classList.add('active');
    }

    const displayTemplate = (admission: VisitAdmission, index: number) => {
      return (
        <Link data-visit={admission.systemInternalIdentifier.value} className={`admission-item list-group-item-action${urlParams.get('v') === admission.systemInternalIdentifier.value ? " active" : ""} list-group-item py-0 ${admission.status.id === "ADM IN" ? "list-group-item-warning" : ""}`} key={index} to={`${process.env.REACT_APP_URL_PATIENT_RECORD}/admissions?p=${urlParams.get('p')}&v=${admission.systemInternalIdentifier.value}`} onClick={() => onAdmissionClick(admission)}>
            <Row>
                <Col xs={8} sm={7} md={6} lg={7} className="py-1">
                    <span className="font-weight-bold text-lg">{admission.admissionDetails?.specialty?.description} {statusToVerb(admission.status.id)} account</span> 
                    <div>
                    {admission.managementIntent?.description && <span className="text-sm">{admission.managementIntent.description}</span>}
                    {admission.admissionDetails?.method?.description && <span> {admission.admissionDetails.method.description} </span>}
                    {admission.admissionDetails?.priority?.description} admission
                    {admission.admissionDetails?.reason && <span>&nbsp;for &ldquo;{admission.admissionDetails?.reason}&rdquo;</span>}                    
                    {admission.admissionDetails?.attendingClinician?.name?.surname && (
                        <>                        
                            &nbsp;with {admission.admissionDetails?.attendingClinician?.name?.forename}&nbsp;
                            {admission.admissionDetails?.attendingClinician?.name?.surname} in&nbsp;
                            {admission.admissionDetails?.attendingClinician?.primarySpecialty?.description}
                        </>
                    )}
                    </div>
                    {admission.status.id === "DIS IN" && (
                        <div className="pt-1 text-sm text-success">
                            Discharged                             
                            {admission.dischargeDetails?.destination?.description && <span> to {admission.dischargeDetails.destination.description} </span>}
                            {admission.dischargeDetails?.reason?.description && <span> {admission.dischargeDetails.reason.description} </span>}
                            {admission.dischargeDetails?.dischargeDateTime && <span> on {ShortDateOnlyFormat(admission.dischargeDetails.dischargeDateTime)} </span>}
                        </div>
                    )}
                </Col>
                <Col xs={4} sm={2} lg={2} className="py-1">
                        {ShortDateOnlyFormat(admission.admissionDetails.admissionDateTime)}
                </Col>
                <Col xs={12} sm={3} md={4} lg={3} className="py-1">
                    <span className="text-sm text-secondary">{admission.systemPublicIdentifier.value}</span>
                    {statusToBadge(admission.status.id)}
                </Col>
            </Row>
        </Link>
      )
    }    

    const filters: IFilter[] = [
        { label: "Attending", accessPath: "admissionDetails.attendingClinician.name", specialType: "Clinician" } as ISpecialFilter,
        { label: "Specialty", accessPath: "admissionDetails.specialty.description" },
        { label: "Intent", accessPath: "managementIntent.description" },
        { label: "Date", accessPath: "admissionDetails.admissionDateTime", specialType: "Date" } as ISpecialFilter
    ];

    const sorts: ISort[] = [
        { label: "Date", accessPath: "admissionDetails.admissionDateTime", type: "Date" }
      ]

    return (
      <PatientRecordListDisplay _filters={filters} _sorts={sorts} _agentCall={loadData} _displayTemplate={displayTemplate} />
    )

}
export default observer(SelectedInpatientAdmissionsList)
