import React, { useState, useContext, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Collapse, Navbar, NavbarToggler, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { RootStoreContext } from '../../app/stores/rootStore';
import RHRLogo from '../../app/images/rhr-logo-sm.png';

const MainNavbar = () => {
    const rootStore = useContext(RootStoreContext);
    const { isLoggedIn, user, logout,  } = rootStore.userStore;
    const [isOpen, setIsOpen] = useState(false);    
    const toggle = () => setIsOpen(!isOpen);
    
    return (
        <Navbar color="dark" dark expand="md">
            <Link className="navbar-brand" to="/"><img src={RHRLogo} style={{ height:'40px'}}/></Link>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
                {user && (
                <Nav className="mr-auto" navbar>
                    <NavItem>
                        <Link className='nav-link text-white' to="/">Home</Link>
                    </NavItem>
                    {user.roles.length > 0 && (
                    <Fragment>
                        {user.roles.some(a => a.name === 'System Admin') && (
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav caret>Admin</DropdownToggle>
                            <DropdownMenu>
                                <Link to={process.env.REACT_APP_URL_ADMIN_USERS_ROOT} className="dropdown-item">System Administration</Link>
                                <Link to={process.env.REACT_APP_URL_ADMIN_AUDIT_ROOT} className="dropdown-item">System Audit</Link>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        )}
                        
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav caret>Clinical</DropdownToggle>
                            <DropdownMenu>
                            {user.roles[0].userAccess?.some(a => a.name === 'Patient Search') && (
                                <Link to={process.env.REACT_APP_URL_PATIENT_SEARCH} className="dropdown-item">Patient Search</Link>
                            )}
                            {user.roles[0].userAccess?.some(a => a.name === 'Carehomes') && (
                                <Link to={process.env.REACT_APP_URL_CAREHOMES_CAPACITY} className="dropdown-item">Care Homes Capacity View</Link>
                            )}
                             {user.roles[0].userAccess?.some(a => a.name === 'Patient Record') && (
                                <Link to={process.env.REACT_APP_URL_PATIENT_PRIORITY} className="dropdown-item">Priority Dashboard</Link>
                            )}    
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </Fragment>
                    )}
                </Nav>
                )}
                {!isLoggedIn &&
                <Nav className="mr-auto" navbar></Nav>
                }
                <Nav>                      
                {isLoggedIn &&            
                    <NavItem><NavLink className='text-white' href='#'  onClick={logout}>Logout</NavLink></NavItem>
                }
                {!isLoggedIn &&
                    <NavItem><Link className='nav-link text-white' to="/">Login</Link></NavItem>
                }
                </Nav>                    
            </Collapse>
        </Navbar>
    );
}

export default MainNavbar;