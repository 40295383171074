import { observable, action, runInAction } from 'mobx'
import agent from "../api/agent"
import { createContext } from 'react';
import { Patient, PatientAllergy } from '../models/inpatient';

class PatientRecordStore {
    @observable IsAnonymous = process.env.REACT_APP_IS_ANONYMOUS === "true";
    @observable isPatientLoaded = false;
    @observable isAllergiesLoaded = false;
    @observable patient: Patient = null;
    @observable allergies: PatientAllergy[] = null;

    @action getPatient = (patientId: string) => {  
        this.isPatientLoaded = false;
        this.patient = null;
        this.allergies = null;
      
        agent.Patient.Get({ systemIdentifier: patientId, isAnonymised: this.IsAnonymous })
            .then(patient => {
                runInAction(() => {
                    this.patient = patient;
                    this.isPatientLoaded = true;
                    this.loadPatientAllergies(patientId);
                })
            })
    }

    @action loadPatientAllergies = (patientId) => {
        this.isAllergiesLoaded = false;
        this.allergies = null;

        agent.Patient.Allergies({ systemIdentifier: patientId, patientIdentifier: null, visitIdentifier: null })
            .then(allergies => {
                runInAction(() => {
                    this.allergies = allergies;
                    this.isAllergiesLoaded = true;
                })
            })
        }
}

export default createContext(new PatientRecordStore());