import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Container, Card, CardBody } from "reactstrap";
import { observer } from "mobx-react-lite";
import PatientResults from "./PatientResults";
import PatientNameForm from "./PatientNameForm";
import { ISearchSubmitValues } from "../../app/models/search";
import InpatientStore from "../../app/stores/inpatientStore";
import agent from "app/api/agent";
import { Patient } from '../../app/models/inpatient';

const PatientSearch: React.FC = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const inpatientStore = useContext(InpatientStore);
  const [results, setResults] = useState<Patient[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>();
  const [searchParams, setSearchParams] = useState<ISearchSubmitValues>({
    forename: null,
    surname: null,
    addressLine1: null,
    dateOfBirth: null,
    postCode: null,
    patientNumber: null,
    isAnonymised: inpatientStore.IsAnonymous,
  });

  useEffect(() => {
    if (urlParams.get("number")) {
      setSearchParams({
        ...searchParams, patientNumber: urlParams.get("number")
      });
    } else if (urlParams.get("name")) {
      let name = urlParams.get("name");
      if (name.indexOf(' ') > -1) {
        setSearchParams({
          ...searchParams, forename: name.split(" ")[0], surname: name.split(" ")[1]
        });
      } else {
        setSearchParams({
          ...searchParams, surname: name.split(" ")[0]
        });
      }
    }
  }, []);

  useEffect(() => {
    if (searchParams.patientNumber !== null && searchParams.patientNumber !== undefined && searchParams.patientNumber !== "") {
      setIsLoading(true)
      setResults([]);
      agent.Patient.Get({ 
           systemIdentifier: searchParams.patientNumber,
           isAnonymised: inpatientStore.IsAnonymous
        })
        .then(resp => { setResults([resp]); setError(undefined); })
        .catch(err => setError(err))
        .finally(() => setIsLoading(false));
    } else {
      if (
        searchParams.forename !== null ||
        searchParams.surname !== null ||
        searchParams.addressLine1 !== null ||
        searchParams.postCode !== null
      ) {
        setIsLoading(true);
        setResults([]);
        agent.Search.Advanced(searchParams)
        .then(resp => { setResults(resp); setError(undefined); })
        .catch(err => setError(err))
        .finally(() => setIsLoading(false));
        
      }
    }
  }, [searchParams]);

  useEffect(() => {
    
  }, [results]);

  return (
    <Container fluid className="pt-5 px-xl-5 px-3">
      <Row>
        <Col sm={12} md={12} lg="4" xl="3">
          <h3 className="text-gradient text-primary mb-4">Patient Search</h3>
          <Card className="shadow border-secondary bg-light mb-4">
            <CardBody>
              <PatientNameForm
                state={searchParams}
                stateSetter={setSearchParams}
              />
            </CardBody>
          </Card>
        </Col>
        <Col sm={12} md={12} lg="8" xl="9" className="mb-5">
          <h3 className="mb-4">&nbsp;</h3>
          <PatientResults results={results} isLoading={isLoading} loadError={error} />
        </Col>
      </Row>
    </Container>
  );
};
export default observer(PatientSearch);
