import React from "react";
import { VisitRequest } from "../../app/models/inpatient";
import { Table } from "reactstrap";
import { observer } from "mobx-react-lite";
import agent, { DataLoadStatus } from "app/api/agent";
import { ShortDateOnlyFormat } from "./common/DateTimeFunctions";
import PatientRecordSummaryDisplay from "./common/PatientRecordSummaryDisplay";

const PatientRecordSummaryReferrals: React.FC = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const loadData = () => 
    agent.Patient.Referrals({
      systemIdentifier: urlParams.get("p"),
      patientIdentifier: urlParams.get("p"),
      visitIdentifier: null
    })

  const displayTemplate = (data: any, status: DataLoadStatus, loader: React.ReactNode) => {

    if (status === DataLoadStatus.Loading)
      return loader;
      
    if (status === DataLoadStatus.Failed)
      return <></>;
      
    return (
    <Table size="sm" className="mb-0">
      <tbody>
      {data && data.length > 0 ?
        data.map((item: VisitRequest, index: number) => (
        <tr key={index} className="p-0 text-sm">
          <td className="table-primary font-weight-bold t-bold text-center text-xs text-uppercase" style={{width:"60px"}}>
            <span>{item.priority}</span>
          </td>
          <td className="px-2"><span className="text-xs text-secondary">{ShortDateOnlyFormat(item.requestDateTime)} </span> - {item.procedure.description}<br />{item.requestComment}</td>
          <td className="px-2"></td>
        </tr>
      ))
    : <tr className="table-warning text-sm text-secondary"><td className="px-2">No referrals found.</td></tr>}
    </tbody>
    </Table>
  )}
  
  return (
    <PatientRecordSummaryDisplay _agentCall={loadData} _displayTemplate={displayTemplate} _title="Internal Referrals" />
  )
}
export default observer(PatientRecordSummaryReferrals);
