import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft, faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';

interface IListPagingProps {
    records: number,
    perPage: number,
    currentPage: number,
    handlePagingClick: (pageNumber: number) => void
}

const PatientRecordPaging: React.FC<IListPagingProps> = ({records, perPage, currentPage, handlePagingClick}) => {    
    const [ pages, setPages ] = useState({
        total: Math.ceil(records / perPage),
        min: 1,
        max: 0
    });
    const [ arr, setArr ] = useState<number[]>([]);

    const createArray = () => {
        let arr = [];
        for(let i = pages.min; i <= pages.max; i++) 
            arr.push(i)
        return arr;
    }

    useEffect(() => {
        setPages({
            total: Math.ceil(records / perPage),
            min: currentPage - 4 <= 0 ? 1 : currentPage - 4,
            max: ((currentPage - 4 <= 0 ? 1 : currentPage - 4) + 8 > Math.ceil(records / perPage) ? Math.ceil(records / perPage) : (currentPage - 4 <= 0 ? 1 : currentPage - 4) + 8)
        });
    }, [records])

    useEffect(() => {
        setArr(createArray());
    }, [pages])

    useEffect(() => {
        setPages({
            total: Math.ceil(records / perPage),
            min: currentPage - 4 <= 0 ? 1 : currentPage - 4,
            max: ((currentPage - 4 <= 0 ? 1 : currentPage - 4) + 8 > pages.total ? pages.total : (currentPage - 4 <= 0 ? 1 : currentPage - 4) + 8)
        });
    }, [currentPage])

    
    return (   
        records > perPage && (
    <div className="bg-light py-2 px-3">  
        <nav>
            <ul className="pagination mb-0 text-sm font-weight-bold max-w100">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}><a className="px-2 px-md-3 page-link" onClick={() => handlePagingClick(1)}><span aria-hidden="true"><FontAwesomeIcon icon={faAngleDoubleLeft} /></span></a></li>
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}><a className="px-2 px-md-3 page-link" onClick={() => handlePagingClick(currentPage > 1 ? currentPage - 1 : 1)}><span aria-hidden="true"><FontAwesomeIcon icon={faAngleLeft} /></span></a></li>
                {pages.min > 1 && (
                    <li className={`page-item disabled`}><a className="px-2 px-md-3 page-link">...</a></li>
                )}     
                {arr.map((value, index)=> (
                    <li className={`page-item ${currentPage === value ? 'active font-weight-bold' : ''}`} key={index}><a className="px-2 px-md-3 page-link" onClick={() => handlePagingClick(value)}>{value}</a></li>
                ))}      
                {pages.total > pages.max && (
                    <li className={`page-item disabled`}><a className="px-2 px-md-3 page-link">...</a></li>
                )}          
                <li className={`page-item ${currentPage === pages.total ? 'disabled' : ''}`}><a className="px-2 px-md-3 page-link" onClick={() => handlePagingClick(currentPage < pages.total ? currentPage + 1 : pages.total)}><span aria-hidden="true"><FontAwesomeIcon icon={faAngleRight} /></span></a></li>
                <li className={`page-item ${currentPage === pages.total ? 'disabled' : ''}`}><a className="px-2 px-md-3 page-link" onClick={() => handlePagingClick(pages.total)}><span aria-hidden="true"><FontAwesomeIcon icon={faAngleDoubleRight} /></span></a></li>
            </ul>
        </nav>    
    </div>) 
    )
}

export default observer(PatientRecordPaging);