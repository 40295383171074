import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import yhcragent from "app/api/yhcragent";
import PatientRecordStore from "app/stores/patientRecordStore";
import PatientRecordYhcrListDisplay from "./common/PatientRecordYhcrListDisplay";
import { ListGroupItem, Row, Col } from "reactstrap";
import { ShortDateTimeFormat } from "./common/DateTimeFunctions";
import YhcrAllergies from "./yhcr/YhcrAllergies";
import YhcrAppointments from "./yhcr/YhcrAppointments";
import YhcrConditions from "./yhcr/YhcrConditions";
import YhcrDocuments from "./yhcr/YhcrDocuments";
import YhcrEncounters from "./yhcr/YhcrEncounters";
import YhcrMedications from "./yhcr/YhcrMedications";
import YhcrProcedures from "./yhcr/YhcrProcedures";
import { RootStoreContext } from "app/stores/rootStore";
import YhcrPatient from "./yhcr/YhcrPatient";

interface IYhcrFhirProps {
  resource: string;
}

const PatientRecordYhcrFhir: React.FC<IYhcrFhirProps> = ({resource}) => {
  const rootStore = useContext(RootStoreContext);
  const patientRecordStore = useContext(PatientRecordStore);

  const loadData = (id: string, pageNumber: number, countPerPage: number) => {    
    if (id) {
            let paging = { searchGuid: id, pageOffset: pageNumber, countPerPage: countPerPage, yhcrJwt: window.localStorage.getItem("yhcrJwt"), isAnonymous: process.env.REACT_APP_IS_ANONYMOUS === "true" };
            return yhcragent.Fhir.paging(paging);
    } else {
            return yhcragent.Fhir.get({ 
              resource: resource, 
              nhsNumber: process.env.REACT_APP_IS_ANONYMOUS === "true" ? window.localStorage.getItem("yhcrSandpitPatient") : patientRecordStore.patient?.nhsNumber?.value, 
              countPerPage: countPerPage, 
              isAnonymous: process.env.REACT_APP_IS_ANONYMOUS === "true",
              user: rootStore.userStore.user ,
              yhcrJwt: window.localStorage.getItem("yhcrJwt")
            })
          }
  } 
  
  const displayTemplate = (data, index) => {
    return (
      data.search.mode.value === "match" && 
        <ListGroupItem key={index} action className="py-0">
          <Row>
            {window.location.pathname.split('/').pop() === "AllergyIntolerance" && <YhcrAllergies allergy={data.resource} />}
            {window.location.pathname.split('/').pop() === "Appointment" && <YhcrAppointments appointment={data.resource} />}
            {window.location.pathname.split('/').pop() === "Condition" && <YhcrConditions condition={data.resource} />}
            {window.location.pathname.split('/').pop() === "DocumentReference" && <YhcrDocuments document={data.resource} />}
            {window.location.pathname.split('/').pop() === "Encounter" && <YhcrEncounters encounter={data.resource} />}
            {window.location.pathname.split('/').pop() === "MedicationStatement" && <YhcrMedications medication={data.resource} />}
            {window.location.pathname.split('/').pop() === "Procedure" && <YhcrProcedures procedure={data.resource} />}
            {window.location.pathname.split('/').pop() === "Patient" && <YhcrPatient patient={data.resource} />}

            <Col className="text-sm bg-problems py-2 lh-xs-115" style={{maxWidth: "215px"}}>
            {data.resource?.meta?.tag.filter(t => t.system?.value === "https://yhcr.nhs.uk/Source").map((tag, tIndex) => (
              <div className="mb-1" key={tIndex}><span className="font-weight-bold">{tag.display?.value}</span></div>
            ))}
            <div className="text-muted">
            Updated: {data.resource?.meta?.lastUpdated?.value && ShortDateTimeFormat(data.resource.meta?.lastUpdated?.value)}
            </div>
            </Col>
          </Row>
        </ListGroupItem>
      
    )
  }

  return (
   <PatientRecordYhcrListDisplay _agentCall={loadData} _displayTemplate={displayTemplate} />
  );
};
export default observer(PatientRecordYhcrFhir);
