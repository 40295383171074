import React, { Fragment } from "react";
import { observer } from "mobx-react-lite";
import { Col, Badge, Table, Row } from "reactstrap";
import { ShortDateOnlyFormat, ShortTimeOnlyFormat } from "../common/DateTimeFunctions";
import { IFhirMedication } from "app/models/fhir";

interface IFhirMedicationProps {
    medication: IFhirMedication
}

const YhcrEncounters: React.FC<IFhirMedicationProps> = ({ medication }) => {

    return (
        <Fragment>
            <Col className="py-2">       
                <div className={`font-weight-bold text-sm pr-2 mr-2 text-lg mb-2`}>
                    {medication.medicationReference?.display?.value || <span className="text-danger">!! Error: Medication name not supplied !!</span>}   
                    {medication.dosage?.map((dosage, dIndex) => (
                    <div key={`dose${dIndex}`} className="text-sm text-secondary font-weight-normal lh-xs-115">
                        <div>Direction: <span className="text-dark">{dosage.text?.value}</span> </div>
                        {dosage.route?.coding.map((adminRoute, aIndex) => (
                        <div key={aIndex}> Route: <span className="text-dark">{adminRoute.display?.value}</span> </div>
                        ))}                        
                        {dosage.patientInstruction?.value ? <div>Instruction: <span className="text-dark">{dosage.patientInstruction?.value}</span> </div> : <></>}
                    </div>
                ))}                
                </div>
            </Col>
            
            <Col xl={3} lg={4} sm={6} xs={5} className="text-center text-sm lh-xs-115 py-2">
                <Row>
                <div className="col-6"><div className="text-xs text-secondary">Asserted</div>{medication.dateAsserted ? ShortDateOnlyFormat(medication.dateAsserted?.value as Date) : "-"}</div>
                <div className="col-6"><div className="text-xs text-secondary">Taken</div>{medication.taken?.value || "-"}</div>
                </Row>
            </Col>
            <Col xs="2" className="py-2 text-center">
                <span className={`badge text-md badge-${medication.status?.value === "active" ? "primary" :"secondary"}`}>{medication.status?.value}</span><br />
            </Col>
        </Fragment>
    )

}
export default observer(YhcrEncounters)