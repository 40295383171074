import React, { useContext, useState } from 'react';
import { Form as FinalForm, Field } from 'react-final-form'
import { Card, Button, Form, FormGroup, Label, Container, Col, Row} from 'reactstrap';
import { IAddRoleValues, IRole, IOrganisation } from '../../app/models/user';
import AdminStore from '../../app/stores/adminStore';
import { Link } from 'react-router-dom';


interface IProps {
    roles: IRole[],
    organisations: IOrganisation[]
}

const required = value => (value ? undefined : 'Required')
const CreateNewRole:  React.FC<IProps> = ({roles, organisations}) => {
    const adminStore = useContext(AdminStore)    
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
 
    return (
        <>
<Container fluid>
                <h2 className="text-gradient text-primary mb-4">
                    Add a New Role
                </h2>
<Row>
    <Col>
            <div className="pb-3">
                <Link to="/system/admin/roles" className="btn btn-link text-secondary">Back to Roles</Link> &nbsp;
            </div>
        <Card body className='mb-5 card-menu'>
                <FinalForm                                
                        onSubmit={(values: IAddRoleValues) => adminStore.addRole(values)        
                        }
                        render={({ handleSubmit, pristine, form }) => (

                            <Form onSubmit={handleSubmit}>
                    
                        <Field
                            validate={required}
                            name="name"
                            render={({ input, meta }) => (
                                <FormGroup row>
                                    <Label md={3} className="text-primary" for="name">Role Name</Label>
                                    <Col md={9}>
                                    <input {...input} className="form-control" type="input" name="name" id="name" placeholder="Enter Role Name"/>
                                    {meta.touched && meta.error && <Label className="t-bold text-danger">{meta.error}</Label>}
                                    </Col>
                                    </FormGroup>)} />
                    <Field
                        name="dataFilter"
                        render={({ input, meta }) => (
                            <FormGroup row>
                                <Label md={3} className="text-primary" for="dataFilter">Data Filter</Label>
                                <Col md={9}>
                                <input {...input} className="form-control" type="input" name="dataFilter" id="dataFilter" placeholder="Enter Data Filter" />
                                {meta.touched && meta.error && <Label className="t-bold text-danger">{meta.error}</Label>}
                                </Col>
                            </FormGroup>)}/>

                        <FormGroup row>
                            <Label md={3} className="text-primary" for="organisation">Organisation</Label>
                            <Col md={9}>
                            <Field type="select" className="form-control" validate={required} component="select" name="organisation" id="organisation">
                                <option key="-1">Select Organisation</option>
                                {adminStore.organisations.map((organisation, index) => (
                                    <option key={index} value={organisation.id}>{organisation.name}</option>
                                ))}
                            </Field>
                            </Col>
                        </FormGroup>

                    <Button type="submit" color="primary">Submit</Button>
                </Form>
                )}   
            />
            </Card>
        </Col>
    </Row>
</Container>
</>
    );
};

export default CreateNewRole;