import React, { useContext, useState } from "react";
import Loader from "react-loader-spinner";
import { observer } from "mobx-react-lite";
import InpatientStore from "../../app/stores/inpatientStore";
import { Link } from 'react-router-dom';
import DocumentSection from "./documents/DocumentSection";

const PatientRecordDocumentsView: React.FC = () => {
  const inpatientStore  = useContext(InpatientStore);
  const urlParams       = new URLSearchParams(window.location.search);

  useState(() => {
    inpatientStore.loadPatientDocument(urlParams.get("d"));
  });

  return inpatientStore.isDocumentLoaded && inpatientStore.document !== undefined ? (
    <>
      <div className="mx-auto text-sm text-center">
          <Link className="btn btn-primary btn-sm" to={`${process.env.REACT_APP_URL_PATIENT_RECORD}/documents?p=${urlParams.get("p")}&v=${urlParams.get("v")}`}>Back to documents</Link>
      </div>
      <div id="letter" className="border shadow mx-5 my-3 px-5 py-2">
        <h2 className="letter-h2">{inpatientStore.document.name.description}</h2>
        {inpatientStore.document.sections.map((section, index) => (
          <DocumentSection section={section} key={index} index={index} />
        ))}
        <div className="py-2">&nbsp;</div>
      </div>
    </>
  ) : (
    <Loader
      type="TailSpin"
      color="#007bff"
      className="text-center"
      height={80}
      width={80}
    />
  );
};
export default observer(PatientRecordDocumentsView);
