import React, { useContext, useState } from 'react';
import { Form as FinalForm, Field } from 'react-final-form'
import { Card, Button, Form, FormGroup, Label, Container, Row, Col } from 'reactstrap';
import { IAddOrganisationForm, IOrganisation } from '../../app/models/user';
import AdminStore from '../../app/stores/adminStore';
import { Link } from 'react-router-dom';


interface IProps {   
    organisations: IOrganisation[]
}

const required = value => (value ? undefined : 'Required')
const CreateNewOrg: React.FC<IProps> = ({organisations}) => {
    const adminStore = useContext(AdminStore)   
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
 
    return (
        <>
<Container fluid>
                <h2 className="text-gradient text-primary mb-4">
                Create New Organisation
                </h2>
<Row>
    <Col>
            <div className="pb-3">
                <Link to="/system/admin/organisations" className="btn btn-link text-secondary">Back to Organisation</Link> &nbsp;
            </div>
        <Card body className='mb-5 card-menu'>
                <FinalForm                                
                        onSubmit={(values: IAddOrganisationForm) => adminStore.addOrganisation(values)        
                        }
                        render={({ handleSubmit, pristine, form }) => (

                            <Form onSubmit={handleSubmit}>
                    
                        <Field
                            validate={required}
                            name="name"
                            render={({ input, meta }) => (
                                <FormGroup row>
                                    <Label md={3} className="text-primary" for="name">Organisation Name</Label>
                                    <Col md={9}>
                                    <input {...input} className="form-control" type="input" name="name" id="name" placeholder="Enter Organisation Name"/>
                                    {meta.touched && meta.error && <Label className="t-bold text-danger">{meta.error}</Label>}
                                    </Col>
                                    </FormGroup>)} />
                    <Field
                        name="code"
                        render={({ input, meta }) => (
                            <FormGroup row>
                                <Label md={3} className="text-primary" for="code">Filter Code</Label>
                                <Col md={9}>
                                <input {...input} className="form-control" type="input" name="code" id="code" placeholder="Enter Filter Code" />
                                {meta.touched && meta.error && <Label className="t-bold text-danger">{meta.error}</Label>}
                                </Col>
                            </FormGroup>)}/>                        

                    <Button type="submit" color="primary">Submit</Button>
                </Form>
                )}   
            />
            </Card>
        </Col>
    </Row>
</Container>
</>
    );
};

export default CreateNewOrg;