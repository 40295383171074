import { RootStore } from "./rootStore";
import { observable, action, reaction } from "mobx";
import { DataItem } from "../models/inpatient";
import jwt_decode from "jwt-decode";
import { IValidity } from "app/models/user";

export default class CommonStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        reaction(
            () => this.token,
            token => {
                if (token) {
                    window.localStorage.setItem('jwt', token); 
                } else {
                    window.localStorage.removeItem('jwt');
                }
            }
        )
    }
    @observable UseDataLoader: boolean =  false;
    @observable dataItems: DataItem[] = [];
    @observable token: string | null = window.localStorage.getItem('jwt');
    @observable appLoaded = false;

    @action isTokenValid = () : IValidity => {
        let dt = window.localStorage.getItem('tokendatetime');
        let jwt = window.localStorage.getItem('jwt');

        if (jwt && dt) {
            let check = null;
            let tkn = jwt_decode(jwt) as any;
            if (tkn && tkn.exp) {
                check = tkn.exp as number;                
                if (dt == check && Number(dt) > Date.now() / 1000) {
                    return { 
                        valid: true,
                        validUntil: new Date(check * 1000),
                        checkPerformed: new Date()
                    };
                }       
                console.log(tkn, check);          
                return { valid: false, reason: "JWT expiry issue",checkPerformed: new Date() };
            }
        } else {
            return { valid: false, reason: "JWT missing",checkPerformed: new Date() };
        }
    }
    @action setToken = (token: string) => {
        window.localStorage.setItem('jwt', token);
        let dt: number = Date.now() / 1000;
        if (token !== null) {
            let tkn = jwt_decode(token) as any;
            if (tkn && tkn.exp) {
                dt = tkn.exp as number;
            }
        } else {
            dt = null
        }
        window.localStorage.setItem('tokendatetime', dt ? dt.toString() : null)
        this.token = token;
    }
    @action setAppLoaded = () => {
        this.appLoaded = true;
    }
}

