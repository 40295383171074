import React from 'react';
import { observer } from "mobx-react-lite";

interface IProps {
    response: string
}

const MeditechQueryResponseFix: React.FC<IProps> = ({ response }) => {

    const fix = (value: string) => {
        let expr: RegExp = /\x18?([a-zA-Z\?\x20]+)[\x09\x20]?([0-9]+)([BDIU]*[abcdrg]?)[\r\n]|\x18([a-zA-Z\?\x20]+)[\x09\x20]?([0-9]+)([BDIU]*[abcdrg]?)/g;
        let matches: IterableIterator<RegExpMatchArray> = value.matchAll(expr);
        let results: RegExpMatchArray[] = [...matches];
    
        for (let result of results) {      
            const [match, fontName, size, style] = result;        
            let fontFamily = "'Roboto'";
            switch (fontName)
            {
                case "Symbol":
                case "Wingdings":
                case "Webdings":
                case "Courier New":
                case "Times New Roman":
                    fontFamily = fontName;
                    break;
                default:
                    break;
            }
            let fontSize = (size == "38" ? "text-xl" : (size == "5" || size == "37" ? "text-lg" : (size == "3" ? "text-sm" : " ")));
            let fontWeight = "";
    
            if (style) {
              if (style.includes("B"))
                fontWeight += "font-weight-bold text-plum ";
              else
                  fontWeight += "text-normal";
              if (style.includes("U"))
                  fontWeight += " text-decoration-underline ";
              if (style.includes("I"))
                  fontWeight += " text-italic ";
              if (style.includes("r"))
                  fontWeight += " text-danger ";
              if (style.includes("b"))
                  fontWeight += " text-primary ";
              if (style.includes("g"))
                  fontWeight += " text-success ";
            }
            let replaceText = ` </span><span data-meditech-font="${fontFamily}" data-meditech-font-size="${fontSize}" data-meditech-font-style="${fontWeight}" class="${fontSize} ${fontWeight}" style="font-family:${fontFamily};">`;
            value = value.replace(match, replaceText);
        }
    
        value = value.replaceAll(/\"\"/g, "");
        value = value.replaceAll("  ", "&nbsp; ");
        value = value.replaceAll("\r\n\r\n\r\n", "");
        value = value.replaceAll("\r\n\r\n", "");
        value = value.replaceAll("\r\r", "<br />");
        value = value.replaceAll("\r", "<br />");
        value = value.replaceAll("\n\n\n", "");
        value = value.replaceAll(/[\x07\x18\x12\x16]/g, "");
        value = value.trim();
        value = value.replaceAll("\r", "");
        value = value.replaceAll("", "");
        value = value.replaceAll("", "");
        value = value.replaceAll("", "&bull; ");
        value = value.replaceAll("", "");
        value = value.replaceAll("", "");
    
        return value;
      }

      return (
        <div dangerouslySetInnerHTML={{ __html: fix(response)}}></div>
      )
}
export default observer(MeditechQueryResponseFix);