
export const msalConfig = {
    auth: {
        clientId: 'e3760b75-0485-4109-80cc-7d54a5f2b864',
        authority: 'https://login.microsoftonline.com/d3db4717-2853-4110-bc89-f41b4c7eb1c6',
        //redirectUri: 'https://demo.rhr.care/',
        redirectUri: 'https://rhr.care/',    
        //redirectUri: 'http://localhost:3000/',    
        scopes: [            
            'user.readwrite.all',
            'user.invite.all'
        ]
    }
};
export const loginRequest = {
    scopes: ['user.readwrite.all', 'user.invite.all']
};