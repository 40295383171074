import  React, { useContext } from "react";
import {Link} from 'react-router-dom'
import { Patient } from '../../app/models/inpatient';
import { observer } from 'mobx-react-lite';
import { Card, Row, Col } from 'reactstrap';
import NhsNumber from "features/common/NhsNumber";
import SearchStore from '../../app/stores/searchStore';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import DataLoadErrorAlert from "features/patientrecord/DataLoadErrorAlert";
import { ShortDateOnlyFormat } from "features/patientrecord/common/DateTimeFunctions";

interface IPatientResultsProps {
    results?: Patient[],
    isLoading: boolean,
    loadError: any
}

const PatientResults: React.FC<IPatientResultsProps> = ({results, isLoading, loadError}) => {
    const searchStore = useContext(SearchStore);
  
    return (
        <Card className="border-secondary mb-5">
            <div className="card-header">
                <span className="text-dark text-lg t-bold mb-0">
                    Search Results
                </span>
            </div>
                <div className="card-body">
                    <p className="mb-5">Use the search fields and click the appropriate Search button to get started. Click the row of the patient to open the patient record.</p>
                    {isLoading && (
                        <h1 className="p-2 text-center py-5 text-success">
                            <FontAwesomeIcon spin icon={faSpinner} size="2x" />
                        </h1>
                    )}
                    {(!isLoading && results.length) ? (
                        <>
                        <span className="badge bg-success text-white text-lg mb-2">
                        {results.length} patient{results.length === 1 ? "" : "s"} found.
                        </span>
                        <hr className="my-1" />
                        </>
                    ): <></>}
                    {loadError ? <DataLoadErrorAlert error={loadError} />: <></>}
                    <div className="list-group list-group-flush">
                    {(!isLoading && results.length > 0 && (
                        results.map((patient:Patient, index:number) => (
                                <Link 
                                    key={`result_${index}`}
                                    className={`list-group-item list-group-item-action py-0 px-2 lh-sm ${patient.dateOfDeath !== null ? "list-group-item-danger" : ""}`} 
                                    onClick={() => searchStore.setPatient(patient)} 
                                    to={`/patient/summary?p=${patient.systemInternalIdentifier.value}&v=`}>
                                    <Row>
                                        <Col xs={2} sm={3} md={"auto"} className="text-center py-2 border-right bg-problems" style={{minWidth:"175px", maxWidth:"175px"}}>
                                        {patient.nhsNumber?.value &&
                                        <>
                                            <span className="badge badge-primary bg-primary text-md mb-1">
                                                NHS:<NhsNumber nhsNumber={patient.nhsNumber?.value} />
                                            </span>
                                            <br />
                                        </>
                                        }
                                        {patient.systemPublicIdentifier?.value &&
                                            <span className="text-secondary text-md mb-1">
                                               {patient.systemPublicIdentifier?.value}
                                            </span>
                                        }
                                        </Col>
                                        <Col xs={7} sm={5} md={6} xl={7} className="py-2 pl-3">
                                            <div className="mb-1">
                                                <span className="font-weight-bold align-top text-xl d-inline-block mr-2">{patient.name.forename} {patient.name.surname}</span>
                                                <span className="font-weight-bold align-top text-md d-inline-block text-secondary border rounded bg-light px-2">{patient.gender} {patient.age}</span>
                                            </div>
                                            <div className="text-uppercase text-xs text-muted">
                                                {patient.contactDetails && (
                                                    <>
                                                    <span className="text-muted">{patient.contactDetails.address?.line1}</span>&nbsp;
                                                    <span className="text-muted">{patient.contactDetails.address?.line2}</span>&nbsp;
                                                    <span className="text-muted">{patient.contactDetails.address?.line3}</span>&nbsp;
                                                    <span className="text-muted">{patient.contactDetails.address?.postcode}</span>.
                                                    </>
                                                )}
                                            </div>
                                        </Col>
                                        <Col sm={3} md={"auto"} className="text-right d-none d-sm-block" style={{minWidth:"150px", maxWidth:"150px"}}>
                                            <div className="text-muted small py-1">DOB: <strong>{ShortDateOnlyFormat(patient.dateOfBirth)}</strong></div> 
                                            {patient.dateOfDeath !== null && (
                                                <div className="text-muted small py-1">DOD: <strong>{ShortDateOnlyFormat(patient.dateOfDeath)}</strong></div>                                                
                                            )}
                                        </Col>
                                    </Row>
                                </Link>
                        ))
                        ))}
                    </div>
                </div>
        </Card>
    )

}
export default observer(PatientResults)