import React, { Fragment } from "react";
import { observer } from "mobx-react-lite";
import { Col, Row } from "reactstrap";
import { ShortDateOnlyFormat, ShortTimeOnlyFormat } from "../common/DateTimeFunctions";
import { IFhirEncounter, IFhirExtension_v4 } from "app/models/fhir";

interface IFhirEncounterProps {
    encounter: IFhirEncounter
}

const YhcrEncounters: React.FC<IFhirEncounterProps> = ({ encounter }) => {

    const getIndividualType = (participant, index) => {
        return (<div key={index}>
               &bull; <strong>{participant.individual.display?.value}</strong> : 
               {participant.type?.length > 0 ? participant.type[0]?.coding?.map((tc, ci) => (
                   <span key={ci} className=""> {tc.display?.value}</span>
               )) : <></>} 
           </div>)
       }
       const getLocation = (location, index) => {
        return (<div key={index}>
               &bull; <strong>{location.location.display?.value}</strong> : {location.status?.value} 
           </div>)
       }

    return (
        <Fragment>
            <Col className="py-2 lh-xs-115" xs={5}>       
                <div className={`font-weight-bold text-sm pr-2 mr-2 text-xl mb-2`}>
                    
                    {encounter.extension?.map((e: IFhirExtension_v4,i) => 
                        e.url?.value === "https://fhir.yhcr.nhs.uk/StructureDefinition/Extension-Interweave-R4EncounterServiceType" &&
                        e.valueCodeableConcept.coding.map((c, ci) =>
                        <div className="text-sm text-primary" key={i}>
                            {c.display?.value}
                        </div>
                    ))}
                    {encounter.class?.display?.value}                   
                </div>

                {encounter.hospitalization?.admitSource?.coding?.map((c,i) => (
                    <div className="text-sm py-1" key={i}>
                        <div className="font-weight-bold">Admit Source</div>
                        {c.display?.value}
                    </div>
                ))}
                {encounter.hospitalization?.extension?.map((e: IFhirExtension_v4,i) => 
                    e.url?.value === "https://fhir.hl7.org.uk/STU3/StructureDefinition/Extension-CareConnect-AdmissionMethod-1" &&
                    e.valueCodeableConcept.coding.map((c, ci) =>
                    <div className="text-sm py-1" key={i}>
                        <div className="font-weight-bold">Admission Method</div>
                        {c.display?.value}
                    </div>
                ))}
                {encounter.hospitalization?.extension?.map((e: IFhirExtension_v4,i) => 
                    e.url?.value === "https://fhir.hl7.org.uk/STU3/StructureDefinition/Extension-CareConnect-DischargeMethod-1" &&
                    e.valueCodeableConcept.coding.map((c, ci) =>
                    <div className="text-sm py-1" key={i}>
                        <div className="font-weight-bold">Discharge Method</div>
                        {c.display?.value}
                    </div>
                ))}
                {encounter.hospitalization?.dischargeDisposition?.coding?.map((c,i) => (
                    <div className="text-sm py-1" key={i}>
                        <div className="font-weight-bold">Discharge Disposition</div>
                        {c.display?.value}
                    </div>
                ))}

                <div className="text-sm mt-1">
                        Location(s)
                    {encounter.location?.map((l,i) => (
                        <Fragment key={`location_${i}`}>{getLocation(l, i)}</Fragment>   
                    )) || <span className="font-weight-bold"> None</span>}
                </div>
                <div className="text-sm mt-1">
                        Participants(s)
                    {encounter.participant?.map((p,i) => (
                        <Fragment key={`individual_${i}`}>{getIndividualType(p, i)}</Fragment>
                    )) || <span className="font-weight-bold"> None</span>}
                </div>
            </Col>
            <Col xs={12} sm={6} md={"auto"} className="lh-xs-115 text-center py-2 bg-light border-left border-right">
                <Row>
                    <Col xs={6}>
                        <div className="text-xs">Start</div>
                        <div className="d-inline-block shadow-sm align-top rounded border border-secondary" style={{width:"90px", height: "60px"}}>
                            <div className="text-sm py-1 px-2 text-white bg-secondary text-center">
                                {ShortDateOnlyFormat(encounter.period.start?.value)}
                            </div>
                            <div className="py-2 text-center font-weight-bold">
                                {ShortTimeOnlyFormat(encounter.period.start?.value)}
                            </div>
                        </div>
                    </Col>
                    <Col xs={6}>
                        <div className="text-xs">End</div>
                        <div className="d-inline-block shadow-sm align-top rounded border border-secondary" style={{width:"90px", height: "60px"}}>
                            <div className="text-sm py-1 px-2 text-white bg-secondary text-center">
                                {encounter.period.end && ShortDateOnlyFormat(encounter.period.end?.value) || "-"}
                            </div>
                            <div className="py-2 text-center font-weight-bold">
                                {encounter.period.end && ShortTimeOnlyFormat(encounter.period.end?.value) || "-"}
                            </div>
                        </div>
                    </Col>
                </Row>
             
            </Col>
            <Col xs="2" className="py-2 text-center">
                <span className={`badge text-md badge-${encounter.status?.value === "final" ? "primary" :"secondary"}`}>{encounter.status?.value}</span><br />
                <div className="mt-2">
                    {encounter.priority?.coding.map((p,i) => (
                        <span className={`text-uppercase text-sm px-2 py-1 border font-weight-bold text-${p.display?.value === "emergency" ? "danger" : (p.display?.value === "elective" ? "success" : "primary")}`} key={i}>{p.display?.value}</span>
                    ))}
                </div>
            </Col>
        </Fragment>
    )

}
export default observer(YhcrEncounters)