import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

interface IListCountProps {
    total: number,
    filtered: number,
    recordsPerPage: number,
    currentPage: number
}

const PatientRecordCounts: React.FC<IListCountProps> = ({total, filtered, recordsPerPage, currentPage}) => {   
    const [ pages, setPages ] = useState<number>(Math.ceil(filtered / recordsPerPage));

    useEffect(() => {
        setPages(Math.ceil(filtered / recordsPerPage));
    }, [filtered])
   
    return (      
        <>
        {total === 0 ?
        <div className="table-warning px-3 py-1 text-sm font-weight-bold">
            There were no records found for this patient.
        </div>
            :
        <div className="table-info px-3 py-1 text-sm font-weight-bold">
            {total} records loaded. 
            {total !== filtered && (
                <> {filtered} records displayed (filtered).</>
            )}
            {filtered > recordsPerPage && (
                <> Currently viewing page {currentPage} of {pages}.</>
            )}
        </div>}
        </>  
    )
}

export default observer(PatientRecordCounts);