import UserStore from './userStore';
import { createContext } from 'react';
import { configure } from 'mobx';
import CommonStore from './commonStore';
import AuditStore from './auditStore';

configure({ enforceActions: 'always' });

export class RootStore {
    userStore: UserStore
    commonStore: CommonStore
    auditStore: AuditStore

    constructor() {
        this.userStore = new UserStore(this);
        this.commonStore = new CommonStore(this);
        this.auditStore = new AuditStore(this)
    }
}

export const RootStoreContext = createContext(new RootStore());