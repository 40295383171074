import React, { useContext } from "react";
import { Form as FinalForm, Field } from "react-final-form";
import {  Button,  Form,  FormGroup,  Label,  Card,  CardBody,  Container,  Row,  Col,  CardHeader,} from "reactstrap";
import { ISetupPasswordForm } from "../../app/models/user";
import { RootStoreContext } from "../../app/stores/rootStore";

const SetupPassword = () => {
  let urlParams = new URLSearchParams(window.location.search);
  let rootStore = useContext(RootStoreContext);
  return (
    <Container fluid>
      <Row className="justify-content-center">
        <Col xs="10" md="6" className="pt-5">
          <Card>
            <CardHeader className="text-white bg-primary t-bold">
              Rotherham Health Record Setup Password
            </CardHeader>
            <CardBody>
              <FinalForm
                onSubmit={(values: ISetupPasswordForm) => {
                  values.email = urlParams.get("email");
                  values.token = urlParams.get("token");

                  rootStore.userStore
                    .setuppassword(values)
                    .then((response) => {});
                }}
                render={({ handleSubmit }) => (
                  <Form onSubmit={handleSubmit}>
                    <FormGroup>
                      <Label for="password">Password</Label>
                      <Field
                        className="form-control"
                        type="password"
                        name="password"
                        component="input"
                        id="password"
                        placeholder="Enter Password"
                      />
                    </FormGroup>
                    <Button type="submit" color="primary">
                      Submit
                    </Button>
                  </Form>
                )}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default SetupPassword;
