import React from 'react';
import { DataLoadStatus } from "app/api/agent";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckDouble, faEllipsisH, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';

interface IDataLoadStatusBadgeProps {
    status: DataLoadStatus
}

const DataLoadStatusBadgeSummary: React.FC<IDataLoadStatusBadgeProps> = ({ status }) => {

    return (
        <span className="text-sm px-2">
        {DataLoadStatus[status] === "NotAttempted" && (
            <FontAwesomeIcon icon={faEllipsisH} className="text-secondary" />
        ) ||
        DataLoadStatus[status] === "Loading" && (
            <FontAwesomeIcon icon={faSpinner} spin className="text-primary" />
        ) ||
        DataLoadStatus[status] === "Successful" && (
            <FontAwesomeIcon icon={faCheckDouble} className="text-success" />
        ) ||
        DataLoadStatus[status] === "Failed" && (            
            <FontAwesomeIcon icon={faTimes} className="text-danger" />
        )}
        </span>
    )
}

export default DataLoadStatusBadgeSummary;