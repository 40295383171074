import React, { useContext, Fragment, useEffect, useState }  from "react";
import { PopoverHeader, PopoverBody, UncontrolledPopover, Card, ListGroup, ListGroupItem } from 'reactstrap'
import { observer } from "mobx-react-lite";
import Loader from 'react-loader-spinner'
import NhsNumber from "features/common/NhsNumber";
import PatientRecordStore from "app/stores/patientRecordStore";
import { Patient, PatientAllergy } from "app/models/inpatient";
import { ShortDateTimeFormat } from "./common/DateTimeFunctions";

const PatientRecordHeader: React.FC = () => {
    const patientRecordStore = useContext(PatientRecordStore);
    const [ patient, setPatient ] = useState<Patient>(null)
    const [ allergies, setAllergies ] = useState<PatientAllergy[]>(null)
    let urlParams = new URLSearchParams(window.location.search);       

    useEffect(() => {
        if (!patientRecordStore.patient || patientRecordStore.patient.systemInternalIdentifier.value !== urlParams.get('p')) {
            patientRecordStore.getPatient(urlParams.get('p'))
        } else {
            setPatient(patientRecordStore.patient);
        }
    }, [patientRecordStore.patient]);

    useEffect(() => {
        if (patientRecordStore.allergies) {
            setAllergies(patientRecordStore.allergies);
        }
    }, [patientRecordStore.allergies]);


    return (
        <Fragment>

            <nav className="sticky-top">
                {patient ? (
                    <>
                        <div className="row bg-secondary text-white" id="patientBanner">
                            <div className="col-12 col-md-8 col-lg-4 pl-3 pt-2">
                                <div className="patient-name d-inline-block">
                                    {patient.name.forename} {patient.name.surname}
                                    <span className="patient-gender ml-3">
                                        {patient.gender}, {patient.age}
                                    </span><br />
                                    <p className="patient-address mb-0">
                                        {patient.contactDetails.address.line1 ? patient.contactDetails.address.line1 + ', ' : ''}
                                        {patient.contactDetails.address.line2 ? patient.contactDetails.address.line2 + ', ' : ''}
                                        {patient.contactDetails.address.line3 ? patient.contactDetails.address.line3 + ', ' : ''}
                                        {patient.contactDetails.address.postcode ? patient.contactDetails.address.postcode + '. ' : ''}
                                    </p>
                                </div>
                                <div className="mb-3">
                                    <div className="badge patient-identifier text-center">
                                        NHS:<NhsNumber nhsNumber={patient?.nhsNumber?.value} />
                                    </div>
                                    <div className="badge patient-identifier text-center">
                                        {patient.systemPublicIdentifier.value}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4">
                                <div className="pt-3 lh-sm">
                                        {patient.gpDetails?.registeredPracticeContactDetails?.address?.line1 ? (
                                        <>
                                            <div className="patient-banner-heading mb-0">
                                                {patient.gpDetails.registeredPracticeContactDetails.address.line1}
                                            </div>
                                            <div className="text-sm mb-1">
                                                Registered GP Practice
                                            </div>  
                                        </>                                              
                                        ) : (
                                        <div className="patient-banner-heading text-warning">
                                            No GP Practice Recorded
                                        </div>
                                        )}
                                </div>
                            </div>
                            <div className="col-12 col-lg-4">
                                <div className="pt-2">
                                    {allergies !== undefined && allergies !== null && (                                        
                                        allergies.length > 0 && !allergies.some(x => x.allergy.id === 'NKA') ? (
                                        <Fragment>
                                            <br />
                                            <Card className="ml-4 mx-2 d-inline px-3 py-2 font-weight-bold bg-warning text-center shadow text-dark border text-lg mt-3" id="KA" type="hover">
                                                Known Allergies
                                                <span className="abs badge badge-pill badge-danger text-md border border-dark" style={{ 'top': '-8px' }}>{allergies.length}</span>
                                            </Card>
                                            <div>
                                                <UncontrolledPopover style={{ 'width': '400px' }} trigger="hover" placement="bottom" target="KA" >
                                                    <PopoverHeader className="bg-header">Known Allergies</PopoverHeader>
                                                    <PopoverBody>
                                                        <ListGroup className="list-group list-group-flush p-0" style={{ lineHeight: 1.15 }}>
                                                            {allergies.map((allergy, index) => (
                                                                <ListGroupItem key={index} className="p-2">
                                                                    <span className="badge badge-warning badge-pill float-right text-xs text-uppercase t-bold text-center">{allergy.status}</span>
                                                                    <div className="float-right mr-3 text-sm text-right t-medium text-muted">{ShortDateTimeFormat(allergy.addedDateTime)}</div>
                                                                    <div className="t-medium text-md t-bold">{allergy.allergy.description}  <span className="text-muted" style={{ 'fontSize': '0.7em' }}> </span></div>
                                                                    {allergy.comment && (
                                                                        <div className="small text-muted">{allergy.comment}</div>
                                                                    )}
                                                                </ListGroupItem>
                                                            ))}
                                                        </ListGroup>
                                                    </PopoverBody>
                                                </UncontrolledPopover>
                                            </div>
                                        </Fragment>
                                    ) :
                                        <div className="patient-banner-heading">NKA - No Known Allergies</div>
                                    )}

                                </div>
                            </div>
                        </div>
                    </>
                ) : <Loader type="TailSpin" color="#007bff" className="text-center" height={80} width={80} />}
            </nav>
        </Fragment>

    )

}
export default observer(PatientRecordHeader)