import React from "react";
import { PatientAllergy } from "../../app/models/inpatient";
import { observer } from "mobx-react-lite";
import agent from "app/api/agent";
import PatientRecordListDisplay from "./common/PatientRecordListDisplay";
import { ShortDateTimeFormat } from "./common/DateTimeFunctions";

const SelectedInpatientAllergiesList: React.FC = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const loadData = () => {
      return agent.Patient.Allergies({
        systemIdentifier: urlParams.get("p"),
        patientIdentifier: null,
        visitIdentifier: null,
      })
  };


  const displayTemplate = (allergy:PatientAllergy, index: number) => {
    return (allergy.allergy.id === "NKA" ? (
      <li
        className="list-group-item list-group-item-success"
        key={index}
      >
        <div className="row">
          <div className="col-sm-3">
            <span className="badge badge-success text-md float-right">
              {allergy.status}
            </span>
            <span className="small" style={{ marginTop: ".2rem" }}>              
                {ShortDateTimeFormat(allergy.addedDateTime)}
            </span>
          </div>
          <div className="col-sm-9">
            <h5 className="list-group-item-heading">
              <i className="fa fa-check-circle"></i>{" "}
              {allergy.allergy.description}
            </h5>
          </div>
        </div>
      </li>
    ) : (
      <li className="list-group-item px-3 py-0" key={index}>
        <div className="row">
          <div className="col-md-8 col-xl-10 py-2">
            <div className="badge badge-primary text-md float-right">
              {allergy.status}
            </div>
            <div className="my-0 text-lg font-weight-bold">{allergy.allergy.description}</div>
            <div className="small text-muted">
              {allergy.reaction} {allergy.severity}
            </div>
            <div className="small text-success">{allergy.comment}</div>
          </div>
          <div className="col-md-4 col-xl-2 py-2 border-right d-none d-md-block bg-allergies">
            <h6 className="my-0 t-bold">{allergy.type}</h6>
            <div className="small" style={{ marginTop: ".2rem" }}>
              Added{" "}
                {ShortDateTimeFormat(allergy.addedDateTime)}
            </div>
          </div>
        </div>
      </li>
    ))
  } 

  return (
    <PatientRecordListDisplay _filters={[]} _sorts={[]} _agentCall={loadData} _displayTemplate={displayTemplate} />
  );
};
export default observer(SelectedInpatientAllergiesList);
