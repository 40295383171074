import React, { Fragment } from "react";
import { observer } from "mobx-react-lite";
import { Col, Badge } from "reactstrap";
import { ShortDateOnlyFormat } from "../common/DateTimeFunctions";
import { IFhirAllergy } from "app/models/fhir";

interface IFhirAllergyProps {
    allergy: IFhirAllergy
}

const YhcrAllergies: React.FC<IFhirAllergyProps> = ({ allergy }) => {

    return (
        <Fragment>
            <Col className="py-2">
                {allergy.code?.coding?.map((coding, index) => (
                    <div className="font-weight-bold" key={index}>{coding.display?.value}</div>
                ))}
                
                {allergy.reaction &&
                <span className="text-sm">
                    Reaction(s):
                    {allergy.reaction?.map((reaction) => (
                        reaction && reaction.manifestation.map((manifestation) => (
                            manifestation && manifestation.coding.map((coding, cIndex) => (
                                <span className="px-1 font-weight-bold" key={cIndex}>
                                    &bull; {coding.display?.value}
                                </span>
                            ))
                        ))
                    ))}
                </span>}
                <div className="text-sm">
                    {allergy.onsetDateTime && (
                        <>On-set <span className="font-weight-bold">{ShortDateOnlyFormat(allergy.onsetDateTime?.value)}</span>.</>
                    )}
                    {allergy.assertedDate && (
                        <> Asserted <span className="font-weight-bold">{ShortDateOnlyFormat(allergy.assertedDate?.value)}</span></>
                    )}
                    {allergy.asserter && (
                        <> by <span className="font-weight-bold">{allergy.asserter.display?.value}</span>.</>
                    )}
                </div>
            </Col>
            <Col xs="2" className="py-2 text-center">
                
                <Badge className="text-md" color={allergy.clinicalStatus?.value === "active" ? "primary" : "secondary"}>{allergy.clinicalStatus?.value}</Badge>
                <div className="pt-2">                    
                    <span className={`text-md bg-light py-1 px-2 font-weight-bold rounded text-${allergy.verificationStatus.value === "confirmed" ? "dark" : "muted"}`}>{allergy.verificationStatus?.value}</span>
                </div>
            </Col>
        </Fragment>
    )

}
export default observer(YhcrAllergies)