import React, { useEffect, useContext } from "react";
import { Switch, Route, useParams } from 'react-router-dom';
import { observer } from "mobx-react-lite";
import { Row, Col, Container, Card } from 'reactstrap'
import { RootStoreContext } from "../../app/stores/rootStore";
import PatientRecordHeader from "./PatientRecordHeader";
import PatientRecordNavBar from "./PatientRecordNavBar";
import summaryImg from '../../app/images/summary.png';
import problemsImg from '../../app/images/stethoscope.png';
import allergiesImg from '../../app/images/rash.png';
import appointmentsImg from '../../app/images/medical-appointment.png';
import attentanceImg from '../../app/images/ambulance.png';
import admissionImg from '../../app/images/hospital-bed.png';
import documentImg from '../../app/images/file.png';
import surgeryImg from '../../app/images/surgery.png';
import medicationImg from '../../app/images/medication.png';
import resultImg from '../../app/images/flask.png';
import yhcrImg from '../../app/images/yhcr.png';
import PatientRecordStore from "app/stores/patientRecordStore";
import PatientRecordSummary from "./PatientRecordSummary";
import PatientRecordYHCR from "./PatientRecordYHCR";
import PatientRecordYhcrFhir from "./PatientRecordYhcrFhir";
export interface IPatientNav {
    route: string,
    displayOnMenu: boolean,
    imageSrc: string,
    title: string,
    component: React.ReactNode
}

const PatientRecordYhcrPageLayout: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const patientRecordStore = useContext(PatientRecordStore);
    const urlParams = new URLSearchParams(window.location.search);
    const { resource } = useParams();
    const getPageRoute = (routePath:string) => `${process.env.REACT_APP_URL_PATIENT_RECORD}${routePath}`

    const menuItems: IPatientNav[] = [
        { route: "/summary", title: "Summary", displayOnMenu: true, imageSrc: summaryImg, component: <PatientRecordSummary />},
        { route: "/yhcr", title: "Home", displayOnMenu: true, imageSrc: yhcrImg, component: <PatientRecordYHCR /> },
        { route: "/yhcr/AllergyIntolerance", title: "Allergies", displayOnMenu: true, imageSrc: allergiesImg, component: <PatientRecordYhcrFhir resource="AllergyIntolerance" /> },
        { route: "/yhcr/Appointment", title: "Appointments", displayOnMenu: true, imageSrc: appointmentsImg, component: <PatientRecordYhcrFhir resource="Appointment" /> },
        { route: "/yhcr/Condition", title: "Conditions", displayOnMenu: true, imageSrc: problemsImg, component: <PatientRecordYhcrFhir resource="Condition" /> },
        { route: "/yhcr/DocumentReference", title: "Documents", displayOnMenu: true, imageSrc: documentImg, component: <PatientRecordYhcrFhir resource="DocumentReference" /> },
        { route: "/yhcr/Encounter", title: "Encounters", displayOnMenu: true, imageSrc: admissionImg, component: <PatientRecordYhcrFhir resource="Encounter" /> },
        { route: "/yhcr/MedicationStatement", title: "Medications", displayOnMenu: true, imageSrc: medicationImg, component: <PatientRecordYhcrFhir resource="MedicationStatement" /> },
        { route: "/yhcr/Patient", title: "Patient", displayOnMenu: false, imageSrc: summaryImg, component: <PatientRecordYhcrFhir resource="Patient" /> },
        // { route: "/yhcr/Observation", title: "Observations", displayOnMenu: true, imageSrc: resultImg, component: <PatientRecordYhcrFhir resource="Observation" /> },
        { route: "/yhcr/Procedure", title: "Procedures", displayOnMenu: true, imageSrc: surgeryImg, component: <PatientRecordYhcrFhir resource="Procedure" /> }
    ];

    useEffect(() => {    
        let mis = menuItems.filter(mi => getPageRoute(mi.route) === window.location.pathname);
        if (mis.length) {
            let audit = {
                module: "YHCR",
                page: window.location.pathname,
                description: `${rootStore.userStore.user.username} has accessed YHCR ${mis[0].title} (${resource})`,
                username: rootStore.userStore.user.username,
                action: `${mis[0].title}`,
                documentId: urlParams.get("d"),
                patientId: urlParams.get("p"),
                visitId: urlParams.get("v")
            };
            rootStore.auditStore.addEntry(audit);
        }    
    },[,window.location.pathname]);

    return (
        <div className="d-flex flex-column h-100">
            <main role="main" className="flex-shrink-0 mt-0">
                <Container fluid={true}>
                    <Row>
                        <PatientRecordNavBar menuItems={menuItems} />  
                        <Col>                      
                        <PatientRecordHeader />
                            <Card body className="card-menu card-patient-record my-0 mx-0 m-sm-3 m-lg-5 py-3 px-4">    
                                {patientRecordStore.patient ? 
                                <Switch>                                    
                                    {menuItems.map((menuItem: IPatientNav, index: number) => 
                                    <Route
                                        exact
                                        key={index}
                                        path={getPageRoute(menuItem.route)}
                                        children={() => <>
                                            <h3 className="mb-3 text-primary text-gradient">{menuItem.title}</h3>
                                            {menuItem.component}
                                            </>}
                                    />
                                    )}
                                </Switch>
                                : <>Loading...</> }
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </main>
        </div>
    )

}
export default observer(PatientRecordYhcrPageLayout)