import React, { useContext } from 'react';
import { RootStoreContext } from 'app/stores/rootStore';
import { Link } from 'react-router-dom';

interface IDataLoadErrorAlertProps {
    error: any
}

const DataLoadErrorAlert: React.FC<IDataLoadErrorAlertProps> = ({ error }) => {
    const rootStore = useContext(RootStoreContext);
    const { logout } = rootStore.userStore;
    
    return (
        error ? (
            <div className="alert alert-danger">
                <strong>Error {error.status}</strong> &nbsp; {error?.statusText || error?.message}
                {error.status === 401 && 
                (<> Please login {" "}
                    <Link 
                        onClick={logout} 
                        to={`/?login_redirect=${Buffer.from(window.location.pathname + window.location.search).toString("base64")}`}>
                        here
                    </Link>
                </>)}
                {error.message === "Network Error" && <>{'. Please check your internet connectivity'}</>}
            </div>
        ) : <></>
    )
}

export default DataLoadErrorAlert;